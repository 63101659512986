<div class="forty-sixty-view">
  <div class="image-container">
    <h3 style="color: white">Willkommen bei GeoLOG</h3>
  </div>
  <div class="company-landing-page-container">
    <ng-container *ngIf="!successfullyRegisteredCompany && !successfullyJoinedCompany">
      <elab-register-company-form (successfulRegisterEmitter)="handleSuccessfulRegisterCompany()" (stepEmitter)="handleRegisterCompanyFormStepChange($event)" ></elab-register-company-form>
      <ng-container *ngIf="registerCompanyFormStep === 0">
        <div style="display: flex; flex-direction: row; justify-content: center; width: 406px; margin: auto">
          <mat-divider class="divider-left"></mat-divider>
          <h4 style="text-align: center; margin: 36px 0">Oder</h4>
          <mat-divider class="divider-right"></mat-divider>
        </div>
        <elab-join-company-form (successfulJoinCompanyEmitter)="handleSuccessfulJoinCompany()"></elab-join-company-form>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="successfullyRegisteredCompany || successfullyJoinedCompany">
      <h4 data-cy="successful-create-company-title" style="text-align: center; color: green" *ngIf="successfullyRegisteredCompany">Unternehmen erfolgreich erstellt!</h4>
      <h4 data-cy="successful-join-company-title" style="text-align: center; color: green" *ngIf="successfullyJoinedCompany">Unternehmen erfolgreich beigetreten!</h4>
    </ng-container>
  </div>
  
  
</div>
