import { CompanyInterface } from '../../..';
import { CompanyAddressDto } from './company-address.dto';

export class CreateCompanyRequestDto implements CompanyInterface {
  address: CompanyAddressDto;
  email: string;
  name: string;
  licenseKey: string;
  phoneNumber: string;
  
  constructor(address: CompanyAddressDto, email: string, name: string, phoneNumber: string, licenseKey: string) {
    this.address = address;
    this.email = email;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.licenseKey = licenseKey;
  }
}
