import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RefreshTokenRequestDto, SignInRequestDto, SignUpProviderResponseDto } from '@platri/elab-common-auth-plug-play';
import { excludeFalsyPropertiesFromObject } from '@platri/elab-common-core';
import { environment } from '../../environments/default.environment';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService implements OnDestroy {
  baseUrl = environment.geologApi;

  constructor(
    private httpClient: HttpClient,
  ) {
    console.log('Initializing AuthHttpService');
  }

  ngOnDestroy(): void {
    console.log('Destroying AuthHttpService');
  }

  login(signInRequestDto: SignInRequestDto): Observable<SignUpProviderResponseDto> {
    excludeFalsyPropertiesFromObject(signInRequestDto);
    return this.httpClient.post<SignUpProviderResponseDto>(this.baseUrl + 'auth/sign-in', signInRequestDto);
  }
  
  magicLinkLogin(token: string): Observable<any> {
    return this.httpClient.post<SignUpProviderResponseDto>(this.baseUrl + 'auth/magic-link/sign-in', {token});
  }

  refresh(refreshTokenRequestDto: RefreshTokenRequestDto): Observable<SignUpProviderResponseDto> {
    return this.httpClient.post<SignUpProviderResponseDto>(this.baseUrl + 'auth/refresh', refreshTokenRequestDto);
  }

}
