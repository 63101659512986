<div class="full-view">
  <div class="flex-container">
    <div class="loading-box" *ngIf="isWaitingForConfirmEmailResponse">
      <mat-spinner></mat-spinner>
    </div>
    
    <div *ngIf="!confirmEmailFailedNoConnection && !isWaitingForConfirmEmailResponse && hasSuccessfullyConfirmEmail">
      Sie haben Ihre E-Mail erfolgreich bestätigt!
    </div>
    
    <div *ngIf="!confirmEmailFailedNoConnection && !isWaitingForConfirmEmailResponse && !hasSuccessfullyConfirmEmail">
      Etwas ist schief gelaufen.
    </div>
    
    <div *ngIf="confirmEmailFailedNoConnection">
      Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.
    </div>
  </div>
</div>
