<div class="geolog-app page">
  <div class="page-header">
    <h2 style="color: green">GeoLOG</h2>
    <div class="page-header-links">
      <button *ngIf="accessToken" mat-button [routerLink]="['/', GeologAppRoute, GeologProjectsRoute]" routerLinkActive="active-link">Projekte</button>
      <ng-container *ngIf="availableCompanies && availableCompanies.length > 0">
         <button *ngFor="let company of availableCompanies"  mat-button [routerLink]="['/', GeologAppRoute, GeologCompanyRoute, company.id]" routerLinkActive="active-link">{{company.name}}</button>
      </ng-container>
    </div>
    <div class="page-header-actions">
      <button color="accent" *ngIf="selectedCompany" mat-button [matMenuTriggerFor]="menu">{{selectedCompany.name}} <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon></button>
      <button *ngIf="accessToken" mat-button [routerLink]="['/', GeologAppRoute, GeologCompanyLandingRoute]" routerLinkActive="active-link">Neues Unternehmen</button>
      <mat-menu #menu="matMenu">
        <button *ngFor="let company of availableCompanies" (click)="changeSelectedCompany(company)" mat-menu-item>{{company.name}}</button>
      </mat-menu>
      <ng-container *ngIf="accessToken">
        <button type="button" mat-button (click)="logout()">Abmelden</button>
      </ng-container>
      <ng-container *ngIf="!accessToken">
        <button mat-button [routerLink]="GeologLoginRoute">Einloggen</button>
        <button mat-button [routerLink]="GeologRegisterRoute">Registrieren</button>
      </ng-container>
    </div>
  </div>
  <mat-divider style="margin-bottom: 60px"></mat-divider>
  <div class="page-body">
      <router-outlet></router-outlet>
  </div>
</div>
