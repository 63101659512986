<ng-container>
  <div style="width: 100%" [formGroup]="googleAddressSearchAutocompleteFormGroup">
    <mat-form-field style="width: 100%;" appearance="outline">
     <mat-label>Adresse</mat-label>
     <input
       style="-webkit-appearance:none; background-color: white;"
       matInput
       elabGoogleAutocomplete
       type="text" 
       id="address-input"
       data-cy="address-input"
       formControlName="address"
       placeholder="Hamburg Peutestrasse 56-60 Sondierung"
       autocomplete="off"
       (onAddressChange)="handleAddressChange($event)">
   </mat-form-field>
  </div>
</ng-container>
