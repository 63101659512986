import { Component } from '@angular/core';
import { CompanyStateService } from '../../shared/services/company-state.service';

@Component({
  selector: 'elab-company-page',
  templateUrl: './company-page.component.html',
  styleUrls: ['./company-page.component.scss']
})
export class CompanyPageComponent {
  
  successfullyRegisteredCompany = false;
  successfullyJoinedCompany = false;
  
  registerCompanyFormStep = 0;
  
  constructor(
    private companyStateService: CompanyStateService
  ) {
  }
  
  handleSuccessfulRegisterCompany(): void {
    this.successfullyRegisteredCompany = true;
    this.companyStateService.loadAvailableData();
  }
  
  handleSuccessfulJoinCompany(): void {
    this.successfullyJoinedCompany = true;
  }

  handleRegisterCompanyFormStepChange(step: number): void {
    this.registerCompanyFormStep = step;
  }
  
}
