<div class="forty-sixty-view">
  <div class="image-container">
    <h3 style="color: white">GeoLOG</h3>
    <!--<button style="margin-top: 100px" (click)="openLoginDialog()">Open Dialog</button> -->
  </div>
  <div class="login-container">
    <elab-login-form></elab-login-form>
  </div>
  
  
</div>
