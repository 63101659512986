import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadBoreholesComponent } from './upload-boreholes.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ProgressModule } from '../progress/progress.module';

@NgModule({
  declarations: [UploadBoreholesComponent],
  imports: [
    CommonModule,
    ProgressModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [UploadBoreholesComponent]
})
export class UploadBoreholesModule { }
