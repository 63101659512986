import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompaniesPageComponent } from './companies-page.component';

@NgModule({
  declarations: [CompaniesPageComponent],
  imports: [
    CommonModule,
  ],
  exports: [CompaniesPageComponent]
})
export class CompaniesPageModule { }
