import { CompanyAddressDto } from './company-address.dto';
import { CompanyInterface } from '../../..';

export class UpdateCompanyRequestDto implements CompanyInterface {
  address?: CompanyAddressDto;
  businessArea?: string;
  email?: string;
  name?: string;
  phoneNumber?: string;
  
  constructor(address: CompanyAddressDto, email: string, name: string, phoneNumber: string, businessArea: string) {
    this.address = address;
    this.email = email;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.businessArea = businessArea;
  }
}
