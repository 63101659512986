import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterDialogComponent } from './register-dialog.component';
import { RegisterFormModule } from '../../components/register-page/register-form/register-form.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [RegisterDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    RegisterFormModule
  ],
  exports: [RegisterDialogComponent]
})
export class RegisterDialogModule {
  constructor() {
    console.log('registerDialogModule initialized');
  }
}
