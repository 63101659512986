import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthHttpService } from './auth-http.service';
import { SignInRequestDto } from '@platri/elab-common-auth-plug-play';
import { AuthService } from './auth.service';
import { GeologAppRoute, GeologLoginRoute } from '../routes';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService implements OnDestroy {
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(!!this.authService.getAccessTokenFromLocalStorage());
  accessToken: BehaviorSubject<string> = new BehaviorSubject<string>(this.authService.getAccessTokenFromLocalStorage());
  refreshToken: BehaviorSubject<string> = new BehaviorSubject<string>(this.authService.getRefreshTokenFromLocalStorage());
  subscriptions: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private authHttpService: AuthHttpService,
    private router: Router,
    private authService: AuthService
  ) {
    console.log('Initializing AuthStateService');
    //this.refreshTokens();
    setInterval(() => this.refreshTokens(), 900000);
  }
  ngOnDestroy(): void {
    console.log("Destroying AuthStateService");
    this.isLoggedIn.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  login(password: string, username?: string, email?: string): Observable<any> {
    const signInRequestDto: SignInRequestDto = {username, email, password};
    return this.authHttpService.login(signInRequestDto).pipe(map((res) => {
      this.sendIsLoggedIn(true);
      if (res.refreshToken) {
        this.sendRefreshToken(res.refreshToken);
      }
      this.sendAccessToken(res.accessToken);
      return res;
    }));
  }
  
  magicLinkLogin(token: string): Observable<any> {
    return this.authHttpService.magicLinkLogin(token).pipe(map((res) => {
      this.sendAccessToken(res.accessToken);
      if (res.refreshToken) {
        this.sendRefreshToken(res.refreshToken);
      }
      return res;
    }));
  }
  
  refreshTokens(): void {
    if (this.getSyncRefreshToken()) {
      this.authHttpService.refresh({refreshToken: this.getSyncRefreshToken()}).subscribe(res => {
        this.sendAccessToken(res.accessToken);
        if (res.refreshToken) {
          this.sendRefreshToken(res.refreshToken);
        }
      });
    }
  }
  
  logout(): void {
    this.sendIsLoggedIn(false);
    this.sendAccessToken('');
    this.sendRefreshToken('');
    this.router.navigate([GeologAppRoute, GeologLoginRoute]);
  }

  getSyncAccessToken(): string {
    return this.accessToken.getValue();
  }

  getAsyncAccessToken(): Observable<string> {
    return this.accessToken.asObservable();
  }

  private sendAccessToken(accessToken: string): void {
    this.authService.saveAccessTokenToLocalStorage(accessToken);
    this.accessToken.next(accessToken);
  }

  getSyncRefreshToken(): string {
    return this.refreshToken.getValue();
  }

  getAsyncRefreshToken(): Observable<string> {
    return this.refreshToken.asObservable();
  }

  private sendRefreshToken(refreshToken: string): void {
    this.refreshToken.next(refreshToken);
    this.authService.saveRefreshTokenToLocalStorage(refreshToken);
  }

  getSyncIsLoggedIn(): boolean {
    return this.isLoggedIn.getValue();
  }

  getAsyncIsLoggedIn(): Observable<boolean> {
    return this.isLoggedIn.asObservable();
  }

  private sendIsLoggedIn(isLoggedIn: boolean): void {
    this.isLoggedIn.next(isLoggedIn);
  }

}
