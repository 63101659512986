<div class="full-view">
  <div class="header-bar">
    <button [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, projectIdFromParam, GeologAreasRoute, areaIdFromParam]" mat-button color="primary" type="button"><mat-icon style="font-size: 12px; height: 12px; width: 12px; margin-right: 0">arrow_back_ios</mat-icon> Zurück zu den Bohrlöchern</button>
<!--    <div>-->
<!--      <button [routerLink]="[GeologEditAreaRoute]" style="margin-right: 12px" mat-raised-button type="button"><mat-icon>send</mat-icon>Send</button>-->
<!--      <button mat-raised-button color="primary" type="button"><mat-icon>ios_share</mat-icon>Export</button>-->
<!--    </div>-->
  </div>
  
  <div class="details">
    <h3>{{borehole && project ? project.name + ' / ' + borehole.physicalBoreholeId : '...'}}</h3>
    <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="changeUrlFragment($event)" animationDuration="0ms" mat-align-tabs="center">
       <mat-tab label="Übersicht"> 
        <table *ngIf="isInitialized" style="width:100%">
          <h4>Allgemeine Informationen</h4>
          <tr>
            <th>Physikalische Bohrloch-ID</th>
            <td>{{isInitialized ? borehole.physicalBoreholeId : "Loading..."}}</td>
          </tr>
          <tr>
            <th>GPS-Standort</th>
            <td *ngIf="isInitialized"> {{ 'E ' + borehole.longitude}} <br> {{'N ' + borehole.latitude }} </td>
          </tr>
          <tr>
            <th>Höhe</th>
            <td *ngIf="isInitialized"> {{borehole.altitude ? borehole.altitude + ' m' : 'Not known'}} </td>
          </tr>
          <tr *ngIf="borehole.status !== BoreholeStatusEnum.CREATED">
            <th>Maschinen-ID</th>
            <td>{{isInitialized ? borehole.machineId : "Loading..."}}</td>
          </tr>
          <tr *ngIf="borehole.status !== BoreholeStatusEnum.CREATED">
            <th>Maschinenbediener</th>
            <td>{{isInitialized ? borehole.machineOperator : "Loading..."}}</td>
          </tr>
          <tr *ngIf="borehole.status !== BoreholeStatusEnum.CREATED">
            <th>Softwareversion</th>
            <td>{{isInitialized ? borehole.machineSoftwareVersion : "Loading..."}}</td>
          </tr>

          <mat-divider class="custom-divider"></mat-divider>
          
          <h4 style="margin-top: 80px">Bohrlochdetails</h4>
          
          <tr>
            <th>Status</th>
            <td>
              <ng-container *ngIf="isInitialized && borehole.status">
                <mat-chip *ngIf="borehole.status === BoreholeStatusEnum.SUCCESSFULLY_FILLED" [ngClass]="'success-chip'" class="success-chip">Erfolgreich gefüllt</mat-chip>
                <mat-chip *ngIf="borehole.status === BoreholeStatusEnum.IN_PROGRESS" [ngClass]="'in-progress-chip'" class="in-progress-chip">In progress</mat-chip>
                <mat-chip *ngIf="borehole.status === BoreholeStatusEnum.CREATED">Nicht gefüllt</mat-chip>
              </ng-container>
              {{isInitialized ? '' : "Loading..."}}
            </td>
          </tr>
          <tr *ngIf="borehole.status !== BoreholeStatusEnum.CREATED">
            <th>Füllen Startdatum</th>
            <td>{{isInitialized ? ((borehole.startTimestamp | date) + ', ' + (borehole.startTimestamp | date: 'shortTime')) : "Loading..."}}</td>
          </tr>
          <tr *ngIf="borehole.status !== BoreholeStatusEnum.CREATED">
            <th>Füllen Enddatum</th>
            <td>{{isInitialized ? ((borehole.endTimestamp | date) + ', ' + (borehole.endTimestamp | date: 'shortTime')) : "Loading..."}}</td>
          </tr>
          <tr *ngIf="borehole.status !== BoreholeStatusEnum.CREATED">
            <th>Schichtnummer</th>
            <td>{{isInitialized ? borehole.boreholeLayers ? borehole.boreholeLayers.length : 7 : "Loading..."}}</td>
          </tr>
          <tr *ngIf="borehole.status !== BoreholeStatusEnum.CREATED">
            <th>Gesamtmasse</th>
            <td>{{isInitialized ? borehole.totalMass + " kg" : "Loading..."}}</td>
          </tr>
          <tr *ngIf="borehole.status !== BoreholeStatusEnum.CREATED">
            <th>Gesamtvolumen</th>
            <td>{{isInitialized ? borehole.totalVolume + " L" : "Loading..."}}</td>
          </tr>
          <tr *ngIf="borehole.pusherDiameterMM">
            <th>Durchmesser</th>
            <td>{{isInitialized ? borehole.pusherDiameterMM + " mm" : "Loading..."}}</td>
          </tr>
          <tr *ngIf="borehole.depth">
            <th>Tiefe</th>
            <td>{{isInitialized ? borehole.depth + " mm" : "Loading..."}}</td>
          </tr>
          <tr *ngIf="borehole.waterLevel">
            <th>Grundwasserstand</th>
            <td>{{isInitialized ? borehole.waterLevel + " mm" : "Loading..."}}</td>
          </tr>
          
        </table>
        <div class="loading-box" *ngIf="!isInitialized && !hasErrorLoadingBoreholeDetails" data-cy="loading-box">
          <mat-spinner></mat-spinner>
        </div>
        
        <div *ngIf="!isInitialized && hasErrorLoadingBoreholeDetails" class="error-box">
          <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
          <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
        </div>
                 <div style="position: fixed; width: 0; height: 0;" *ngIf="isWaitingForBoreholeResponse" data-cy="loading-box"></div>
      </mat-tab>
      <mat-tab data-cy="borehole-layers-tab" (click)="navigateToBoreholeLayers()" style="max-width: 110px" label="Borehole Layers"> 
         <elab-borehole-layer-list></elab-borehole-layer-list>
      </mat-tab>
      </mat-tab-group>
  </div>
</div>
