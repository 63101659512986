import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CreateProjectDto, GetProjectsByFilterDto, ProjectDto, UpdateProjectDto } from '@platri/geolog-common-core';
import { PageDto } from '@platri/elab-common-swagger';
import { environment } from '../../environments/default.environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectHttpService implements OnDestroy {
  baseUrl = environment.geologApi;

  constructor(
    private httpClient: HttpClient,
  ) {
    console.log('Initializing ProjectHttpService');
  }

  ngOnDestroy(): void {
    console.log('Destroying ProjectHttpService');
  }

  getAllProjects(): Observable<ProjectDto[]> {
    return this.httpClient.get<ProjectDto[]>(this.baseUrl + 'project');
  }
  
  getAllProjectsByFilter(getProjectsByFilterRequestDto: GetProjectsByFilterDto): Observable<PageDto<ProjectDto>> {
    getProjectsByFilterRequestDto.isOrCondition = false;
    return this.httpClient.post<PageDto<ProjectDto>>(this.baseUrl + 'project/filter', getProjectsByFilterRequestDto);
  }

  getProjectById(id: number): Observable<ProjectDto> {
    return this.httpClient.get<ProjectDto>(this.baseUrl + 'project/' + id);
  }
  
  createProject(createProjectDto: CreateProjectDto): Observable<number> {
    return this.httpClient.post<number>(this.baseUrl + 'project', createProjectDto);
  }

  updateProject(id: number, updateProjectDto: UpdateProjectDto): Observable<ProjectDto> {
    return this.httpClient.patch<ProjectDto>(this.baseUrl + 'project/' + id, updateProjectDto);
  }

}
