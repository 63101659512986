<div class="full-view">
  <div class="flex-container">
    <div class="loading-box" *ngIf="isWaitingForDeleteAccountResponse">
      <mat-spinner></mat-spinner>
    </div>
    
    <div *ngIf="!deleteAccountFailedNoConnection && !isWaitingForDeleteAccountResponse && hasSuccessfullyDeleteAccount">
      Ihr Konto wurde erfolgreich gelöscht!
    </div>
    
    <div *ngIf="!deleteAccountFailedNoConnection && !isWaitingForDeleteAccountResponse && !hasSuccessfullyDeleteAccount">
      Etwas ist schief gelaufen.
    </div>
    
    <div *ngIf="deleteAccountFailedNoConnection">
      Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.
    </div>
    
    <div *ngIf="deleteAccountFailedInternalServer">
      Fehler auf dem Server aufgetreten.
    </div>
  </div>
</div>
