import { BoreholeInterface } from './borehole.interface';
import { ProjectInterface } from './project.interface';
import { AddressInterface } from './address.interface';

export interface AreaInterface {
  id?: number;
  address?: AddressInterface;
  longitude?: number;
  latitude?: number;
  cadastralDistrict?: string;
  floor?: string;
  parcelNumber?: string;
  description?: string;
  boreholes?: BoreholeInterface[];
  project?: ProjectInterface;
  projectId?: number;
  soil?: string;
}
