import { BoreholeInterface } from './borehole.interface';
import { BoreholeLayerFillingMaterialEnum } from '../enums';

export interface BoreholeLayerInterface {
  id?: number;
  depthMM?: number;
  compressionLoadMPA?: number;
  fillingQuantity?: number;
  fillingMaterial?: BoreholeLayerFillingMaterialEnum;
  density?: number;
  layerThickness?: number;
  additionalInformation?: string;
  borehole?: BoreholeInterface;
  boreholeId?: number;
}
