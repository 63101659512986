<div class="full-view">
  <div class="table-with-search">
    <mat-form-field style="padding-top: 10px" appearance="outline">
        <mat-label style="font-size: 16px">
          <mat-icon style="font-size: 20px; vertical-align: top;">suchen</mat-icon> 
          Suchen
        </mat-label>
        <input (keydown.enter)="search()" matInput id="search-input" type="text" [formControl]="searchFormControl" placeholder="Hamburg" data-cy="search-input" >
      </mat-form-field>
    <table cdk-table [dataSource]="dataSource" data-cy="borehole-list-table">
      <ng-container cdkColumnDef="id">
        <th width="15%" style="text-align: left; color:#808080" cdk-header-cell *cdkHeaderCellDef> Physikalische Bohrloch-ID </th>
        <td cdk-cell *cdkCellDef="let row"> {{row.physicalBoreholeId}} </td>
      </ng-container>
      
      <ng-container cdkColumnDef="depth">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Tiefe </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.depth ? row.depth + ' mm' : '-'}} </td>
      </ng-container>
      
      <ng-container cdkColumnDef="diameter">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Durchmesser </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.pusherDiameterMM ? row.pusherDiameterMM + ' mm' : '-'}} </td>
      </ng-container>
      
      <ng-container cdkColumnDef="location">
        <th width="20%" style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Standort </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{'E ' + row.longitude}} <br> {{'N ' + row.latitude }} <br> {{'H ' + (row.altitude ?? '-') }}</td>
      </ng-container>
      
      <ng-container cdkColumnDef="waterLevel">
        <th width="20%" style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Grundwasserstand </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.waterLevel ? row.waterLevel + ' nm' : '-'}} </td>
      </ng-container>      
      
      <ng-container cdkColumnDef="status">
        <th width="40%" style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Status </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> 
          <mat-chip *ngIf="row.status === BoreholeStatusEnum.SUCCESSFULLY_FILLED" [ngClass]="'success-chip'" class="success-chip">Erfolgreich gefüllt</mat-chip>
          <mat-chip *ngIf="row.status === BoreholeStatusEnum.IN_PROGRESS" [ngClass]="'in-progress-chip'" class="in-progress-chip">In progress</mat-chip>
          <mat-chip *ngIf="row.status === BoreholeStatusEnum.CREATED">Nicht gefüllt</mat-chip>
        </td>
      </ng-container>
      
      
      <tr class="tr-header" cdk-header-row *cdkHeaderRowDef="['id', 'depth', 'diameter', 'location', 'waterLevel', 'status']"></tr>
      <tr class="clickable-row" cdk-row *cdkRowDef="let row; columns: ['id', 'depth', 'diameter', 'location', 'waterLevel', 'status']" (click)="handleRowClick(row)" data-cy="element-row">></tr>
    </table>
    
    <div class="loading-box" *ngIf="isLoadingBoreholes" data-cy="loading-box">
      <mat-spinner></mat-spinner>
    </div>
    
    <div class="error-box" *ngIf="hasFailedLoadingBoreholesNoConnection" data-cy="error-box">
      <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
      <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
    </div>
    
    <div class="error-box" *ngIf="hasFailedLoadingBoreholesInternalServerError" data-cy="error-box">
      <p style="color: red" data-cy="internal-server-error">Der Server hat einen Fehler zurückgegebe!</p>
      <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
    </div>
    
    <div style="text-align: center" *ngIf="hasLoadedBoreholes && !hasFailedLoadingBoreholesNoConnection && availableBoreholes.length === 0" data-cy="no-results-box">
      <h4 style="margin-bottom: 12px">Keine Bohrlöcher</h4>
      <p style="margin-block-start: 0; margin-block-end: 0;">Starten Sie mit dem Erstellen eines neuen Bohrlochs.</p>
    </div>
  </div>
</div>
