import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginDialogComponent } from './login-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { LoginFormModule } from '../../components/login-page/login-form/login-form.module';

@NgModule({
  declarations: [LoginDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    LoginFormModule,
    LoginFormModule
  ],
  exports: [LoginDialogComponent]
})
export class LoginDialogModule {
  constructor() {
    console.log('LoginDialogModule initialized');
  }
}
