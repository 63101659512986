import { BoreholeLayerDto } from './borehole-layer.dto';
import { BoreholeInterface, BoreholeStatusEnum } from '../../..';
import { BoreholeCommentDto } from './borehole-comment.dto';

export class UpdateBoreholeRequestDto implements BoreholeInterface {
  boreholeLayers: BoreholeLayerDto[];
  boreholeComments: BoreholeCommentDto[];
  status: BoreholeStatusEnum;
  
  constructor(args: UpdateBoreholeRequestDto) {
    if (args) { 
      this.boreholeLayers = args.boreholeLayers;
      this.boreholeComments = args.boreholeComments;
      this.status = args.status;
    } 
  }
}
