import { Component } from '@angular/core';

@Component({
  selector: 'elab-edit-project-page',
  templateUrl: './edit-project-page.component.html',
  styleUrls: ['./edit-project-page.component.scss']
})
export class EditProjectPageComponent {

  
  constructor(
  ) {
  }
  
  
}
