<form style="width: 100%;" [formGroup]="teamWorkerInviteFormGroup" (ngSubmit)="onSubmit()">
  <div class="content-container">
    <span>Einladung per E-Mail</span>
    <div class="input-container">
       <mat-form-field style="width: 100%; margin-bottom: -1.25em" appearance="outline">
          <mat-label style="font-size: 16px">
            Email
          </mat-label>
          <input matInput id="email-input" type="text" placeholder="max@mustermann.de" formControlName="invitedEmail">
       </mat-form-field>
      <mat-form-field style="width: 100%; margin-bottom: -1.25em" appearance="outline">
        <mat-label>Rolle</mat-label>
        <mat-select data-cy="role-select" formControlName="role">
          <mat-option *ngFor="let companyUserRole of (CompanyUserRoleEnum | keyvalue)" [value]="companyUserRole.value">
            {{companyUserRole.key | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button id="invite-worker-button" [disabled]="isWaitingForTeamWorkerInviteResponse" [class.spinner]="isWaitingForTeamWorkerInviteResponse" mat-raised-button style="width: 100%; max-width: 80px;" color="primary" type="submit" data-cy="invite-worker-button"> Einladen </button>
    </div>
    <div *ngIf="inviteFailedNoConnection || inviteFailedCompanyUserAlreadyInvitedOrExists || inviteFailedInternalServerError" style="height: 60px">
      <p *ngIf="inviteFailedNoConnection" class="invite-error" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
      <p *ngIf="inviteFailedCompanyUserAlreadyInvitedOrExists" class="invite-error" data-cy="company-user-already-invited-error">Dieser Benutzer existiert bereits oder ist zu dieser Firma eingeladen.</p>
      <p *ngIf="inviteFailedInternalServerError" class="invite-error" data-cy="internal-server-error">Interner Serverfehler ist aufgetreten</p>
    </div>
  </div>
</form>
