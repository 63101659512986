import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditProjectFormInterface } from './edit-project-form.interface';
import { Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologLoginRoute, GeologProjectsRoute } from '../../../shared/routes/geolog.routes';
import { ProjectHttpService } from '../../../shared/services/project-http.service';
import { ProjectInterface, UpdateProjectDto } from '@platri/geolog-common-core';
import { getSubscription } from '@platri/elab-angular-core';
import { GeologProjectIdRouterParam } from '../../../shared/router-params';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'elab-edit-project-form',
  templateUrl: './edit-project-form.component.html',
  styleUrls: ['./edit-project-form.component.scss']
})
export class EditProjectFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulEditProjectEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  editProjectFormGroup!: FormGroup<EditProjectFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForEditProjectResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;
  
  editFailedNoConnection = false;
  editFailedInternalServer = false;

  routerParams!: Params;

  isLoadingProject = false;
  hasFailedLoadingProjectNoConnection = false;
  
  loadedProject: Readonly<ProjectInterface>;
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectHttpService: ProjectHttpService,
    private matSnackbar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.editProjectFormGroup = this.createEditProjectFormGroup();
    this.editProjectFormGroup.disable();
  }

  createEditProjectFormGroup(): FormGroup<EditProjectFormInterface> {
    return this.fb.group<EditProjectFormInterface>({
      name: this.fb.control('', [Validators.required]),
      description: this.fb.control(''),
      client: this.fb.control(''),
      propertyOwner: this.fb.control(''),
      planner: this.fb.control('')
    });
  }
  
  patchEditProjectFormGroup(project: ProjectInterface): void {
    this.editProjectFormGroup.patchValue(project);
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.editProjectFormGroup.valueChanges.subscribe(res => {
      this.editFailedNoConnection = false;
      this.editFailedInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    if (!this.routerParams[GeologProjectIdRouterParam]) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    this.loadData();
  }


  loadData(): void {
    this.isLoadingProject = true;
    this.hasFailedLoadingProjectNoConnection = false;
    this.subscriptions.add(
      this.projectHttpService.getProjectById(this.routerParams[GeologProjectIdRouterParam]).subscribe(projectDto => {
        this.loadedProject = projectDto;
        this.patchEditProjectFormGroup(projectDto);
        this.editProjectFormGroup.enable();
        this.isLoadingProject = false;
      }, error => {
        console.log(error);
        this.isLoadingProject = false;
        if (error.status === 0) {
          this.onLoadProjectConnectionLost();
        }
      })
    );
  }
  

  onSubmit(): void {
    if (this.editProjectFormGroup.valid) {
      this.isWaitingForEditProjectResponse = true;
      this.editFailedNoConnection = false;
      this.editFailedInternalServer = false;
      const {name, planner, propertyOwner, description, client} = this.editProjectFormGroup.getRawValue();
      const updateProjectDto: UpdateProjectDto = new UpdateProjectDto(name!, description!, client!, propertyOwner!, planner!);
      this.subscriptions.add(this.projectHttpService.updateProject(this.routerParams[GeologProjectIdRouterParam], updateProjectDto).subscribe(createdProjectDto => {
        this.successfulEditProjectEmitter.next(true);
        this.matSnackbar.open("Successfully edited project!");
        this.router.navigate(['/', GeologAppRoute, GeologProjectsRoute, this.routerParams[GeologProjectIdRouterParam]]);
        this.isWaitingForEditProjectResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        this.isWaitingForEditProjectResponse = false;
      }));
    }
  }

  onLoadProjectConnectionLost(): void {
    this.hasFailedLoadingProjectNoConnection = true;
  }
  
  onConnectionLost(): void {
    (this.editFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.editFailedInternalServer = true;
  }

  public canDeactivate(): Observable<boolean> {
    if (!this.editProjectFormGroup.dirty) {
      return of(true);
    } else {
      return of(false);
    }
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
}
