import { BoreholeLayerFillingMaterialEnum, BoreholeLayerInterface } from '../../..';
import { BoreholeDto } from './borehole.dto';

export class BoreholeLayerDto implements BoreholeLayerInterface {
  id: number;
  depthMM: number;
  compressionLoadMPA: number;
  fillingQuantity: number;
  fillingMaterial: BoreholeLayerFillingMaterialEnum;
  density: number;
  layerThickness: number;
  additionalInformation: string;
  borehole: BoreholeDto;
  boreholeId: number;
  
  constructor(args: BoreholeLayerDto) {
    if (args) { 
      this.id = args.id;
      this.depthMM = args.depthMM;
      this.compressionLoadMPA = args.compressionLoadMPA;
      this.additionalInformation = args.additionalInformation;
      this.borehole = args.borehole;
      this.boreholeId = args.boreholeId;
    } 
  }
}
