import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BoreholeLayerInterface, GetBoreholeLayersByFilterDto } from '@platri/geolog-common-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologCreateProjectRoute, GeologLoginRoute } from '../../../shared/routes/geolog.routes';
import { getSubscription } from '@platri/elab-angular-core';
import { GeologAreaIdRouterParam, GeologBoreholeIdRouterParam, GeologProjectIdRouterParam } from '../../../shared/router-params';
import { BoreholeLayerHttpService } from '../../../shared/services/borehole-layer-http.service';

@Component({
  selector: 'elab-borehole-layer-list',
  templateUrl: './borehole-layer-list.component.html',
  styleUrls: ['./borehole-layer-list.component.scss']
})
export class BoreholeLayerListComponent implements OnInit, OnDestroy {
  
  searchFormControl: FormControl<string | null> = new FormControl<string>('');
  dataSource: BoreholeLayerInterface[] = [];
  availableBoreholeLayers: BoreholeLayerInterface[] = [];
  
  subscriptions: Subscription =  new Subscription();
  
  isLoadingBoreholeLayers = true;
  hasLoadedBoreholeLayers = false;
  hasFailedLoadingBoreholeLayersNoConnection = false;
  hasFailedLoadingBoreholeLayersInternalServerError = false;
  
  searchStrings: string[] = [];

  routerParams!: Params;
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boreholeLayerHttpService: BoreholeLayerHttpService
  ) {
  }
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    if (!this.routerParams[GeologProjectIdRouterParam] || !this.routerParams[GeologAreaIdRouterParam]) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    this.loadData();
  }


  loadData(): void {
    this.isLoadingBoreholeLayers = true;
    this.hasFailedLoadingBoreholeLayersNoConnection = false;
    this.hasFailedLoadingBoreholeLayersInternalServerError = false;
    const getBoreholesByFilterRequestDto = new GetBoreholeLayersByFilterDto();
    getBoreholesByFilterRequestDto.boreholeId = this.routerParams[GeologBoreholeIdRouterParam];
    getBoreholesByFilterRequestDto.take = 50;
    this.subscriptions.add(
      this.boreholeLayerHttpService.getBoreholeLayersByFilter(getBoreholesByFilterRequestDto).subscribe(boreholeLayerPageDto => {
        this.availableBoreholeLayers = boreholeLayerPageDto.data;
        this.dataSource = structuredClone(boreholeLayerPageDto.data);
        this.isLoadingBoreholeLayers = false;
        this.hasLoadedBoreholeLayers = true;
        this.searchStrings = [];
        boreholeLayerPageDto.data.map((boreholeLayerDto, index) => {
          let searchString = `${index};`;
          for (const boreholeLayerDtoKey in boreholeLayerDto) {
            if (boreholeLayerDtoKey !== "boreholeLayers") {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              searchString += boreholeLayerDto[boreholeLayerDtoKey] + ';';
            }
          }
          this.searchStrings.push(searchString);
        });
      }, error => {
        console.log(error);
        this.isLoadingBoreholeLayers = false;
        this.hasLoadedBoreholeLayers = true;
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
      })
    );
  }

  onConnectionLost(): void {
    this.hasFailedLoadingBoreholeLayersNoConnection = true;
  }
  
  onInternalServerError(): void {
    this.hasFailedLoadingBoreholeLayersInternalServerError = true;
  }
  
  search(): void {
    const userSearchString = this.searchFormControl.getRawValue();
    const filteredSearchStrings = this.searchStrings.filter(searchString => searchString.includes(userSearchString!));

    this.dataSource = filteredSearchStrings.map(filteredSearchString => {
      const index = filteredSearchString.split(';', 1)[0];
      return structuredClone(this.availableBoreholeLayers[+index]);
    });
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologCreateProjectRoute = GeologCreateProjectRoute;
}
