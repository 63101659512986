import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { RegisterCompanyFormComponent } from './register-company-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import {GoogleAddressSearchAutocompleteModule} from "../../google-address-search-autocomplete";

@NgModule({
  declarations: [RegisterCompanyFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        RouterLink,
        GoogleAddressSearchAutocompleteModule
    ],
  exports: [RegisterCompanyFormComponent]
})
export class RegisterCompanyFormModule { }
