import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { GeologAppComponent } from './geolog-app.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [GeologAppComponent],
  imports: [
    CommonModule,
    RouterOutlet,
    MatButtonModule,
    RouterLink,
    RouterLinkActive,
    MatDividerModule,
    MatMenuModule,
    MatIconModule
  ],
  exports: [
    GeologAppComponent,
  ]
})
export class GeologAppModule {
  constructor() {
    console.log('BestPractiseAppModule initialized');
  }
}
