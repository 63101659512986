import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginFormInterface } from './login-form.interface';
import { Subscription } from 'rxjs';
import { AuthStateService } from '../../../shared/services/auth-state.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologForgotPasswordRoute, GeologLoginRoute, GeologProjectsRoute, GeologRegisterRoute } from '../../../shared/routes/geolog.routes';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomMatSnackbarService } from '../../../shared/services/custom-mat-snackbar.service';
import { GeologCompanyIdRouterParam, GeologJoinCompanyCodeTokenRouterParam } from '../../../shared/router-params';
import { getSubscription } from '@platri/elab-angular-core';

@Component({
  selector: 'elab-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulLoginEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  loginFormGroup!: FormGroup<LoginFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForLoginResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;
  
  signInFailedWrongData = false;
  signInFailedNoConnection = false;
  signInFailedInternalServer = false;
  
  hidePassword = true;
  
  routerParams!: Params;
  returnUrlFromParam: string;

  constructor(
    private fb: FormBuilder,
    private authStateService: AuthStateService,
    private router: Router,
    private matSnackbar: CustomMatSnackbarService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.loginFormGroup = this.createLoginFormGroup();
  }

  createLoginFormGroup(): FormGroup<LoginFormInterface> {
    return this.fb.group<LoginFormInterface>({
      email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      password: this.fb.control('', [Validators.required])
    });
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.loginFormGroup.valueChanges.subscribe(res => {
      this.signInFailedWrongData = false;
      this.signInFailedNoConnection = false;
      this.signInFailedInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.queryParams, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.returnUrlFromParam = this.routerParams['returnUrl'] ?? null;
  }

  onSubmit(): void {
    if (this.loginFormGroup.valid || (this.loginFormGroup.controls.email.hasError('password_wrong') || this.loginFormGroup.controls.password.hasError('password_wrong'))) {
      this.isWaitingForLoginResponse = true;
      this.signInFailedWrongData = false;
      this.signInFailedNoConnection = false;
      this.signInFailedInternalServer = false;
      const {email, password} = this.loginFormGroup.getRawValue();
      this.subscriptions.add(this.authStateService.login(password!, '', email!).subscribe(res => {
        this.successfulLoginEmitter.next(true);
        this.matSnackbar.openSuccess("Successfully logged in!");
        if (this.returnUrlFromParam) {
          this.router.navigateByUrl(this.returnUrlFromParam);
        } else {
          this.router.navigate([GeologAppRoute, GeologProjectsRoute]);
        }
        this.isWaitingForLoginResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        } else {
          this.onWrongPasswordEntered();
        }
        this.isWaitingForLoginResponse = false;
      }));
    }
  }

  onConnectionLost(): void {
    (this.signInFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.signInFailedInternalServer = true;
  }
  
  onWrongPasswordEntered(): void {
    this.signInFailedWrongData = true;
    this.loginFormGroup.controls.email.setErrors({'password_wrong': true});
    this.loginFormGroup.controls.password.setErrors({'password_wrong': true});
  }
  
  routeToRegister(): void {
    this.router.navigate(['/' + GeologAppRoute, GeologRegisterRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
  }
  
  routeToForgotPassword(): void {
    this.router.navigate(['/' + GeologAppRoute, GeologForgotPasswordRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologForgotPasswordRoute = GeologForgotPasswordRoute;
  protected readonly GeologRegisterRoute = GeologRegisterRoute;
}
