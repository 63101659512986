import { AreaInterface } from '../../..';
import { AddressDto } from './address.dto';

export class UpdateAreaRequestDto implements AreaInterface {
  address: AddressDto;
  cadastralDistrict: string;
  description: string;
  soil: string;
  longitude: number;
  latitude: number;
  altitude: number;
  floor: string;
  parcelNumber: string;
  projectId: number;
}
