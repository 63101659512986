import { Component } from '@angular/core';
import { GeologAppRoute } from '../lib/shared/routes/geolog.routes';

@Component({
  selector: 'elab-standalone-app-root',
  templateUrl: './standalone-app.component.html',
  styleUrls: ['./standalone-app.component.scss']
})
export class StandaloneAppComponent {
  
  title = 'wrapper';
  

  protected readonly GeologAppRoute = GeologAppRoute;
}
