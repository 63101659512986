import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsPageComponent } from './projects-page.component';
import { ResetPasswordFormModule } from '../../components/reset-password-page/reset-password-form/reset-password-form.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ProjectsPageComponent],
  imports: [
    CommonModule,
    ResetPasswordFormModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CdkTableModule,
    MatIconModule,
    RouterLink,
    MatProgressSpinnerModule
  ],
  exports: [ProjectsPageComponent]
})
export class ProjectsPageModule { }
