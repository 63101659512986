import { BoreholeCommentInterface } from '../../..';

export class BoreholeCommentDto implements BoreholeCommentInterface {
  id: number;
  created_at: Date;
  text: string;
  userId: string;
  boreholeId: number;
  
  constructor(args: BoreholeCommentDto) {
    if (args) { 
      this.id = args.id;
      this.userId = args.userId;
      this.boreholeId = args.boreholeId;
      this.created_at = args.created_at;
      this.text = args.text;
    } 
  }
}
