import { FormControl } from '@angular/forms';
import { BoreholeStatusEnum } from '@platri/geolog-common-core';

export interface CreateBoreholeFormInterface {
  boreholeId: FormControl<string | null>;
  status: FormControl<BoreholeStatusEnum | null>;
  depth: FormControl<number | null>;
  diameter: FormControl<number | null>;
  longitude: FormControl<number | null>;
  latitude: FormControl<number | null>;
  altitude: FormControl<number | null>;
  waterLevel: FormControl<number | null>;
}
