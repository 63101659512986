import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ForgotPasswordFormModule } from '../../components/forgot-password-page/forgot-password-form/forgot-password-form.module';

@NgModule({
  declarations: [ForgotPasswordDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ForgotPasswordFormModule
  ],
  exports: [ForgotPasswordDialogComponent]
})
export class ForgotPasswordDialogModule {
  constructor() {
    console.log('ForgotPasswordDialogModule initialized');
  }
}
