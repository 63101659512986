import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
// eslint-disable-next-line @nx/enforce-module-boundaries
import proj4 from 'proj4';
import { BoreholeInterface } from '@platri/geolog-common-core';

@Component({
  selector: 'elab-upload-boreholes',
  templateUrl: './upload-boreholes.component.html',
  styleUrls: ['./upload-boreholes.component.scss']
})
export class UploadBoreholesComponent {
  @ViewChild('fileDropRef', {static: false}) fileDropElement: ElementRef;
  @Output() DataChangeEmitter: EventEmitter<BoreholeInterface[]> = new EventEmitter<BoreholeInterface[]>();
  files: any[] = [];

  headers: any[] = []; //for headings
  dataRows: any[] = []; // for rows
  
  coordinateSystem: CoordinateSystemEnum;
  
  constructor(
    private matSnackbar: MatSnackBar
  ) {}
 
  onFileDropped($event: any): void {
    this.prepareFilesList($event);
  }

  onFileBrowseUpload(target: EventTarget | null): void {
    // @ts-ignore
    this.prepareFilesList(target.files);
  }

  prepareFilesList(files: Array<File>): void {
    for (const item of files) {
      if (!this.files.find(file => file.name === item.name && file.size === item.size)) {
        if (item.name.includes('csv')) {
          this.files.push(item);
        } else {
          this.matSnackbar.open("Can't open files other than .csv");
        }
      }
    }
    this.fileDropElement.nativeElement.value = '';
    this.dataRows = [];
    this.files.forEach(file => {
      this.readDocument(file);
    });
    if (this.files.length === 0) {
      this.DataChangeEmitter.emit([]);
    }
  }

  readDocument(file: any): void {
    const fileReader = new FileReader();
    fileReader.onload = (e): void => {
      this.parseDocument(fileReader.result);
    };
    fileReader.readAsText(file);
  }

  parseDocument(csv: any): void {
    const allTextLines = csv.split(/\r|\n|\r/).filter((row: string) => row?? row);
    const headers = this.getHeaders(allTextLines);
    if (headers && headers.length > 0) {
      this.headers = headers;
      this.dataRows = this.getDataRows(allTextLines);
    }
    this.onSave();
  }
  
  getHeaders(allTextLines: string[]): string[] {
    const headers = allTextLines[0].split(';').filter((header) => header !== '');
    if (this.headers.length > 0 && !this.equalsCheck(this.headers, headers)) {
      this.files.pop();
      this.matSnackbar.open("File couldn't be loaded because it is a different type");
      return [];
    }
    
    return headers;
    
  }
  
  getDataRows(allTextLines: string[]): string[] {
    const dataRowsFromTextLines = allTextLines.slice(1, allTextLines.length).map(textLine => textLine.split(';').filter(splittedTextLine => splittedTextLine ?? splittedTextLine));
    return [...this.dataRows, ...dataRowsFromTextLines];
    
  }
  

  deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.prepareFilesList([]);
    if (this.files.length === 0) {
      this.headers = [];
    }
  }

  formatBytes(bytes: any, decimals = 2): any {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  

  equalsCheck(a: any[], b: any[]): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }
  
  onSave(): void {
    const objects = this.getObjectsFromTable();
    this.coordinateSystem = this.getCoordinateSystem();
    switch (this.coordinateSystem) {
      case CoordinateSystemEnum.DECIMAL:
        this.emitData(objects);
        break;
      case CoordinateSystemEnum.UTM:
        // eslint-disable-next-line no-case-declarations
        const convertedObjects: any[] = [];
        objects.forEach(object => {
          convertedObjects.push(this.convertUTMToDecimal(object));
        });
        this.emitData(convertedObjects);
        break;
      case CoordinateSystemEnum.NOT_FOUND:
        this.matSnackbar.open("Couldn't save because of unknown data format");
        break;
      default: {
        this.DataChangeEmitter.emit([]);
      }  
    }
  }
  
  getObjectsFromTable(): any[] {
    const objects = [];
    for (let i = 0; i < this.dataRows.length; i++) {
      const object: any = {};
      for (let x = 0; x <  this.headers.length; x++) {
        object[this.headers[x]] = this.dataRows[i][x];
      }
      objects.push(object);
    }
    return objects;
  }
  
  getCoordinateSystem(): CoordinateSystemEnum {
    switch (this.headers.length) {
      case 2: {
        return CoordinateSystemEnum.DECIMAL;
      }
      
      case 5: {
        return CoordinateSystemEnum.UTM;
      }
      
      default: {
        return CoordinateSystemEnum.NOT_FOUND;
      }
    }
  }
  
  convertUTMToDecimal(input: UTMType): DezimalgradType {
    const northOrSouth = parseFloat(input['y-ho']) > 0 ? "+north" : "+south";
    const utmString = `+proj=utm +zone=${input.U}${northOrSouth} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`;
    const [longitude, latitude] = proj4(utmString, 'WGS84', [parseFloat(input['x-re']), parseFloat(input['y-ho'])]);

    return {
      'Bohrloch Name': input['Bohrloch Name'],
      Dezimalgrad: `${latitude.toFixed(8)}°N ${longitude.toFixed(8)}°E`,
      'Altitude': input['Höhe ü NN']
    };
  }
  
  convertFromDecimalToBoreholeInterface(input: DezimalgradType): BoreholeInterface {
    const borehole: BoreholeInterface = {
      physicalBoreholeId: input['Bohrloch Name'],
      latitude: +input.Dezimalgrad.split(' ')[0].replace(/[^\d.-]/g, ''),
      longitude: +input.Dezimalgrad.split(' ')[1].replace(/[^\d.-]/g, ''),
      altitude: +input.Altitude?.replace(',', '.') ?? null,
    };
    return borehole;
  }
  
  emitData(input: DezimalgradType[]): void {
    const boreholesData: BoreholeInterface[] = input.map(boreholeData => this.convertFromDecimalToBoreholeInterface(boreholeData));
    this.DataChangeEmitter.emit(boreholesData);
  }

  protected readonly CoordinateSystemEnum = CoordinateSystemEnum;
}

enum CoordinateSystemEnum {
  DECIMAL = "DECIMAL",
  UTM = "UTM",
  NOT_FOUND = "NOT_FOUND"
}

interface DezimalgradType {
  'Bohrloch Name': string;
  Dezimalgrad: string;
  'Altitude': string;
}

interface UTMType {
  'Bohrloch Name': string;
  U: string;
  'x-re': string;
  'y-ho': string;
  'Höhe ü NN': string;
}
