import { FormControl } from '@angular/forms';
import { AddressInterface } from '@platri/geolog-common-core';

export interface RegisterCompanyFormInterface {
  name: FormControl<string | null>;
  licenseKey: FormControl<string | null>;
  billingAddress: FormControl<AddressInterface | null>;
  email: FormControl<string | null>;
  phoneNumber: FormControl<string | null>;
}
