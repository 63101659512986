<div class="flex-container">
  <form class="form flex-container-centered-content" [formGroup]="resetPasswordFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize && !hasSuccessfullyResetPassword">
    <h4 style="align-self: flex-start;">Jetzt neues Passwort setzen!</h4>
    <div class="flex-container-row">
      <div class="form-step-container flex-container-column">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input id="email-input" matInput type="text" [formControl]="resetPasswordFormGroup.controls.email" placeholder="max.muster@platri.de" data-cy="email-input">
          <mat-error *ngIf="resetPasswordFormGroup.controls.email.hasError('pattern')">
          Bitte geben Sie eine korrekte Email-Adresse ein!
          </mat-error>
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Passwort</mat-label>
          <input id="password-input" matInput [formControl]="resetPasswordFormGroup.controls.password" placeholder="12345678" [type]="hidePassword ? 'password' : 'text'" data-cy="password-input">
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Passwort bestätigen</mat-label>
          <input id="confirm-password-input" matInput [formControl]="resetPasswordFormGroup.controls.confirmPassword" placeholder="12345678" [type]="hidePassword ? 'password' : 'text'" data-cy="confirm-password-input">
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="resetPasswordFormGroup.controls.confirmPassword.errors?.['mustMatch']">
            Passwörter stimmen nicht überein
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  
    <div style="width: 100%">
      <button id="reset-password-button" [disabled]="isWaitingForResetPasswordResponse" [class.spinner]="isWaitingForResetPasswordResponse" style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="submit-button">Speichern</button>
    </div>

    <div style="height: 60px">
      <p class="reset-password-error" *ngIf="resetPasswordFailedWrongData" data-cy="wrong-data-error">Die angegebenen Daten sind falsch</p>
      <p class="reset-password-error" *ngIf="resetPasswordFailedNoConnection" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
      <p class="reset-password-error" *ngIf="resetPasswordFailedInternalServer" data-cy="internal-server-error">Fehler auf dem Server aufgetreten.</p>
    </div>
    
    
  </form>
  
  <div id="successful-reset-password" *ngIf="hasSuccessfullyResetPassword" class="flex-container-centered-content" data-cy="successful-box">
    <p>Passwort zurücksetzen war erfolgreich!</p>
    <button id="navigate-to-login-button" mat-raised-button color="primary" type="button" (click)="routeToLogin()" data-cy="back-to-login-button">Go to login</button>
  </div>
  
</div>

<p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">Fataler Fehler beim Laden der benötigten Daten!</p>
