import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditProjectPageComponent } from './edit-project-page.component';
import { EditProjectFormModule } from '../../components/edit-project-page/edit-project-form/edit-project-form.module';

@NgModule({
  declarations: [EditProjectPageComponent],
  imports: [
    CommonModule,
    EditProjectFormModule
  ],
  exports: [EditProjectPageComponent]
})
export class EditProjectPageModule { }
