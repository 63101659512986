import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAreaPageComponent } from './create-area-page.component';
import { CreateAreaFormModule } from '../../components/create-area-page/create-area-form/create-area-form.module';
import { CreateAreaFormV2Module } from '../../components/create-area-page/create-area-form-v2';

@NgModule({
  declarations: [CreateAreaPageComponent],
  imports: [
    CommonModule,
    CreateAreaFormModule,
    CreateAreaFormV2Module
  ],
  exports: [CreateAreaPageComponent]
})
export class CreateAreaPageModule { }
