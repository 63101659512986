import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologMagicLinkLoginTokenRouterParam } from '../../shared/router-params';
import { GeologAppRoute, GeologLoginRoute } from '../../shared/routes/geolog.routes';
import { AuthStateService } from '../../shared/services/auth-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'elab-magic-link-login-page',
  templateUrl: './magic-link-login-page.component.html',
  styleUrls: ['./magic-link-login-page.component.scss']
})
export class MagicLinkLoginPageComponent implements OnInit, OnDestroy {

  subscriptions: Subscription =  new Subscription();

  isWaitingForMagicLinkLoginResponse = false;

  magicLinkLoginFailedWrongData = false;
  magicLinkLoginFailedNoConnection = false;
  magicLinkLoginFailedInternalServer = false;

  hasSuccessfullyMagicLinkLogin = false;
  
  routerParams!: Params;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authStateService: AuthStateService,
    private matSnackbar: MatSnackBar
  ) {}
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    if (!this.routerParams[GeologMagicLinkLoginTokenRouterParam]) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    } else {
      this.magicLinkLogin();
    }
  }
  
  magicLinkLogin(): void {
    this.isWaitingForMagicLinkLoginResponse = true;
    this.magicLinkLoginFailedWrongData = false;
    this.magicLinkLoginFailedNoConnection = false;
    this.magicLinkLoginFailedInternalServer = false;
    this.subscriptions.add(this.authStateService.magicLinkLogin(this.routerParams[GeologMagicLinkLoginTokenRouterParam]).subscribe(res => {
      this.hasSuccessfullyMagicLinkLogin = true;
      this.isWaitingForMagicLinkLoginResponse = false;
      this.matSnackbar.open("Successfully login via magic link!");
    }, error => {
      console.log(error);
      if (error.status === 0) {
        this.onConnectionLost();
      }
      this.isWaitingForMagicLinkLoginResponse = false;
    }));
  }

  onConnectionLost(): void {
    (this.magicLinkLoginFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.magicLinkLoginFailedInternalServer = true;
  }

}
