import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BoreholeLayerDto, GetBoreholeLayersByFilterDto } from '@platri/geolog-common-core';
import { PageDto } from '@platri/elab-common-swagger';
import { environment } from '../../environments/default.environment';

@Injectable({
  providedIn: 'root',
})
export class BoreholeLayerHttpService implements OnDestroy {
  baseUrl = environment.geologApi;

  constructor(
    private httpClient: HttpClient,
  ) {
    console.log('Initializing BoreholeLayerHttpService');
  }

  ngOnDestroy(): void {
    console.log('Destroying BoreholeLayerHttpService');
  }
  
  getBoreholeLayersByFilter(getBoreholeLayersByFilterDto: GetBoreholeLayersByFilterDto): Observable<PageDto<BoreholeLayerDto>> {
    return this.httpClient.post<PageDto<BoreholeLayerDto>>(this.baseUrl + 'borehole-layer/filter', getBoreholeLayersByFilterDto);
  }
  
  getBoreholeLayerById(boreholeLayerId: number): Observable<BoreholeLayerDto> {
    return this.httpClient.get<BoreholeLayerDto>(this.baseUrl + 'borehole-layer/' + boreholeLayerId);
  }

}
