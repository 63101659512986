import { BoreholeLayerFillingMaterialEnum, BoreholeLayerInterface } from '../../..';

export class CreateBoreholeLayerManualRequestDto implements BoreholeLayerInterface {
  fillingQuantity: number;
  fillingMaterial: BoreholeLayerFillingMaterialEnum;
  boreholeId: number;
  layerNumber: number;
  
  constructor(args: CreateBoreholeLayerManualRequestDto) {
    if (args) {
      this.fillingQuantity = args.fillingQuantity;
      this.fillingMaterial = args.fillingMaterial;
      this.boreholeId = args.boreholeId;
    }
  }
}
