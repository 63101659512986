import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ApplicationRef, enableProdMode, LOCALE_ID } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { StandaloneAppModule } from './standalone-app.module';
import { environment, ServerEnvironmentEnum } from '@platri/elab-angular-environments';

platformBrowserDynamic([{ provide: LOCALE_ID, useValue: 'de-DE' }])
  .bootstrapModule(StandaloneAppModule, {
    providers: [
      {
        provide: LOCALE_ID,
        useValue: 'de-DE'
      }
    ]
  })
  .then((ref) => {
    const applicationRef = ref.injector.get(ApplicationRef);
    const componentRef = applicationRef.components[0];
    enableDebugTools(componentRef);
  })
  .catch((err) => window['console'].error(err));

if (environment.production && !(environment.serverEnvironment === ServerEnvironmentEnum.E2E) && !(environment.serverEnvironment === ServerEnvironmentEnum.LOCAL)) {
  enableProdMode();
} else {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.appReady = true;
}
