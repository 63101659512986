import { ProjectInterface } from '../../..';

export class UpdateProjectDto implements ProjectInterface {
  name?: string;
  description?: string;
  client?: string;
  propertyOwner?: string;
  planner?: string;
  
  constructor(name: string, description: string, client: string, propertyOwner: string, planner: string) {
    this.name = name;
    this.description = description;
    this.client = client;
    this.propertyOwner = propertyOwner;
    this.planner = planner;
  }
}
