import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateBoreholePageComponent } from './create-borehole-page.component';
import { CreateAreaFormModule } from '../../components/create-area-page/create-area-form/create-area-form.module';
import { CreateBoreholeFormModule } from '../../components/create-borehole-page/create-borehole-form/create-borehole-form.module';

@NgModule({
  declarations: [CreateBoreholePageComponent],
  imports: [
    CommonModule,
    CreateAreaFormModule,
    CreateBoreholeFormModule
  ],
  exports: [CreateBoreholePageComponent]
})
export class CreateBoreholePageModule { }
