import { FormControl } from '@angular/forms';

export interface EditCompanyFormInterface {
  name: FormControl<string | null>;
  licenseKey: FormControl<string | null>;
  businessArea: FormControl<string | null>;
  billingAddress: FormControl<string | null>;
  email: FormControl<string | null>;
  phoneNumber: FormControl<string | null>;
}
