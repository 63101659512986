import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { AreaInterface, BoreholeInterface, BoreholeStatusEnum, ProjectInterface } from '@platri/geolog-common-core';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAreaIdRouterParam, GeologBoreholeIdRouterParam, GeologProjectIdRouterParam } from '../../shared/router-params';
import { GeologAppRoute, GeologAreasRoute, GeologBoreholesRoute, GeologEditAreaRoute, GeologEditProjectRoute, GeologLayersRoute, GeologLoginRoute, GeologProjectsRoute } from '../../shared/routes/geolog.routes';
import { BoreholeHttpService } from '../../shared/services/borehole-http.service';
import { ProjectHttpService } from '../../shared/services/project-http.service';

enum BoreholeDetailPageUrlFragmentEnum {
  OVERVIEW = 'Übersicht',
  BOREHOLE_LAYERS = 'borehole-layers'
}
@Component({
  selector: 'elab-borehole-detail-page',
  templateUrl: './borehole-detail-page.component.html',
  styleUrls: ['./borehole-detail-page.component.scss']
})
export class BoreholeDetailPageComponent implements OnInit, OnDestroy {
  
  searchFormControl: FormControl<string | null> = new FormControl<string>('');
  dataSource : AreaInterface[] = [];
  
  subscriptions: Subscription =  new Subscription();
  
  isInitialized = false;
  isWaitingForBoreholeResponse = false;
  hasErrorLoadingBoreholeDetails = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;

  routerParams!: Params;
  projectIdFromParam: number;
  areaIdFromParam: number;
  boreholeIdFromParam: number;
  
  borehole: BoreholeInterface;
  project: ProjectInterface;

  selectedIndex = 0;
  
  constructor(
    private matDialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boreholeHttpService: BoreholeHttpService,
    private projectHttpService: ProjectHttpService,
  ) {
  }
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.activatedRoute.fragment, this.onUrlFragmentChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.projectIdFromParam = this.routerParams[GeologProjectIdRouterParam];
    this.areaIdFromParam = this.routerParams[GeologAreaIdRouterParam];
    this.boreholeIdFromParam = this.routerParams[GeologBoreholeIdRouterParam];
    if (!this.projectIdFromParam || !this.areaIdFromParam || !this.boreholeIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    if (this.activatedRoute.snapshot.url[6] && this.activatedRoute.snapshot.url[6].path === "layers") {
      this.selectedIndex = 1;
    }

    this.loadData();
  }

  onUrlFragmentChanges(fragment: string): void {
    if (fragment !== null) {
      switch (fragment.toLowerCase()) {
        default:
        case BoreholeDetailPageUrlFragmentEnum.OVERVIEW: {
          this.selectedIndex = 0;
          break;
        }
        case BoreholeDetailPageUrlFragmentEnum.BOREHOLE_LAYERS: {
          this.selectedIndex = 1;
          break;
        }
      }
    }
  }

  changeUrlFragment(index: number): void {
    switch (index) {
      case 0: {
        window.location.hash = '#' + BoreholeDetailPageUrlFragmentEnum.OVERVIEW;
        break;
      }
      case 1: {
        window.location.hash = '#' + BoreholeDetailPageUrlFragmentEnum.BOREHOLE_LAYERS;
        break;
      }
    }
  }


  loadData(): void {
    this.hasErrorLoadingBoreholeDetails = false;
    this.isWaitingForBoreholeResponse = true;
    this.subscriptions.add(
      this.boreholeHttpService.getBoreholeById(this.boreholeIdFromParam).subscribe(boreholeDto => {
        this.borehole = boreholeDto;
        this.isWaitingForBoreholeResponse = false;
        this.isInitialized = true;
      }, error => {
        this.hasErrorLoadingBoreholeDetails = true;
        this.isWaitingForBoreholeResponse = false;
      })
    );

    this.subscriptions.add(
      this.projectHttpService.getProjectById(this.projectIdFromParam).subscribe(projectDto => {
        this.project = projectDto;
      }, error => {
      })
    );
  }

  navigateToBoreholeLayers(): void {
    this.router.navigate([GeologLayersRoute]);
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
  protected readonly GeologProjectIdRouterParam = GeologProjectIdRouterParam;
  protected readonly GeologEditProjectRoute = GeologEditProjectRoute;
  protected readonly GeologEditAreaRoute = GeologEditAreaRoute;
  protected readonly GeologAreasRoute = GeologAreasRoute;
  protected readonly GeologBoreholesRoute = GeologBoreholesRoute;
  protected readonly BoreholeStatusEnum = BoreholeStatusEnum;
}
