<div class="full-view">
  <div class="full-upload-container">
    <div class="upload-container dashed-border" drag-and-drop (fileDropped)="onFileDropped($event)">
      <div style="height: 100%; display: flex">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="onFileBrowseUpload($event.target)"/>
        <div style="margin: auto">
          <mat-icon style="width: 24px; height: 24px;">upload</mat-icon>
          <div style="display: flex; text-align: center; place-content: center;">
            <p>Datei hierher ziehen oder&nbsp;</p> <p class="link">Datei auswählen</p>
          </div>
          <p class="sub-text">UTM oder GPS</p>
        </div>
      </div>
    </div>
    <div class="files-list">
      <div class="single-file" *ngFor="let file of files; let i = index">
        <img *ngIf="coordinateSystem === CoordinateSystemEnum.DECIMAL" src="/assets/GPS.svg" width="45px" alt="file">
        <img *ngIf="coordinateSystem === CoordinateSystemEnum.UTM" src="/assets/UTM.svg" width="45px" alt="file">
        <img *ngIf="coordinateSystem === CoordinateSystemEnum.NOT_FOUND" src="/assets/unknown.svg" width="45px" alt="file">
        <div class="info">
          <h4 class="name">
            {{ file?.name }}
          </h4>
          <p class="size sub-text">
            {{ formatBytes(file?.size) }}
          </p>
        </div>
        <img src="/assets/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
      </div>
    </div>
  </div>
  <div *ngIf="coordinateSystem !== CoordinateSystemEnum.NOT_FOUND" class="table-with-data">
      <table style="width: 100%" class="table table-bordered table-dark mt-5">
        <thead>
          <tr>
            <th style="text-align: left" *ngFor="let header of headers; let i = index">{{header}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dataRow of dataRows; let i = index">
            <td class="sub-text" *ngFor="let dataRowColumn of headers; let columnIndex = index">{{dataRow[columnIndex]}}</td>
            
          </tr>
        </tbody>
      </table>
    </div>
</div>
