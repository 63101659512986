import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JoinCompanyFormInterface } from './join-company-form.interface';
import { Subscription } from 'rxjs';
import { GeologAppRoute } from '../../../shared/routes/geolog.routes';
import { CompanyHttpService } from '../../../shared/services/company-http.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'elab-join-company-form',
  templateUrl: './join-company-form.component.html',
  styleUrls: ['./join-company-form.component.scss']
})
export class JoinCompanyFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulJoinCompanyEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  joinCompanyFormGroup!: FormGroup<JoinCompanyFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForJoinCompanyResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;
  
  joinCompanyFailedWrongData = false;
  joinCompanyFailedNoConnection = false;
  joinCompanyFailedInternalServer = false;

  constructor(
    private fb: FormBuilder,
    private companyHttpService: CompanyHttpService,
    private matSnackbar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.joinCompanyFormGroup = this.createLoginFormGroup();
  }

  createLoginFormGroup(): FormGroup<JoinCompanyFormInterface> {
    return this.fb.group<JoinCompanyFormInterface>({
      code: this.fb.control('', [Validators.required])
    });
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.joinCompanyFormGroup.valueChanges.subscribe(res => {
      this.joinCompanyFailedWrongData = false;
      this.joinCompanyFailedNoConnection = false;
      this.joinCompanyFailedInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
  }
  

  onSubmit(): void {
    if (this.joinCompanyFormGroup.valid) {
      this.isWaitingForJoinCompanyResponse = true;
      this.joinCompanyFailedWrongData = false;
      this.joinCompanyFailedNoConnection = false;
      this.joinCompanyFailedInternalServer = false;
      const {code} = this.joinCompanyFormGroup.getRawValue();
      this.subscriptions.add(this.companyHttpService.joinCompany({joinCompanyCode: code!}).subscribe(res => {
        this.successfulJoinCompanyEmitter.next(true);
        this.matSnackbar.open('Successfully joined company!');
       // this.router.navigate([GeologAppRoute]);
        this.isWaitingForJoinCompanyResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        } else {
          this.onWrongPasswordEntered();
        }
        this.isWaitingForJoinCompanyResponse = false;
      }));
    }
  }

  onConnectionLost(): void {
    this.joinCompanyFailedNoConnection = true;
  }
  
  onInternalServerError(): void {
    this.joinCompanyFailedInternalServer = true;
  }
  
  onWrongPasswordEntered(): void {
    this.joinCompanyFailedWrongData = true;
  }

  protected readonly GeologAppRoute = GeologAppRoute;
}
