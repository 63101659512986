<div class="full-view">
  <div class="flex-container">
    <div class="loading-box" *ngIf="isWaitingForDeactivateAccountResponse">
      <mat-spinner></mat-spinner>
    </div>
    
    <div *ngIf="!deactivateAccountFailedNoConnection && !isWaitingForDeactivateAccountResponse && hasSuccessfullyDeactivateAccount">
      Ihr Konto wurde erfolgreich deaktiviert!
    </div>
    
    <div *ngIf="!deactivateAccountFailedNoConnection && !isWaitingForDeactivateAccountResponse && !hasSuccessfullyDeactivateAccount">
      Etwas ist schief gelaufen.
    </div>
    
    <div *ngIf="deactivateAccountFailedNoConnection">
      Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.
    </div>
    
    <div *ngIf="deactivateAccountFailedInternalServer">
      Fehler auf dem Server aufgetreten.
    </div>
  </div>
</div>
