import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmEmailPageComponent } from './confirm-email-page.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [ConfirmEmailPageComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  exports: [ConfirmEmailPageComponent]
})
export class ConfirmEmailPageModule { }
