<div class="full-view">
  <div class="header-bar">
      <h3>Projekte</h3>
      <button mat-raised-button color="primary" type="button" [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, GeologCreateProjectRoute]">+ Neues Projekt</button>
  </div>
  <div class="table-with-search">
      <mat-form-field appearance="outline">
        <mat-label style="font-size: 16px">
          <mat-icon style="font-size: 20px; vertical-align: top;" >suchen</mat-icon> 
          Suchen
        </mat-label>
        <input (keydown.enter)="search()" matInput id="search-input" type="text" [formControl]="searchFormControl" placeholder="Hamburg" >
      </mat-form-field>
    
    <table cdk-table [dataSource]="dataSource" data-cy="projects-list-table">
      <ng-container cdkColumnDef="name">
        <th style="text-align: left; color:#808080" cdk-header-cell *cdkHeaderCellDef> Projektname </th>
        <td width="50%" cdk-cell *cdkCellDef="let row"> {{row.name}} </td>
      </ng-container>
      
      <ng-container cdkColumnDef="client">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Kunde </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.client ? row.client : '-'}} </td>
      </ng-container>
      
      <tr class="tr-header" cdk-header-row *cdkHeaderRowDef="['name', 'client']"></tr>
      <tr class="clickable-row" cdk-row *cdkRowDef="let row; columns: ['name', 'client']" (click)="handleRowClick(row)" data-cy="element-row">></tr>
    </table>
    
    <div class="loading-box" *ngIf="isLoadingProjects" data-cy="loading-box">
      <mat-spinner></mat-spinner>
    </div>
    
    <div class="error-box" *ngIf="hasFailedLoadingProjectsNoConnection" data-cy="error-box">
      <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
      <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
    </div>
    
        <div class="error-box" *ngIf="hasFailedLoadingProjectsInternalServer" data-cy="error-box">
      <p style="color: red" data-cy="internal-server-error">Fehler auf dem Server aufgetreten.</p>
      <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
    </div>
    
    <div style="text-align: center" *ngIf="hasLoadedProjects && availableProjects.length === 0 && !hasFailedLoadingProjectsNoConnection && !hasFailedLoadingProjectsInternalServer && !isLoadingProjects && !hasNoSelectedCompany" data-cy="no-projects-box">
      <h4 style="margin-bottom: 12px" >Keine Projekte</h4>
      <p style="margin-block-start: 0; margin-block-end: 0;">Beginnen Sie mit dem Hinzufügen eines neuen Projekts.</p>
    </div>
    
    <div style="text-align: center" *ngIf="hasLoadedProjects && availableProjects.length === 0 && !hasFailedLoadingProjectsNoConnection && !hasFailedLoadingProjectsInternalServer && !isLoadingProjects && hasNoSelectedCompany" data-cy="no-projects-box">
      <h4 style="margin-bottom: 12px" >Kein Unternehmen ausgewählt</h4>
      <p style="margin-block-start: 0; margin-block-end: 0;">Beginnen Sie mit dem Beitritt oder der Erstellung eines Unternehmens.</p>
    </div>
  </div>
</div>
