import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterFormInterface } from './register-form.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologForgotPasswordRoute, GeologLoginRoute, GeologRegisterRoute } from '../../../shared/routes/geolog.routes';
import { getSubscription, Match } from '@platri/elab-angular-core';
import { UserHttpService } from '../../../shared/services/user-http.service';
import { SignUpRequestDto, UserAppLangEnum } from '@platri/elab-common-auth-plug-play';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'elab-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss']
})
export class RegisterFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulRegisterEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  registerFormGroup!: FormGroup<RegisterFormInterface>;

  subscriptions: Subscription = new Subscription();

  isInitialized = false;
  isWaitingForRegisterResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;

  signInFailedWrongData = false;
  signInFailedNoConnection = false;
  signInFailedInternalServer = false;

  hidePassword = true;
  
  hasSuccessfullyRegistered = false;
  
  routerParams!: Params;
  returnUrlFromParam: string;

  constructor(
    private fb: FormBuilder,
    private userHttpService: UserHttpService,
    private router: Router,
    private matSnackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.registerFormGroup = this.createRegisterFormGroup();
  }

  createRegisterFormGroup(): FormGroup<RegisterFormInterface> {
    return this.fb.group<RegisterFormInterface>({
        firstname: this.fb.control('', [Validators.required]),
        lastname: this.fb.control('', [Validators.required]),
        email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-äöüß]+@[a-z0-9.-äöüß]+\\.[a-z]{2,4}$')]),
        password: this.fb.control('', [Validators.required]),
        confirmPassword: this.fb.control('', [Validators.required])
      },
      {
        validators: [Match('password', 'confirmPassword')]
      }
    );
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.registerFormGroup.valueChanges.subscribe(res => {
      this.signInFailedWrongData = false;
      this.signInFailedNoConnection = false;
      this.signInFailedInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.queryParams, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.returnUrlFromParam = this.routerParams['returnUrl'] ?? null;
  }

  onSubmit(): void {
    if (this.registerFormGroup.valid || (this.registerFormGroup.controls.email.hasError('password_wrong') || this.registerFormGroup.controls.password.hasError('password_wrong'))) {
      this.isWaitingForRegisterResponse = true;
      this.signInFailedWrongData = false;
      this.signInFailedNoConnection = false;
      this.signInFailedInternalServer = false;
      const {email, password, firstname, lastname} = this.registerFormGroup.getRawValue();
      const signUpDto: SignUpRequestDto = {email: email!, password: password!, firstName: firstname!, lastName: lastname!, username: '', appLang: UserAppLangEnum.DE};
      this.subscriptions.add(this.userHttpService.signUp(signUpDto).subscribe(res => {
        if (this.returnUrlFromParam) {
          this.router.navigateByUrl(this.returnUrlFromParam);
        }
        this.hasSuccessfullyRegistered = true;
        this.isWaitingForRegisterResponse = false;
        this.matSnackbar.open("Successfully registered!");
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        } else {
          this.onWrongPasswordEntered();
        }
        this.isWaitingForRegisterResponse = false;
      }));
    }
  }

  onConnectionLost(): void {
    (this.signInFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.signInFailedInternalServer = true;
  }

  onWrongPasswordEntered(): void {
    this.signInFailedWrongData = true;
    this.registerFormGroup.controls.email.setErrors({'password_wrong': true});
    this.registerFormGroup.controls.password.setErrors({'password_wrong': true});
  }
  
  routeToLogin(emitSuccessfulRegisterEmitter: boolean = false): void {
    this.router.navigate(['/' + GeologAppRoute, GeologLoginRoute], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge',});
    if (emitSuccessfulRegisterEmitter) {
      this.successfulRegisterEmitter.next(true);
    }
  }

  protected readonly GeologLoginRoute = GeologLoginRoute;
  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologForgotPasswordRoute = GeologForgotPasswordRoute;
  protected readonly GeologRegisterRoute = GeologRegisterRoute;
}
