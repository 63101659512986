import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { CompanyDto, CompanyInterface, GetProjectsByFilterDto, ProjectInterface } from '@platri/geolog-common-core';
import { ProjectHttpService } from '../../shared/services/project-http.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GeologAppRoute, GeologCreateProjectRoute, GeologProjectDetailIdRoute, GeologProjectsRoute } from '../../shared/routes/geolog.routes';
import { CompanyStateService } from '../../shared/services/company-state.service';
import { getSubscription } from '@platri/elab-angular-core';

@Component({
  selector: 'elab-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss']
})
export class ProjectsPageComponent implements OnInit, OnDestroy {
  
  searchFormControl: FormControl<string | null> = new FormControl<string>('');
  dataSource: ProjectInterface[] = [];
  availableProjects: ProjectInterface[] = [];
  
  selectedCompany: CompanyInterface;
  
  subscriptions: Subscription =  new Subscription();
  
  isLoadingProjects = true;
  hasLoadedProjects = false;
  hasFailedLoadingProjectsNoConnection = false;
  hasFailedLoadingProjectsInternalServer = false;
  
  hasNoSelectedCompany = false;
  
  searchStrings: string[] = [];
  
  constructor(
    private matDialog: MatDialog,
    private projectHttpService: ProjectHttpService,
    private router: Router,
    private companyStateService: CompanyStateService
  ) {
  }
  
  ngOnInit(): void {
    this.initializeSubscriptions();
    this.checkHasSelectedCompanyAfter5Seconds();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.companyStateService.getAsyncSelectedData(), this.onSelectedCompaniesChanges.bind(this)));
  }

  loadData(): void {
    this.isLoadingProjects = true;
    this.hasFailedLoadingProjectsNoConnection = false;
    this.hasFailedLoadingProjectsInternalServer = false;
    const getProjectsByFilterRequestDto = new GetProjectsByFilterDto();
    getProjectsByFilterRequestDto.companyIds = [this.companyStateService.getSyncSelectedData()!.id!];
    this.subscriptions.add(
      this.projectHttpService.getAllProjectsByFilter(getProjectsByFilterRequestDto).subscribe(projectDtos => {
        this.availableProjects = projectDtos.data;
        this.dataSource = structuredClone(projectDtos.data);
        this.isLoadingProjects = false;
        this.searchStrings = [];
        projectDtos.data.map((projectDto, index) => {
          let searchString = `${index};`;
          for (const projectDtoKey in projectDto) {
            // @ts-ignore
            searchString += projectDto[projectDtoKey] + ';';
          }
          this.searchStrings.push(searchString);
        });
        this.hasLoadedProjects = true;
      }, error => {
        console.log(error);
        this.isLoadingProjects = false;
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
      })
    );
  }

  handleRowClick(row: ProjectInterface): void {
    this.router.navigate([GeologProjectDetailIdRoute(row.id + '')]);
  }

  onConnectionLost(): void {
    this.hasFailedLoadingProjectsNoConnection = true;
  }
  
  onInternalServerError(): void {
    this.hasFailedLoadingProjectsInternalServer = true;
  }
  
  search(): void {
    const userSearchString = this.searchFormControl.getRawValue();
    const filteredSearchStrings = this.searchStrings.filter(searchString => searchString.includes(userSearchString!));
    
    this.dataSource = filteredSearchStrings.map(filteredSearchString => {
      const index = filteredSearchString.split(';', 1)[0];
      return structuredClone(this.availableProjects[+index]);
    });
  }


  onSelectedCompaniesChanges(selectedCompany: CompanyDto): void {
    if(selectedCompany) {
      this.selectedCompany = selectedCompany;
      this.loadData();
    }
  }

  checkHasSelectedCompanyAfter5Seconds(): void {
    setTimeout(() => {
      if (!this.selectedCompany) {
        this.hasLoadedProjects = true;
        this.isLoadingProjects = false;
        this.hasNoSelectedCompany = true;
      } else {
        this.hasNoSelectedCompany = false;
      }
    }, 5000);
  }
  
  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologCreateProjectRoute = GeologCreateProjectRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
}
