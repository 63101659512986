import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoreholeDetailPageComponent } from './borehole-detail-page.component';
import { ResetPasswordFormModule } from '../../components/reset-password-page/reset-password-form/reset-password-form.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BoreholeListModule } from '../../components/area-detail-page/borehole-list/borehole-list.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { BoreholeLayerListModule } from '../../components/borehole-detail-page/borehole-layer-list/borehole-layer-list.module';

@NgModule({
  declarations: [BoreholeDetailPageComponent],
  imports: [
    CommonModule,
    ResetPasswordFormModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CdkTableModule,
    MatIconModule,
    MatTabsModule,
    RouterLink,
    MatProgressSpinnerModule,
    BoreholeListModule,
    BoreholeLayerListModule,
    MatDividerModule,
    MatChipsModule
  ],
  exports: [BoreholeDetailPageComponent]
})
export class BoreholeDetailPageModule { }
