import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { AreaInterface } from '@platri/geolog-common-core';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAreaIdRouterParam, GeologProjectIdRouterParam } from '../../shared/router-params';
import { GeologAppRoute, GeologAreasRoute, GeologBoreholesRoute, GeologCreateBoreholeRoute, GeologEditAreaRoute, GeologEditProjectRoute, GeologLoginRoute, GeologProjectsRoute, GeologUploadBoreholeRoute } from '../../shared/routes/geolog.routes';
import { AreaHttpService } from '../../shared/services/area-http.service';
import { BoreholeHttpService } from '../../shared/services/borehole-http.service';

enum AreaDetailPageUrlFragmentEnum {
  OVERVIEW = 'Übersicht',
  BOREHOLES = 'Bohrlöcher'
}

@Component({
  selector: 'elab-area-detail-page',
  templateUrl: './area-detail-page.component.html',
  styleUrls: ['./area-detail-page.component.scss']
})
export class AreaDetailPageComponent implements OnInit, OnDestroy {
  
  searchFormControl: FormControl<string | null> = new FormControl<string>('');
  dataSource : AreaInterface[] = [];
  
  subscriptions: Subscription =  new Subscription();
  
  isInitialized = false;
  isWaitingForAreaResponse = false;
  hasErrorLoadingAreaDetails = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;

  routerParams!: Params;
  projectIdFromParam: number;
  areaIdFromParam: number;
  
  area: AreaInterface;

  selectedIndex = 0;
  
  constructor(
    private matDialog: MatDialog,
    private areaHttpService: AreaHttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boreholeHttpService: BoreholeHttpService
  ) {
  }
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.activatedRoute.fragment, this.onUrlFragmentChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.projectIdFromParam = this.routerParams[GeologProjectIdRouterParam];
    this.areaIdFromParam = this.routerParams[GeologAreaIdRouterParam];
    if (!this.projectIdFromParam || !this.areaIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    if (this.activatedRoute.snapshot.url[4] && this.activatedRoute.snapshot.url[4].path === "boreholes") {
      this.selectedIndex = 1;
    }
    this.loadData();
  }

  onUrlFragmentChanges(fragment: string): void {
    if (fragment !== null) {
      switch (fragment.toLowerCase()) {
        default:
        case AreaDetailPageUrlFragmentEnum.OVERVIEW: {
          this.selectedIndex = 0;
          break;
        }
        case AreaDetailPageUrlFragmentEnum.BOREHOLES: {
          this.selectedIndex = 1;
          break;
        }
      }
    }
  }

  changeUrlFragment(index: number): void {
    switch (index) {
      case 0: {
        window.location.hash = '#' + AreaDetailPageUrlFragmentEnum.OVERVIEW;
        break;
      }
      case 1: {
        window.location.hash = '#' + AreaDetailPageUrlFragmentEnum.BOREHOLES;
        break;
      }
    }
  }

  loadData(): void {
    this.hasErrorLoadingAreaDetails = false;
    this.isWaitingForAreaResponse = true;
    this.subscriptions.add(
      this.areaHttpService.getAreaById(this.routerParams[GeologAreaIdRouterParam]).subscribe(areaDto => {
        this.area = areaDto;
        this.isWaitingForAreaResponse = false;
        this.isInitialized = true;
      }, error => {
        this.hasErrorLoadingAreaDetails = true;
        this.isWaitingForAreaResponse = false;
      })
    );
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
  protected readonly GeologProjectIdRouterParam = GeologProjectIdRouterParam;
  protected readonly GeologEditProjectRoute = GeologEditProjectRoute;
  protected readonly GeologEditAreaRoute = GeologEditAreaRoute;
  protected readonly GeologAreasRoute = GeologAreasRoute;
  protected readonly GeologBoreholesRoute = GeologBoreholesRoute;
  protected readonly GeologCreateBoreholeRoute = GeologCreateBoreholeRoute;
  protected readonly GeologUploadBoreholeRoute = GeologUploadBoreholeRoute;
}
