import { GeologResetPasswordTokenRouterParam } from '../router-params';

export const GeologAppRoute = 'geolog-app';
export const GeologLoginRoute = 'login';
export const GeologRegisterRoute = 'register';
export const GeologForgotPasswordRoute = 'forgot-password';
export const GeologConfirmEmailRoute = 'confirm-email';
export const GeologResetPasswordRoute = 'reset-password';
export const GeologDeactivateAccountRoute = 'deactivate-account';
export const GeologDeleteAccountRoute = 'delete-account';
export const GeologMagicLinkLoginRoute = 'magic-link-login';
export const GeologJoinCompanyRoute = 'join-company';
export const GeologResetPasswordTokenRoute = ((token: string): string => GeologResetPasswordTokenRouteHelper(token));
export const GeologProjectsRoute = 'projects';
export const GeologProjectDetailIdRoute = ((id: string): string => GeologProjectDetailIdRouteHelper(id));
export const GeologCreateProjectRoute = 'create-project';
export const GeologEditProjectRoute = 'edit';
export const GeologCompanyRoute = 'company';
export const GeologEditCompanyRoute = 'edit';
export const GeologCompanyLandingRoute = 'company-landing';
export const GeologAreasRoute = 'areas';
export const GeologCreateAreaRoute = 'create-area';
export const GeologAreaDetailIdRoute = ((projectId: string, areaId: string): string => GeologAreaDetailIdRouteHelper(projectId, areaId));
export const GeologEditAreaRoute = 'edit';
export const GeologBoreholesRoute = 'boreholes';
export const GeologUploadBoreholeRoute = 'upload-borehole';
export const GeologCreateBoreholeRoute = 'create-borehole';
export const GeologBoreholeDetailIdRoute = ((projectId: string, areaId: string, boreholeId: string): string => GeologBoreholeDetailIdRouteHelper(projectId, areaId, boreholeId));
export const GeologLayersRoute = 'layers';

function GeologResetPasswordTokenRouteHelper(token: string = ':' + GeologResetPasswordTokenRouterParam): string {
  return `/${GeologAppRoute}/${GeologResetPasswordRoute}/${token}`;
}

export function GeologProjectDetailIdRouteHelper(id: string = ':' + GeologProjectDetailIdRoute): string {
  return `/${GeologAppRoute}/${GeologProjectsRoute}/${id}`;
}

function GeologAreaDetailIdRouteHelper(projectId: string = ':' + GeologProjectDetailIdRoute, areaId: string = ':' + GeologAreaDetailIdRoute): string {
  return `/${GeologAppRoute}/${GeologProjectsRoute}/${projectId}/${GeologAreasRoute}/${areaId}`;
}

function GeologBoreholeDetailIdRouteHelper(projectId: string = ':' + GeologProjectDetailIdRoute, areaId: string = ':' + GeologAreaDetailIdRoute, boreholeId: string = ':' + GeologBoreholeDetailIdRoute): string {
  return `/${GeologAppRoute}/${GeologProjectsRoute}/${projectId}/${GeologAreasRoute}/${areaId}/${GeologBoreholesRoute}/${boreholeId}`;
}
