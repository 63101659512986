<div class="flex-container">
  <form class="form flex-container-centered-content" [formGroup]="registerFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize && !hasSuccessfullyRegistered">
    
    <ng-container *ngIf="step === 0">
      <h4 style="align-self: flex-start;">Unternehmen registrieren</h4>
      <p >Geben Sie einen Lizenschlüssel ein, um Ihre Firma zu registrieren.</p>
    </ng-container>
    
    <div *ngIf="step === 1" style="display: flex; flex-direction: row; width: 100%;  margin-block-end: 1.33em;">
          <button (click)="changeStep(0)" style="min-width:0;" mat-button color="primary" type="button" data-cy="change-step-button"><mat-icon style="font-size: 16px; height: 16px; width: 16px; margin-right: 0; padding: 0; color: black;">arrow_back_ios</mat-icon></button>
          <h4 style="align-self: flex-start; margin: auto 0;">Unternehmen registrieren</h4>
    </div>
    
    <div class="flex-container-row">
      <div class="form-step-container flex-container-column">
        <mat-form-field appearance="outline">
          <mat-label>Unternehmensname</mat-label>
          <input matInput id="company-name-input" type="text" [formControl]="registerFormGroup.controls.name" placeholder="Baufirma Klaus" data-cy="company-name-input">
        </mat-form-field>
        
        <ng-container *ngIf="step === 0">
          <mat-form-field appearance="outline">
            <mat-label>Lizenzschlüssel</mat-label>
            <input matInput id="license-key-input" type="text" [formControl]="registerFormGroup.controls.licenseKey" placeholder="A1B2C3D4" data-cy="license-key-input">
          </mat-form-field>
        </ng-container>
        
        <ng-container *ngIf="step === 1">
           <elab-google-address-search-autocomplete
             [existingAddress]="registerFormGroup.controls.billingAddress.value!"
             (addressSelected)="onAddressSelected($event)"
             data-cy="google-address-input"
           ></elab-google-address-search-autocomplete>
          
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="text" id="email-input" [formControl]="registerFormGroup.controls.email" placeholder="tiefbau-müller@hotmail.com" data-cy="email-input">
            <mat-error *ngIf="registerFormGroup.controls.email.hasError('pattern')">
             Bitte geben Sie eine korrekte Email-Adresse ein!
            </mat-error>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Telefon</mat-label>
            <input matInput type="text" id="phone-input" [formControl]="registerFormGroup.controls.phoneNumber" placeholder="06461 81 56 96" data-cy="phone-input">
            <mat-error *ngIf="registerFormGroup.controls.phoneNumber.hasError('invalidPhone')">
              Bitte geben Sie eine korrekte Telefonnummer ein!
            </mat-error>
          </mat-form-field>
        </ng-container>
        
      </div>
    </div>
    
    <ng-container *ngIf="step === 0">
      <div style="width: 100%">
        <button id="continue-button" (click)="changeStep(1)" style="width: 100%" mat-raised-button color="primary" type="button" data-cy="continue-button">Weiter</button>
      </div>
    </ng-container>
    
    <ng-container *ngIf="step === 1">
      <div style="width: 100%">
        <button id="registration-button" [disabled]="isWaitingForRegisterResponse" [class.spinner]="isWaitingForRegisterResponse" style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="register-button">Registrieren</button>
      </div>
    </ng-container>

    <div *ngIf="signInFailedWrongData || signInFailedNoConnection" style="height: 60px">
      <p class="register-error" *ngIf="signInFailedWrongData" data-cy="wrong-data-error">Die angegebenen Daten sind falsch</p>
      <p class="register-error" *ngIf="signInFailedNoConnection" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
    </div>
    
    
  </form>
  
</div>

<p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">Fataler Fehler beim Laden der benötigten Daten!</p>
