import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologAreasRoute, GeologForgotPasswordRoute, GeologLoginRoute, GeologProjectsRoute } from '../../../shared';
import { AddressInterface, BoreholeInterface, CreateAreaRequestDto, CreateBoreholeBulkRequestDto, ProjectDetailPageUrlFragmentEnum } from '@platri/geolog-common-core';
import { GenericCreateFormComponent } from '@platri/elab-angular-core';
import { GeologProjectIdRouterParam } from '../../../shared';
import { AreaHttpService } from '../../../shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateAreaFormInterface } from '../create-area-form';

@Component({
  selector: 'elab-create-area-form-v2',
  templateUrl: './create-area-form-v2.component.html',
  styleUrls: ['./create-area-form-v2.component.scss']
})
export class CreateAreaFormV2Component extends GenericCreateFormComponent<CreateAreaFormInterface> {
  
  boreholesToUpload: BoreholeInterface[];
  
  projectIdFromParam: number;
  
  constructor(
    private areaHttpService: AreaHttpService,
    fb: FormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    matSnackbar: MatSnackBar
  ) {
    super(fb, router, activatedRoute, matSnackbar);
  }
  
  override createCreateFormGroup(): FormGroup<CreateAreaFormInterface> {
    return this.fb.group<CreateAreaFormInterface>({
      address: this.fb.control(null, [Validators.required]),
      latitude: this.fb.control(null),
      longitude: this.fb.control(null),
      cadastralDistrict: this.fb.control(''),
      floor: this.fb.control(''),
      parcelNumber: this.fb.control(''),
      description: this.fb.control(''),
      soil: this.fb.control('')
    });
  }

  override onActivatedRouteChanges(params: Params): void {
    super.onActivatedRouteChanges(params);
    this.projectIdFromParam = this.routerParams[GeologProjectIdRouterParam];
    if (!this.projectIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
  }
  

  onSubmit(): void {
    if (this.createFormGroup.valid) {
      super.onFormSubmitStart();
      const {address, latitude, longitude, cadastralDistrict, floor, parcelNumber, description, soil} = this.createFormGroup.getRawValue();
      const createBoreholeBulkRequestDtos: CreateBoreholeBulkRequestDto[] = this.boreholesToUpload ? this.boreholesToUpload.map(borehole =>
        new CreateBoreholeBulkRequestDto({
          physicalBoreholeId: borehole.physicalBoreholeId!,
          latitude: borehole.latitude!,
          longitude: borehole.longitude!,
          altitude: borehole.altitude!
        })) : [];
      // @ts-ignore
      const createAreaRequestDto: CreateAreaRequestDto = {address: address!, boreholes: createBoreholeBulkRequestDtos, longitude: longitude!, latitude: latitude!, floor: floor!, cadastralDistrict: cadastralDistrict!, parcelNumber: parcelNumber!, description: description!, soil: soil!, projectId: +this.projectIdFromParam};
      this.subscriptions.add(this.areaHttpService.createArea(createAreaRequestDto).subscribe(createdId => {
        super.onFormSubmitSuccessful();
        this.matSnackbar.open('Successfully created area!');
        this.router.navigate(['/', GeologAppRoute, GeologProjectsRoute, this.projectIdFromParam], {fragment: ProjectDetailPageUrlFragmentEnum.AREAS});
      }, error => super.onFormSubmitError(error)));
    }
  }

  onAddressSelected(address: AddressInterface): void {
   this.createFormGroup.patchValue({address: address, latitude: address.longitude, longitude: address.latitude});
  }
  
  onBoreholeUploadChange(boreholes: BoreholeInterface[]): void {
    this.boreholesToUpload = boreholes;
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologForgotPasswordRoute = GeologForgotPasswordRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
  protected readonly GeologAreasRoute = GeologAreasRoute;
}
