export enum BoreholeLayerFillingMaterialEnum {
  EXCAVATED_SOIL = 'EXCAVATED_SOIL',
  SWELLING_CLAY_PELLETS = 'SWELLING_CLAY_PELLETS',
  SWELLING_CLAY_GRANULES = 'SWELLING_CLAY_GRANULES',
  GRAVEL = 'GRAVEL',
  CRUSHED_STONE = 'CRUSHED_STONE',
  CEMENT_SUSPENSION = 'CEMENT_SUSPENSION',
  BOROPLAST = 'BOROPLAST',
  GRIT = 'GRIT',
  DRILLED_MATERIAL = 'DRILLED_MATERIAL',
  FILLING_SAND = 'FILLING_SAND'
}
