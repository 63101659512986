import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { AreaInterface, GetAreasByFilterRequestDto, ProjectInterface } from '@platri/geolog-common-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologAreaDetailIdRoute, GeologCreateProjectRoute, GeologLoginRoute } from '../../../shared/routes/geolog.routes';
import { AreaHttpService } from '../../../shared/services/area-http.service';
import { getSubscription } from '@platri/elab-angular-core';
import { GeologProjectIdRouterParam } from '../../../shared/router-params';

@Component({
  selector: 'elab-area-list',
  templateUrl: './area-list.component.html',
  styleUrls: ['./area-list.component.scss']
})
export class AreaListComponent implements OnInit, OnDestroy {
  
  searchFormControl: FormControl<string | null> = new FormControl<string>('');
  dataSource : AreaInterface[] = [];
  availableAreas: AreaInterface[] = [];
  
  subscriptions: Subscription =  new Subscription();
  
  isLoadingAreas = true;
  hasLoadedAreas = false;
  hasFailedLoadingAreasNoConnection = false;
  hasFailedLoadingAreasInternalServer = false;

  routerParams!: Params;
  
  constructor(
    private matDialog: MatDialog,
    private areaHttpService: AreaHttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    if (!this.routerParams[GeologProjectIdRouterParam]) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    this.loadData();
  }


  loadData(): void {
    this.hasLoadedAreas = false;
    this.isLoadingAreas = true;
    this.hasFailedLoadingAreasNoConnection = false;
    this.hasFailedLoadingAreasInternalServer = false;
    const getAreasByFilterRequestDto = new GetAreasByFilterRequestDto();
    getAreasByFilterRequestDto.projectId = this.routerParams[GeologProjectIdRouterParam];
    this.subscriptions.add(
      this.areaHttpService.getAllAreasByFilter(getAreasByFilterRequestDto).subscribe(areaPageDto => {
        this.availableAreas = areaPageDto.data;
        this.dataSource = structuredClone(areaPageDto.data);
        this.isLoadingAreas = false;
        this.hasLoadedAreas = true;
      }, error => {
        console.log(error);
        this.isLoadingAreas = false;
        this.hasLoadedAreas = true;
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
      })
    );
  }

  handleRowClick(row: ProjectInterface): void {
    this.router.navigate([GeologAreaDetailIdRoute(this.routerParams[GeologProjectIdRouterParam], row.id + '')]);
    //this.router.navigate([GeologProjectDetailIdRoute(row.id + '')]);
  }

  onConnectionLost(): void {
    (this.hasFailedLoadingAreasNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.hasFailedLoadingAreasInternalServer = true;
  }
  
  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologCreateProjectRoute = GeologCreateProjectRoute;
}
