import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeologAppRoute, GeologCompanyRoute } from '../../shared/routes/geolog.routes';

@Component({
  selector: 'elab-companies-page',
  templateUrl: './companies-page.component.html',
  styleUrls: ['./companies-page.component.scss']
})
export class CompaniesPageComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(({ companies }) => {
      this.router.navigate([GeologAppRoute, GeologCompanyRoute, companies[0].id]);
      // do something with your resolved data ...
    });
  }
}
