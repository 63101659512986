import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BoreholeInterface, BoreholeStatusEnum, GetBoreholesByFilterDto, ProjectInterface } from '@platri/geolog-common-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologBoreholeDetailIdRoute, GeologLoginRoute } from '../../../shared/routes/geolog.routes';
import { getSubscription } from '@platri/elab-angular-core';
import { GeologAreaIdRouterParam, GeologProjectIdRouterParam } from '../../../shared/router-params';
import { BoreholeHttpService } from '../../../shared/services/borehole-http.service';

@Component({
  selector: 'elab-borehole-list',
  templateUrl: './borehole-list.component.html',
  styleUrls: ['./borehole-list.component.scss']
})
export class BoreholeListComponent implements OnInit, OnDestroy {
  
  searchFormControl: FormControl<string | null> = new FormControl<string>('');
  dataSource: BoreholeInterface[] = [];
  availableBoreholes: BoreholeInterface[] = [];
  
  subscriptions: Subscription =  new Subscription();
  
  isLoadingBoreholes = true;
  hasLoadedBoreholes = false;
  hasFailedLoadingBoreholesNoConnection = false;
  hasFailedLoadingBoreholesInternalServerError = false;
  
  searchStrings: string[] = [];

  routerParams!: Params;

  BoreholeStatusEnum = BoreholeStatusEnum;
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private boreholeHttpService: BoreholeHttpService
  ) {
  }
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    if (!this.routerParams[GeologProjectIdRouterParam] || !this.routerParams[GeologAreaIdRouterParam]) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    this.loadData();
  }


  loadData(): void {
    this.isLoadingBoreholes = true;
    this.hasFailedLoadingBoreholesNoConnection = false;
    this.hasFailedLoadingBoreholesInternalServerError = false;
    const getBoreholesByFilterRequestDto = new GetBoreholesByFilterDto();
    getBoreholesByFilterRequestDto.areaId = this.routerParams[GeologAreaIdRouterParam];
    getBoreholesByFilterRequestDto.take = 1000;
    this.subscriptions.add(
      this.boreholeHttpService.getBoreholesByFilter(getBoreholesByFilterRequestDto).subscribe(boreholePageDto => {
        this.availableBoreholes = boreholePageDto.data;
        this.dataSource = structuredClone(boreholePageDto.data);
        this.isLoadingBoreholes = false;
        this.hasLoadedBoreholes = true;
        this.searchStrings = [];
        boreholePageDto.data.map((boreholeDto, index) => {
          let searchString = `${index};`;
          for (const boreholeDtoKey in boreholeDto) {
            if (boreholeDtoKey !== "boreholeLayers") {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              searchString += boreholeDto[boreholeDtoKey] + ';';
            }
          }
          this.searchStrings.push(searchString);
        });
      }, error => {
        console.log(error);
        this.isLoadingBoreholes = false;
        this.hasLoadedBoreholes = true;
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
      })
    );
  }

  handleRowClick(row: ProjectInterface): void {
    this.router.navigate([GeologBoreholeDetailIdRoute(this.routerParams[GeologProjectIdRouterParam], this.routerParams[GeologAreaIdRouterParam], row.id + '')]);
    //this.router.navigate([GeologProjectDetailIdRoute(row.id + '')]);
  }

  onConnectionLost(): void {
    this.hasFailedLoadingBoreholesNoConnection = true;
  }
  
  onInternalServerError(): void {
    this.hasFailedLoadingBoreholesInternalServerError = true;
  }
  
  search(): void {
    const userSearchString = this.searchFormControl.getRawValue();
    const filteredSearchStrings = this.searchStrings.filter(searchString => searchString.includes(userSearchString!));

    this.dataSource = filteredSearchStrings.map(filteredSearchString => {
      const index = filteredSearchString.split(';', 1)[0];
      return structuredClone(this.availableBoreholes[+index]);
    });
  }

  protected readonly GeologAppRoute = GeologAppRoute;
}
