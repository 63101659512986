<div class="full-view">
  <div class="header-bar">
    <button [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, projectIdFromParam]" mat-button color="primary" type="button"><mat-icon style="font-size: 12px; height: 12px; width: 12px; margin-right: 0">arrow_back_ios</mat-icon> Zurück zu den Bereichen</button>
    <div>
      <button [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, projectIdFromParam, GeologAreasRoute, areaIdFromParam, GeologCreateBoreholeRoute]" style="margin-right: 12px" mat-raised-button color="primary" type="button">Bohrlöcher hinzufügen</button>
      <button style="color: black" mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, projectIdFromParam, GeologAreasRoute, areaIdFromParam, GeologUploadBoreholeRoute]" style="margin-right: 12px" mat-menu-item>Bohrlöcher importieren</button>
          <button [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, projectIdFromParam, GeologAreasRoute, areaIdFromParam, GeologEditAreaRoute]" style="margin-right: 12px" mat-menu-item>Bearbeiten</button>
        </mat-menu>
     <!-- <button  style="margin-right: 12px" mat-raised-button type="button"><mat-icon>vertical_align_bottom</mat-icon>Import data</button> -->
    </div>
  </div>
  
  <div class="details">
    <h3>{{area ? area.address!.formattedAddress! : '...'}}</h3>
    <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="changeUrlFragment($event)" animationDuration="0ms" mat-align-tabs="center">
      <mat-tab label="Übersicht"> 
        <table *ngIf="isInitialized" style="width:100%">
          <tr>
            <th>Adresse</th>
            <td>{{isInitialized ? area.address!.formattedAddress! : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Referenzpunkt für die Georeferenzierung</th>
            <td>{{isInitialized ? 'E ' + area.longitude : "Loading..."}}<br>{{isInitialized ? 'N ' + area.latitude : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Gemarkung</th>
            <td>{{isInitialized ? area.cadastralDistrict && area.cadastralDistrict !== '' ? area.cadastralDistrict : '-' : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Flur</th>
            <td>{{isInitialized ? area.floor && area.floor !== '' ? area.floor : '-' : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Flurstücknummer</th>
            <td>{{isInitialized ? area.parcelNumber && area.parcelNumber !== '' ? area.parcelNumber : '-' : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Beschreibung</th>
            <td>{{isInitialized ? area.description && area.description !== '' ? area.description : '-' : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Soil</th>
            <td>{{isInitialized ? area.soil && area.soil !== '' ? area.soil : '-' : "Loading..."}}</td>
          </tr>
        </table>
        <div class="loading-box" *ngIf="!isInitialized && !hasErrorLoadingAreaDetails" data-cy="loading-box">
          <mat-spinner></mat-spinner>
        </div>
        
        <div *ngIf="!isInitialized && hasErrorLoadingAreaDetails" class="error-box">
          <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
          <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
        </div>
        <div style="position: fixed; width: 0; height: 0; display: flex" *ngIf="isWaitingForAreaResponse" data-cy="loading-box"></div>
      </mat-tab>
      <mat-tab style="max-width: 110px" label="Bohrlöcher"> 
          <elab-borehole-list></elab-borehole-list>
        </mat-tab>
      </mat-tab-group>
  </div>
</div>
