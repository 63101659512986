import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { CreateBoreholeFormComponent } from './create-borehole-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { GoogleAddressSearchAutocompleteModule } from '../../google-address-search-autocomplete';

@NgModule({
  declarations: [CreateBoreholeFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    GoogleAddressSearchAutocompleteModule
  ],
  exports: [CreateBoreholeFormComponent]
})
export class CreateBoreholeFormModule { }
