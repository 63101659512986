export interface AddressInterface {
  addressLine1?: string;
  city?: string;
  country?: string;
  state?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  name?: string;
  formattedAddress?: string;
  latitude?: number;
  longitude?: number;
  googleMapsUrl?: string;
  googleMapsPlaceId?: string;
  timezoneId?: string;
  timezoneOffsetMinutes?: number;
}
