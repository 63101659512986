import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditCompanyPageComponent } from './edit-company-page.component';
import { EditCompanyFormModule } from '../../components/edit-company-page/edit-company-form/edit-company-form.module';

@NgModule({
  declarations: [EditCompanyPageComponent],
  imports: [
    CommonModule,
    EditCompanyFormModule
  ],
  exports: [EditCompanyPageComponent]
})
export class EditCompanyPageModule { }
