import { Component } from '@angular/core';

@Component({
  selector: 'elab-edit-area-page',
  templateUrl: './edit-area-page.component.html',
  styleUrls: ['./edit-area-page.component.scss']
})
export class EditAreaPageComponent {
  
  
}
