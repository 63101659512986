import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditAreaPageComponent } from './edit-area-page.component';
import { EditAreaFormModule } from '../../components/edit-area-page/edit-area-form/edit-area-form.module';

@NgModule({
  declarations: [EditAreaPageComponent],
  imports: [
    CommonModule,
    EditAreaFormModule
  ],
  exports: [EditAreaPageComponent]
})
export class EditAreaPageModule { }
