import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StandaloneAppComponent } from './standalone-app.component';
import { GeologAppRoute } from '../lib/shared/routes/geolog.routes';

const routes: Routes = [
  {
    path: '',
    redirectTo: GeologAppRoute,
    pathMatch: 'full'
  },
  {
    path: GeologAppRoute,
    loadChildren: (): any => import('../lib/frontend-geolog-app.module').then((m) => m.FrontendGeologAppModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class StandaloneRoutingModule {
  constructor() {
  }
}
