import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeologAppComponent } from './pages';
import { GeologAppRoute, GeologAreasRoute, GeologBoreholesRoute, GeologCompanyLandingRoute, GeologCompanyRoute, GeologConfirmEmailRoute, GeologCreateAreaRoute, GeologCreateBoreholeRoute, GeologCreateProjectRoute, GeologDeactivateAccountRoute, GeologDeleteAccountRoute, GeologEditAreaRoute, GeologEditCompanyRoute, GeologEditProjectRoute, GeologForgotPasswordRoute, GeologJoinCompanyRoute, GeologLayersRoute, GeologLoginRoute, GeologMagicLinkLoginRoute, GeologProjectsRoute, GeologRegisterRoute, GeologResetPasswordRoute, GeologUploadBoreholeRoute } from './shared/routes/geolog.routes';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { GeologAreaIdRouterParam, GeologBoreholeIdRouterParam, GeologCompanyIdRouterParam, GeologConfirmDeactivateAccountTokenRouterParam, GeologConfirmDeleteAccountTokenRouterParam, GeologConfirmEmailTokenRouterParam, GeologJoinCompanyCodeTokenRouterParam, GeologMagicLinkLoginTokenRouterParam, GeologProjectIdRouterParam, GeologResetPasswordTokenRouterParam } from './shared/router-params';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { ProjectsPageComponent } from './pages/projects-page/projects-page.component';
import { ProjectDetailPageComponent } from './pages/project-detail-page/project-detail-page.component';
import { CreateProjectPageComponent } from './pages/create-project-page/create-project-page.component';
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { EditProjectPageComponent } from './pages/edit-project-page/edit-project-page.component';
import { AreaDetailPageComponent } from './pages/area-detail-page/area-detail-page.component';
import { BoreholeDetailPageComponent } from './pages/borehole-detail-page/borehole-detail-page.component';
import { CreateAreaPageComponent } from './pages/create-area-page/create-area-page.component';
import { EditAreaPageComponent } from './pages/edit-area-page/edit-area-page.component';
import { CompanyDetailPageComponent } from './pages/company-detail-page/company-detail-page.component';
import { ConfirmEmailPageComponent } from './pages/confirm-email-page/confirm-email-page.component';
import { DeactivateAccountPageComponent } from './pages/deactivate-account-page/deactivate-account-page.component';
import { EditCompanyPageComponent } from './pages/edit-company-page/edit-company-page.component';
import { DeleteAccountPageComponent } from './pages/delete-account-page/delete-account-page.component';
import { MagicLinkLoginPageComponent } from './pages/magic-link-login-page/magic-link-login-page.component';
import { CreateBoreholePageComponent } from './pages/create-borehole-page/create-borehole-page.component';
import { JoinCompanyPageComponent } from './pages/join-company-page/join-company-page.component';
import { UploadBoreholesPageComponent } from './pages/upload-boreholes-page/upload-boreholes-page.component';
import { AuthGuard } from './helpers/auth.guard';
import { CompaniesByCurrentUserResolver } from './shared/resolvers/companies-by-current-user-resolver.service';
import { CompaniesPageComponent } from './pages/companies-page/companies-page.component';

const routes: Routes = [
  { path: '', redirectTo: GeologProjectsRoute, pathMatch: 'full' },
  { path: '', component: GeologAppComponent, children: [
      { path: GeologLoginRoute, component: LoginPageComponent },
      { path: GeologRegisterRoute, component: RegisterPageComponent },
      { path: GeologForgotPasswordRoute, component: ForgotPasswordPageComponent },
      { path: GeologConfirmEmailRoute + '/:' + GeologConfirmEmailTokenRouterParam, component: ConfirmEmailPageComponent },
      { path: GeologResetPasswordRoute + '/:' + GeologResetPasswordTokenRouterParam, component: ResetPasswordPageComponent },
      { path: GeologDeactivateAccountRoute + '/:' + GeologConfirmDeactivateAccountTokenRouterParam, component: DeactivateAccountPageComponent },
      { path: GeologDeleteAccountRoute + '/:' + GeologConfirmDeleteAccountTokenRouterParam, component: DeleteAccountPageComponent },
      { path: GeologMagicLinkLoginRoute + '/:' + GeologMagicLinkLoginTokenRouterParam, component: MagicLinkLoginPageComponent },
      { path: GeologJoinCompanyRoute + '/:' + GeologJoinCompanyCodeTokenRouterParam, component: JoinCompanyPageComponent },
      { path: GeologProjectsRoute, canActivate: [AuthGuard], children: [
          { path: '', component: ProjectsPageComponent},
          { path: GeologCreateProjectRoute, component: CreateProjectPageComponent },
          { path: ':' + GeologProjectIdRouterParam, children: [
              { path: '', component: ProjectDetailPageComponent },
              { path: GeologCreateAreaRoute, component: CreateAreaPageComponent },
              { path: GeologEditProjectRoute, component: EditProjectPageComponent },
              { path: GeologAreasRoute + '/:' + GeologAreaIdRouterParam, children: [
                  { path: '', component: AreaDetailPageComponent },
                  { path: GeologEditAreaRoute, component: EditAreaPageComponent },
                  { path: GeologUploadBoreholeRoute, component: UploadBoreholesPageComponent },
                  { path: GeologCreateBoreholeRoute , component: CreateBoreholePageComponent },
                  { path: GeologBoreholesRoute + '/:' + GeologBoreholeIdRouterParam, children: [
                      { path: '', component: BoreholeDetailPageComponent },
                      { path: GeologLayersRoute, component: BoreholeDetailPageComponent },
                    ]},
                ]},
            ]},
        ]},
      { path: GeologCompanyRoute, component: CompaniesPageComponent, canActivate: [AuthGuard], resolve: {companies: CompaniesByCurrentUserResolver}},
      { path: GeologCompanyRoute + '/:' + GeologCompanyIdRouterParam, canActivate: [AuthGuard], children: [
          { path: '', component: CompanyDetailPageComponent },
          { path: GeologEditCompanyRoute, component: EditCompanyPageComponent },
        ]},
      { path: GeologCompanyLandingRoute, component: CompanyPageComponent, canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontendGeologAppRoutingModule {
  constructor() {
    console.log('FrontendGeologAppRoutingModule initialized');
  }
}
