<div class="flex-container">
  <form class="form" [formGroup]="editCompanyFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize">
    <h4 style="align-self: flex-start;">Unternehmen bearbeiten</h4>
    <div class="flex-container-row">
      <div class="form-step-container flex-container-column">
        <div class="loading-box"  [class.spinner-big]="true" *ngIf="isLoadingCompany">
        </div>
        <div *ngIf="hasFailedLoadingCompanyNoConnection" class="error-box" >
          <div style="margin-top: 45%;">
            <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
            <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
          </div>
        </div>
        <div *ngIf="hasFailedLoadingCompanyInternalServer" class="error-box" >
          <div style="margin-top: 45%;">
            <p style="color: red" data-cy="internal-server-error">Fehler auf dem Server aufgetreten.</p>
            <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
          </div>
        </div>
        
        <mat-form-field appearance="outline">
          <mat-label>Unternehmensname</mat-label>
          <input matInput type="text" id="company-name-input" [formControl]="editCompanyFormGroup.controls.name" placeholder="Tiefbau Müller">
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Lizenzschlüssel</mat-label>
          <input matInput type="text" id="license-key-input" [formControl]="editCompanyFormGroup.controls.licenseKey" placeholder="JTX-3523-4572-43">
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Geschäftsbereich</mat-label>
          <input matInput type="text" id="business-area-input" [formControl]="editCompanyFormGroup.controls.businessArea" placeholder="Ba01">
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Rechnungsadresse</mat-label>
          <input matInput type="text" id="billing-address-input" [formControl]="editCompanyFormGroup.controls.billingAddress" placeholder="Budapester Strasse 27, 44793, Bochum">
          <mat-error *ngIf="editCompanyFormGroup.controls.billingAddress.hasError('invalidAddress')">
            Bitte geben Sie eine korrekte Rechnungsadresse ein.
          </mat-error>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="text" id="email-input" [formControl]="editCompanyFormGroup.controls.email" placeholder="tiefbau-müller@hotmail.com">
          <mat-error *ngIf="editCompanyFormGroup.controls.email.hasError('pattern')">
            Bitte geben Sie eine korrekte Email-Adresse ein!
          </mat-error>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Telefon</mat-label>
          <input matInput type="text" id="phone-input" [formControl]="editCompanyFormGroup.controls.phoneNumber" placeholder="06461 81 56 96">
          <mat-error *ngIf="editCompanyFormGroup.controls.phoneNumber.hasError('invalidPhone')">
            Bitte geben Sie eine korrekte Telefonnummer ein!
          </mat-error>
        </mat-form-field>
        
        
       
      </div>
    </div>

    <hr>
    <div style="width: 100%; display: flex; flex-direction: row">
      <button id="cancel-button" [disabled]="isWaitingForEditCompanyResponse" [class.spinner]="isWaitingForEditCompanyResponse" style="width: 100%; margin-right: 16px; " mat-raised-button type="button" [routerLink]="['/', GeologAppRoute, GeologCompanyRoute, companyIdFromParam]" data-cy="cancel-button">Abbrechen</button>
      <button id="save-button" [disabled]="isWaitingForEditCompanyResponse" [class.spinner]="isWaitingForEditCompanyResponse" style="width: 100%" mat-raised-button color="primary" type="submit">Speichern</button>
    </div>

    <div style="height: 60px">
   <p class="login-error" *ngIf="editFailedNoConnection" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
   <p class="login-error" *ngIf="editFailedInternalServer" data-cy="internal-server-error">Fehler beim Speichern auf dem Server!</p> </div>
    
    
  </form>
</div>

<p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">Fataler Fehler beim Laden der benötigten Daten!</p>
