<div class="flex-container">
  <form class="form flex-container-centered-content" [formGroup]="registerFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize && !hasSuccessfullyRegistered">
    <h4 style="align-self: flex-start;">Registrieren</h4>
    <div class="flex-container-row">
      <div class="form-step-container flex-container-column">
        <mat-form-field appearance="outline">
          <mat-label>Vorname</mat-label>
          <input matInput id="firstname-input" type="text" [formControl]="registerFormGroup.controls.firstname" placeholder="Max" data-cy="firstname-input">
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Nachname</mat-label>
          <input matInput id="lastname-input" type="text" [formControl]="registerFormGroup.controls.lastname" placeholder="Mustermann" data-cy="lastname-input">
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput id="email-input" type="text" [formControl]="registerFormGroup.controls.email" placeholder="max.muster@platri.de" data-cy="email-input">
          <mat-error *ngIf="registerFormGroup.controls.email.hasError('pattern')">
           Bitte geben Sie eine korrekte Email-Adresse ein!
          </mat-error>
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Passwort</mat-label>
          <input matInput id="password-input" [formControl]="registerFormGroup.controls.password" placeholder="12345678" [type]="hidePassword ? 'password' : 'text'" data-cy="password-input">
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Passwort bestätigen</mat-label>
          <input matInput id="confirm-password-input" [formControl]="registerFormGroup.controls.confirmPassword" placeholder="12345678" [type]="hidePassword ? 'password' : 'text'" data-cy="confirm-password-input">
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error *ngIf="registerFormGroup.controls.confirmPassword.errors?.['mustMatch']">
            Passwörter stimmen nicht überein
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <div style="width: 100%">
      <button id="registration-button" [disabled]="isWaitingForRegisterResponse" [class.spinner]="isWaitingForRegisterResponse" style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="sign-up-button">Registrieren</button>
    </div>
    
    <div>
      <p style="margin-top: 5px; margin-block-start: 0; margin-block-end: 0;">Bereits einen Account? <button type="button" id="login-button" mat-button color="primary" (click)="routeToLogin()" data-cy="login-button">Einloggen</button></p>
    </div>

    <div style="height: 60px">
      <p class="register-error" *ngIf="signInFailedWrongData" data-cy="wrong-data-error">Die angegebenen Daten sind falsch</p>
      <p class="register-error" *ngIf="signInFailedNoConnection" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
      <p class="register-error" *ngIf="signInFailedInternalServer" data-cy="internal-server-error">Fehler auf dem Server aufgetreten.</p>
    </div>
    
    
  </form>
  
  <div id="successful-registration" *ngIf="hasSuccessfullyRegistered" class="flex-container-centered-content" data-cy="successful-box">
    <p >Ihre Registrierung war erfolgreich!</p>
    <button mat-raised-button color="primary" type="button" (click)="routeToLogin(true)" data-cy="back-to-login-button">Go to login</button>
  </div>
  
</div>

<p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">Fataler Fehler beim Laden der benötigten Daten!</p>
