import { BoreholeLayerInterface } from './borehole-layer.interface';
import { BoreholeFillingModeEnum, BoreholeStatusEnum } from '../enums';
import { AreaInterface } from './area.interface';
import { BoreholeCommentInterface } from './borehole-comment.interface';

export interface BoreholeInterface {
  id?: number;
  machineId?: string;
  machineSoftwareVersion?: string;
  physicalBoreholeId?: string;
  longitude?: number;
  latitude?: number;
  altitude?: number;
  pusherDiameterMM?: number;
  startTimestamp?: Date;
  endTimestamp?: Date;
  reason?: string;
  boreholeLayers?: BoreholeLayerInterface[];
  machineOperator?: string;
  status?: BoreholeStatusEnum;
  fillingMode?: BoreholeFillingModeEnum;
  totalMass?: number;
  totalVolume?: number;
  depth?: number;
  waterLevel?: number;
  area?: AreaInterface;
  areaId?: number;
  boreholeComments?: BoreholeCommentInterface[];
}
