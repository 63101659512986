<div class="flex-container">
  <form class="form flex-container-centered-content" [formGroup]="forgotPasswordFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize && !hasSuccessfullyForgotPassword">
    <h4 style="align-self: flex-start;">Passwort vergessen?</h4>
    <div class="flex-container-row">
      <div class="form-step-container flex-container-column">
        <p>Geben Sie die Email Adresse ihres Accounts ein und wir senden Ihnen einen Link zur Passwortrücksetzung per E-Mail</p>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input id="email-input" matInput type="text" [formControl]="forgotPasswordFormGroup.controls.email" placeholder="max.muster@platri.de">
          <mat-error *ngIf="forgotPasswordFormGroup.controls.email.hasError('pattern')">
           Bitte geben Sie eine korrekte Email-Adresse ein!
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <div style="width: 100%">
      <button id="reset-button" [disabled]="isWaitingForForgotResponseResponse" [class.spinner]="isWaitingForForgotResponseResponse" style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="submit-button">Neues Passwort anfordern</button>
    </div>
    
    <div>
      <p style="margin-top: 5px; margin-block-start: 0; margin-block-end: 0; width: 100%;"> <button type="button" id="back-to-login-button" mat-button color="primary" (click)="routeToLogin()" data-cy="cancel-button">Zurück zum Einloggen</button></p>
    </div>

    <div style="height: 60px">
      <p class="forgot-password-error" *ngIf="forgotPasswordFailedWithNoConnection" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
    </div>
    
    
  </form>
  
  <div id="successful-forgot-password" *ngIf="hasSuccessfullyForgotPassword" class="flex-container-centered-content">
    <h4 style="align-self: flex-start;">E-Mail gesendet an</h4>
    <p style=" margin-block-start: 0;">
      <span>
        {{forgotPasswordFormGroup.controls.email.value}}
        <mat-icon style="color: green; vertical-align: text-bottom">
        check_circle
      </mat-icon>
      </span>
      <br> <br>
      Wenn Sie keine E-Mail erhalten, überprüfen Sie bitte ihren Spam-Ordner und versuchen Sie es erneut. Wenn Sie weitere Fragen haben, bitte kontaktieren Sie unseren <a style="text-decoration: none" href="https://google.de">Kundenservice</a>.
    </p>
    <button mat-raised-button color="primary" type="button" style="width: 100%" (click)="routeToLogin(true)" data-cy="ok-button">Ok</button>
    <div style="height: 60px">
      <!-- Resend Email ? -->
      <p class="forgot-password-error" *ngIf="forgotPasswordFailedWithNoConnection && false" data-cy="forgot-password-error">Senden Sie eine weitere E-Mail.</p>
    </div>
  </div>
  
</div>

<p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">Fataler Fehler beim Laden der benötigten Daten!</p>

