<div class="flex-container">
  <form class="form" [formGroup]="createBoreholeFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize">
    <h4 style="align-self: flex-start;">Add borehole</h4>
    <div class="flex-container-row">
      <div class="form-step-container flex-container-column">
          <mat-form-field style="margin-right: 15px" appearance="outline">
            <mat-label>Physikalische Bohrloch-ID</mat-label>
            <input matInput id="borehole-id-input" [formControl]="createBoreholeFormGroup.controls.boreholeId" placeholder="ABC-123" data-cy="borehole-id-input">
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select [formControl]="createBoreholeFormGroup.controls.status" data-cy="status-select">
              <mat-option *ngFor="let boreholeStatus of (BoreholeStatusEnum | keyvalue)" [value]="boreholeStatus.value">
                {{boreholeStatus.key === BoreholeStatusEnum.CREATED ? 'Not filled' : boreholeStatus.key === BoreholeStatusEnum.IN_PROGRESS ? 'In progress' : 'Filled'}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Tiefe (mm)</mat-label>
            <input matInput type="number" id="depth-input" [formControl]="createBoreholeFormGroup.controls.depth" placeholder="74.0060152" data-cy="depth-input">
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Durchmesser (mm)</mat-label>
            <input matInput type="number" id="diameter-input" [formControl]="createBoreholeFormGroup.controls.diameter" placeholder="74.0060152" data-cy="diameter-input">
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Nord</mat-label>
            <input matInput type="number" id="longtitude-input" [formControl]="createBoreholeFormGroup.controls.longitude" placeholder="74.0060152" data-cy="north-input">
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Ost</mat-label>
            <input matInput type="number" id="latitude-input" [formControl]="createBoreholeFormGroup.controls.latitude" placeholder="74.0060152" data-cy="east-input">
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Höhe</mat-label>
            <input matInput type="number" id="altitude-input" [formControl]="createBoreholeFormGroup.controls.altitude" placeholder="74.0060152" data-cy="altitude-input">
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Grundwasserstand (mm)</mat-label>
            <input matInput type="number" id="waterLevel-input" [formControl]="createBoreholeFormGroup.controls.waterLevel" placeholder="74.0060152" data-cy="water-level-input">
          </mat-form-field>
        
      </div>
    </div>

    <hr>
    <div style="width: 100%; display: flex; flex-direction: row">
<button id="cancel-button" [disabled]="isWaitingForCreateBoreholeResponse" [class.spinner]="isWaitingForCreateBoreholeResponse" style="width: 100%; margin-right: 16px; " mat-raised-button type="button" [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, projectIdFromParam, GeologAreasRoute, areaIdFromParam]" data-cy="cancel-button" data-cy="cancel-button">Abbrechen</button>
      <button id="create-button" [disabled]="isWaitingForCreateBoreholeResponse" [class.spinner]="isWaitingForCreateBoreholeResponse" style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="submit-button">Erstellen</button>
    </div>

    <div style="height: 60px">
     <p class="login-error" *ngIf="createFailedNoConnection" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
     <p class="login-error" *ngIf="createFailedInternalServer" data-cy="internal-server-error">Fehler beim Speichern auf dem Server!</p>
    </div>
    
  </form>
</div>

<p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">Fataler Fehler beim Laden der benötigten Daten!</p>
