import { Component } from '@angular/core';

@Component({
  selector: 'elab-edit-company-page',
  templateUrl: './edit-company-page.component.html',
  styleUrls: ['./edit-company-page.component.scss']
})
export class EditCompanyPageComponent {
  
  
}
