import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateProjectPageComponent } from './create-project-page.component';
import { ResetPasswordFormModule } from '../../components/reset-password-page/reset-password-form/reset-password-form.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ForgotPasswordFormModule } from '../../components/forgot-password-page/forgot-password-form/forgot-password-form.module';
import { CreateProjectFormModule } from '../../components/create-project-page/create-project-form/create-project-form.module';

@NgModule({
  declarations: [CreateProjectPageComponent],
  imports: [
    CommonModule,
    ResetPasswordFormModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CdkTableModule,
    MatIconModule,
    MatTabsModule,
    RouterLink,
    MatProgressSpinnerModule,
    ForgotPasswordFormModule,
    CreateProjectFormModule
  ],
  exports: [CreateProjectPageComponent]
})
export class CreateProjectPageModule { }
