import { AreaInterface } from '../../..';
import { AddressDto } from './address.dto';
import { CreateBoreholeBulkRequestDto } from './create-borehole-bulk-request.dto';

export class CreateAreaRequestDto implements AreaInterface {
  address: AddressDto;
  cadastralDistrict: string;
  description: string;
  soil: string;
  longitude: number;
  latitude: number;
  floor: string;
  parcelNumber: string;
  projectId: number;
  boreholes: CreateBoreholeBulkRequestDto[];
}
