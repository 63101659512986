import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontendGeologAppRoutingModule } from './frontend-geolog-app-routing.module';
import { GeologAppModule } from './pages';
import { LoginPageModule } from './pages/login-page/login-page.module';
import { LoginDialogModule } from './dialogs/login-dialog/login-dialog.module';
import { RegisterPageModule } from './pages/register-page/register-page.module';
import { RegisterDialogModule } from './dialogs/register-dialog/register-dialog.module';
import { ForgotPasswordPageModule } from './pages/forgot-password-page/forgot-password-page.module';
import { ForgotPasswordDialogModule } from './dialogs/forgot-password-dialog/forgot-password-dialog.module';
import { ResetPasswordPageModule } from './pages/reset-password-page/reset-password-page.module';
import { ProjectsPageModule } from './pages/projects-page/projects-page.module';
import { ProjectDetailPageModule } from './pages/project-detail-page/project-detail-page.module';
import { CreateProjectPageModule } from './pages/create-project-page/create-project-page.module';
import { CompanyPageModule } from './pages/company-page/company-page.module';
import { EditProjectPageModule } from './pages/edit-project-page/edit-project-page.module';
import { AreaDetailPageModule } from './pages/area-detail-page/area-detail-page.module';
import { BoreholeDetailPageModule } from './pages/borehole-detail-page/borehole-detail-page.module';
import { CreateAreaPageModule } from './pages/create-area-page/create-area-page.module';
import { EditAreaPageModule } from './pages/edit-area-page/edit-area-page.module';
import { ConfirmEmailPageModule } from './pages/confirm-email-page/confirm-email-page.module';
import { DeactivateAccountPageModule } from './pages/deactivate-account-page/deactivate-account-page.module';
import { CompanyDetailPageModule } from './pages/company-detail-page/company-detail-page.module';
import { EditCompanyPageModule } from './pages/edit-company-page/edit-company-page.module';
import { DeleteAccountPageModule } from './pages/delete-account-page/delete-account-page.module';
import { MagicLinkLoginPageModule } from './pages/magic-link-login-page/magic-link-login-page.module';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomMatSnackbarService } from './shared/services/custom-mat-snackbar.service';
import { CreateBoreholePageModule } from './pages/create-borehole-page/create-borehole-page.module';
import { JoinCompanyPageModule } from './pages/join-company-page/join-company-page.module';
import { UploadBoreholesPageModule } from './pages/upload-boreholes-page/upload-boreholes-page.module';
import { DragAndDropDirective } from './shared/directives/drag-and-drop.directive';
import { CompaniesPageModule } from './pages/companies-page/companies-page.module';

@NgModule({
  declarations: [DragAndDropDirective],
  imports: [
    CommonModule,
    FrontendGeologAppRoutingModule,
    GeologAppModule,
    LoginPageModule,
    LoginDialogModule,
    RegisterPageModule,
    RegisterDialogModule,
    ForgotPasswordPageModule,
    ForgotPasswordDialogModule,
    ResetPasswordPageModule,
    ProjectsPageModule,
    ProjectDetailPageModule,
    CreateProjectPageModule,
    EditProjectPageModule,
    CompanyPageModule,
    AreaDetailPageModule,
    BoreholeDetailPageModule,
    CreateAreaPageModule,
    EditAreaPageModule,
    ConfirmEmailPageModule,
    DeactivateAccountPageModule,
    DeleteAccountPageModule,
    CompanyDetailPageModule,
    EditCompanyPageModule,
    MagicLinkLoginPageModule,
    MatSnackBarModule,
    CreateBoreholePageModule,
    JoinCompanyPageModule,
    UploadBoreholesPageModule,
    CompaniesPageModule
  ],
  providers: [
    CustomMatSnackbarService,
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3500}},
  ],
  exports: []
})
export class FrontendGeologAppModule {
}
