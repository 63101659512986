import { AddressInterface } from '../../..';

export class CompanyAddressDto implements AddressInterface {
  street: string;
  streetNumber: string;
  city: string;
  country: string;
  zipCode: string;
  state : string;
  addressLine1 : string;
  
  constructor(args: CompanyAddressDto) {
    if (args) { 
      this.street = args.street;
      this.streetNumber = args.streetNumber;
      this.city = args.city;
      this.country = args.country;
      this.zipCode = args.zipCode;
      this.state = args.state;
      this.addressLine1 = args.addressLine1;
    } 
  }
}
