import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BoreholeLayerDto, CompanyUserDto, CreateCompanyUserRequestDto, GetCompanyUsersByFilterRequestDto, UpdateCompanyUserRequestDto } from '@platri/geolog-common-core';
import { PageDto } from '@platri/elab-common-swagger';
import { environment } from '../../environments/default.environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyUserHttpService implements OnDestroy {
  baseUrl = environment.geologApi;

  constructor(
    private httpClient: HttpClient,
  ) {
    console.log('Initializing CompanyUserHttpService');
  }

  ngOnDestroy(): void {
    console.log('Destroying CompanyUserHttpService');
  }

  getCompanyUsersByFilter(getCompanyUsersByFilterRequestDto: GetCompanyUsersByFilterRequestDto): Observable<PageDto<CompanyUserDto>> {
    return this.httpClient.post<PageDto<BoreholeLayerDto>>(this.baseUrl + 'company-user/filter', getCompanyUsersByFilterRequestDto);
  }
  
  getCompanyUserById(companyUserId: number): Observable<CompanyUserDto> {
    return this.httpClient.get<CompanyUserDto>(this.baseUrl + 'company-user/' + companyUserId);
  }
  
  createCompanyUser(createCompanyUserRequestDto: CreateCompanyUserRequestDto): Observable<number> {
    return this.httpClient.post<number>(this.baseUrl + 'company-user', {...createCompanyUserRequestDto});
  }

  updateCompanyUserById(id: number, updateCompanyUserRequestDto: UpdateCompanyUserRequestDto): Observable<CompanyUserDto> {
    return this.httpClient.patch<CompanyUserDto>(this.baseUrl + 'company-user/' + id, updateCompanyUserRequestDto);
  }

  deleteCompanyUserById(companyUserId: number): Observable<void> {
    return this.httpClient.delete<void>(this.baseUrl + 'company-user/' + companyUserId);
  }


}
