import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectDetailPageComponent } from './project-detail-page.component';
import { ResetPasswordFormModule } from '../../components/reset-password-page/reset-password-form/reset-password-form.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AreaListModule } from '../../components/project-detail-page/area-list/area-list.module';

@NgModule({
  declarations: [ProjectDetailPageComponent],
  imports: [
    CommonModule,
    ResetPasswordFormModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CdkTableModule,
    MatIconModule,
    MatTabsModule,
    RouterLink,
    MatProgressSpinnerModule,
    AreaListModule,
    RouterLinkActive
  ],
  exports: [ProjectDetailPageComponent]
})
export class ProjectDetailPageModule { }
