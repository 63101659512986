import { CompanyUserRoleEnum, CompanyUserStatusEnum } from '../enums';

export interface CompanyUserInterface {
  id?: number;
  companyId?: number;
  userId?: string;
  role?: CompanyUserRoleEnum;
  status?: CompanyUserStatusEnum;
  invitedEmail?: string;
}
