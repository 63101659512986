import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class CustomMatSnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  openInfo(message: string): void {
    const config = new MatSnackBarConfig();
    config.panelClass = ['custom-snackbar', 'info-light'];
    config.duration = 4000;
    this.snackBar.open(message, undefined, config);
  }

  openSuccess(message: string): void {
    const config = new MatSnackBarConfig();
    config.panelClass = ['custom-snackbar', 'success-light'];
    config.duration = 4000;
    this.snackBar.open(message, undefined, config);
  }
  
  
  openError(message: string): void {
    const config = new MatSnackBarConfig();
    config.panelClass = ['custom-snackbar', 'error-light'];
    config.duration = 4000;
    this.snackBar.open(message, undefined, config);
  }
}
