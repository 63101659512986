export const GeologResetPasswordTokenRouterParam = 'reset-password-token';
export const GeologConfirmEmailTokenRouterParam = 'confirm-email-token';
export const GeologConfirmDeactivateAccountTokenRouterParam = 'deactivate-account-token';
export const GeologConfirmDeleteAccountTokenRouterParam = 'delete-account-token';
export const GeologMagicLinkLoginTokenRouterParam = 'magic-link-login-token';
export const GeologJoinCompanyCodeTokenRouterParam = 'join-company-code-token';
export const GeologProjectIdRouterParam = 'project-id';
export const GeologAreaIdRouterParam = 'area-id';
export const GeologBoreholeIdRouterParam = 'borehole-id';
export const GeologCompanyIdRouterParam = 'company-id';
