<div class="flex-container">
  <form class="form" [formGroup]="editProjectFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize">
    <h4 style="align-self: flex-start;">Projekt bearbeiten</h4>
    <div class="flex-container-row">
      <div style="position: relative" class="form-step-container flex-container-column">
        <div class="loading-box" [class.spinner-big]="true" *ngIf="isLoadingProject" data-cy="loading-box">
        </div>
        <div *ngIf="hasFailedLoadingProjectNoConnection" class="error-box">
          <div style="margin-top: 45%;">
            <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
            <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
          </div>
        </div>
        
        <mat-form-field appearance="outline">
          <mat-label>Projektname</mat-label>
          <input matInput type="text" id="project-name-input" [formControl]="editProjectFormGroup.controls.name" placeholder="Hamburg Peutestrasse 56-60 Sondierung" data-cy="project-name-input">
          <mat-error *ngIf="editProjectFormGroup.controls.name.hasError('required')">
            Bitte geben Sie einen Projektnamen ein
          </mat-error>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Beschreibung</mat-label>
            <textarea matInput
                      id="description-input"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="5"
                      cdkAutosizeMaxRows="15"
                      placeholder="Beispiel Projekt"
                      [formControl]="editProjectFormGroup.controls.description"
                      data-cy="description-input"
            ></textarea>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Kunde</mat-label>
          <input matInput type="text" id="client-input" [formControl]="editProjectFormGroup.controls.client" placeholder="Olaf Scholz" data-cy="client-input">
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Grundeigentümer</mat-label>
          <input matInput type="text" id="property-owner-input" [formControl]="editProjectFormGroup.controls.propertyOwner" placeholder="Amazon" data-cy="property-owner-input">
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Planer</mat-label>
          <input matInput type="text" id="planner-input" [formControl]="editProjectFormGroup.controls.planner" placeholder="WIWA" data-cy="planner-input">
        </mat-form-field>
        
      </div>
    </div>

    <hr>
    <div style="width: 100%; display: flex; flex-direction: row">
<button id="cancel-button" [disabled]="isWaitingForEditProjectResponse" [class.spinner]="isWaitingForEditProjectResponse" style="width: 100%; margin-right: 16px; " mat-raised-button type="button" [routerLink]="['../' ]" data-cy="cancel-button">Abbrechen</button>
      <button id="save-button" [disabled]="isWaitingForEditProjectResponse" [class.spinner]="isWaitingForEditProjectResponse" style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="submit-button">Speichern</button>
    </div>

    <div style="height: 60px">
   <p class="login-error" *ngIf="editFailedNoConnection" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
   <p class="login-error" *ngIf="editFailedInternalServer" data-cy="internal-server-error">Fehler beim Speichern auf dem Server!</p> </div>
    
    
  </form>
</div>

<p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">Fataler Fehler beim Laden der benötigten Daten!</p>
