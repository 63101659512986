import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { JoinCompanyPageComponent } from 'libs/frontend/geolog-app/src/lib/pages/join-company-page/join-company-page.component';

@NgModule({
  declarations: [JoinCompanyPageComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  exports: [JoinCompanyPageComponent]
})
export class JoinCompanyPageModule { }
