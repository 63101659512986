import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordPageComponent } from './reset-password-page.component';
import { ResetPasswordFormModule } from '../../components/reset-password-page/reset-password-form/reset-password-form.module';

@NgModule({
  declarations: [ResetPasswordPageComponent],
  imports: [
    CommonModule,
    ResetPasswordFormModule
  ],
  exports: [ResetPasswordPageComponent]
})
export class ResetPasswordPageModule { }
