import { CompanyInterface } from './company.interface';
import { AreaInterface } from './area.interface';

export interface ProjectInterface {
  id?: number;
  name?: string;
  description?: string;
  client?: string;
  propertyOwner?: string;
  planner?: string;
  company?: CompanyInterface;
  companyId?: number;
  areas?: AreaInterface[];
}
