import { Component } from '@angular/core';

@Component({
  selector: 'elab-create-area-page',
  templateUrl: './create-area-page.component.html',
  styleUrls: ['./create-area-page.component.scss']
})
export class CreateAreaPageComponent {
  
  
}
