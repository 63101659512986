import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadBoreholesPageComponent } from './upload-boreholes-page.component';
import { DragAndDropDirective } from '../../shared/directives/drag-and-drop.directive';
import { UploadBoreholesModule } from '../../components/shared/upload-boreholes/upload-boreholes.module';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [UploadBoreholesPageComponent],
  imports: [
    CommonModule,
    UploadBoreholesModule,
    MatButtonModule,
    RouterLink
  ],
  exports: [UploadBoreholesPageComponent]
})
export class UploadBoreholesPageModule { }
