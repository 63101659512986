import { CompanyInterface } from '../../..';
import { CompanyAddressDto } from './company-address.dto';

export class CompanyDto implements CompanyInterface {
  address?: CompanyAddressDto;
  businessArea?: string;
  email: string;
  id: number;
  joinCompanyCode: string;
  licenseKey: string;
  name: string;
  phoneNumber: string;
  
  constructor(args: CompanyDto) {
    if (args) { 
      this.address = args.address;
      this.businessArea = args.businessArea;
      this.email = args.email;
      this.id = args.id;
      this.joinCompanyCode = args.joinCompanyCode;
      this.licenseKey = args.licenseKey;
      this.name = args.name;
      this.phoneNumber = args.phoneNumber;
    } 
  }
}
