<div class="full-view">
  <div class="table-with-search">
    
    <table cdk-table [dataSource]="dataSource">
      <ng-container cdkColumnDef="address">
        <th style="text-align: left; color:#808080" cdk-header-cell *cdkHeaderCellDef> Adresse </th>
        <td width="50%" cdk-cell *cdkCellDef="let row"> {{row.address.formattedAddress}} </td>
      </ng-container>
      
      <ng-container cdkColumnDef="districtLandParcel">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Flur und Flurstück </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.cadastralDistrict && row.parcelNumber ? row.cadastralDistrict + ' ' + row.parcelNumber : '-'}} </td>
      </ng-container>
      
      <ng-container cdkColumnDef="geoReference">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Referenzpunkt für die Georeferenzierung </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{'E ' + row.longitude}} <br> {{'N ' + row.latitude }} </td>
      </ng-container>
      
      <tr class="tr-header" cdk-header-row *cdkHeaderRowDef="['address', 'districtLandParcel', 'geoReference']"></tr>
      <tr class="clickable-row" cdk-row *cdkRowDef="let row; columns: ['address', 'districtLandParcel', 'geoReference']" (click)="handleRowClick(row)" data-cy="element-row"></tr>
    </table>
    
    <div class="loading-box" *ngIf="isLoadingAreas" data-cy="loading-box">
      <mat-spinner></mat-spinner>
    </div>
    
    <div class="error-box" *ngIf="hasFailedLoadingAreasNoConnection" data-cy="error-box">
      <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
      <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
    </div>
    
    <div class="error-box" *ngIf="hasFailedLoadingAreasInternalServer" data-cy="error-box">
      <p style="color: red" data-cy="internal-server-error">Fehler auf dem Server aufgetreten.</p>
      <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
    </div>
    
    <div style="text-align: center" *ngIf="hasLoadedAreas && !hasFailedLoadingAreasNoConnection && !hasFailedLoadingAreasInternalServer && availableAreas.length === 0" data-cy="no-results-box">
      <h4 style="margin-bottom: 12px">Keine Bereiche</h4>
      <p style="margin-block-start: 0; margin-block-end: 0;">Starten Sie mit dem Erstellen eines neuen Bereichs.</p>
    </div>
  </div>
</div>
