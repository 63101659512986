<div class="flex-container">
  <form class="form" [formGroup]="editAreaFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize">
    <h4 style="align-self: flex-start;">Bereich bearbeiten</h4>
    <div class="flex-container-row">
      <div class="form-step-container flex-container-column">
        <div class="loading-box"  [class.spinner-big]="true" *ngIf="isLoadingArea">
        </div>
        <div *ngIf="hasFailedLoadingAreaNoConnection" class="error-box" >
          <div style="margin-top: 45%;">
            <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
            <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
          </div>
        </div>
        
        <elab-google-address-search-autocomplete
          [existingAddress]="editAreaFormGroup.controls.address.value!"
          (addressSelected)="onAddressSelected($event)"
          data-cy="google-address-input"
        ></elab-google-address-search-autocomplete>
        
        <div style="display: flex; flex-direction: row">
          <mat-form-field style="margin-right: 15px" appearance="outline">
            <mat-label>Ost</mat-label>
            <input matInput type="number" id="east-input" [formControl]="editAreaFormGroup.controls.longitude" placeholder="40.7127281" data-cy="east-input">
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Nord</mat-label>
            <input matInput type="number" id="north-input" [formControl]="editAreaFormGroup.controls.latitude" placeholder="74.0060152" data-cy="north-input">
          </mat-form-field>
        </div>
        
        <mat-form-field appearance="outline">
          <mat-label>Gemarkung</mat-label>
          <input matInput type="text" id="cadastral-district-input" [formControl]="editAreaFormGroup.controls.cadastralDistrict" placeholder="Musterdorf" data-cy="cadastral-district-input">
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Flur</mat-label>
          <input matInput type="text" id="floor-input" [formControl]="editAreaFormGroup.controls.floor" placeholder="1" data-cy="floor-input">
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Flurstücknummer</mat-label>
          <input matInput type="text" id="parcel-number-input" [formControl]="editAreaFormGroup.controls.parcelNumber" placeholder="123a" data-cy="parcel-number-input">
        </mat-form-field>
        
        <mat-form-field appearance="outline" style="margin-bottom: 0">
          <mat-label>Beschreibung</mat-label>
            <textarea matInput
                      id="description-input"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="5"
                      cdkAutosizeMaxRows="15"
                      placeholder="Lorem Ipsum Mandala"
                      [formControl]="editAreaFormGroup.controls.description"
                      data-cy="description-input"
            ></textarea>
        </mat-form-field>
        
        <mat-form-field appearance="outline" style="margin-bottom: 0">
          <mat-label>Soil</mat-label>
            <textarea matInput
                      id="soil-input"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="5"
                      cdkAutosizeMaxRows="15"
                      placeholder="Lorem Ipsum Mandala"
                      [formControl]="editAreaFormGroup.controls.soil"
                      data-cy="soil-input"
            ></textarea>
        </mat-form-field>
        
      </div>
    </div>

    <hr>
    <div style="width: 100%; display: flex; flex-direction: row">
      <button id="cancel-button" [disabled]="isWaitingForEditAreaResponse" [class.spinner]="isWaitingForEditAreaResponse" style="width: 100%; margin-right: 16px; " mat-raised-button type="button" [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, projectIdFromParam, GeologAreasRoute, areaIdFromParam]" data-cy="cancel-button">Abbrechen</button>
      <button id="save-button" [disabled]="isWaitingForEditAreaResponse" [class.spinner]="isWaitingForEditAreaResponse" style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="submit-button">Speichern</button>
    </div>

    <div style="height: 60px">
   <p class="login-error" *ngIf="editFailedNoConnection" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
   <p class="login-error" *ngIf="editFailedInternalServer" data-cy="internal-server-error">Fehler beim Speichern auf dem Server!</p> </div>
    
    
  </form>
</div>

<p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">Fataler Fehler beim Laden der benötigten Daten!</p>
