import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BoreholeDto, CreateAreaRequestDto, CreateBoreholeBulkRequestDto, CreateBoreholeManualRequestDto, CreateBoreholeRequestDto, GetBoreholesByFilterDto } from '@platri/geolog-common-core';
import { PageDto } from '@platri/elab-common-swagger';
import { environment } from '../../environments/default.environment';

@Injectable({
  providedIn: 'root',
})
export class BoreholeHttpService implements OnDestroy {
  baseUrl = environment.geologApi;

  constructor(
    private httpClient: HttpClient,
  ) {
    console.log('Initializing BoreholeHttpService');
  }

  ngOnDestroy(): void {
    console.log('Destroying BoreholeHttpService');
  }
  
  getBoreholesByFilter(getBoreholesByFilter: GetBoreholesByFilterDto): Observable<PageDto<BoreholeDto>> {
    return this.httpClient.post<PageDto<BoreholeDto>>(this.baseUrl + 'borehole/filter', getBoreholesByFilter);
  }
  
  getBoreholeById(boreholeId: number): Observable<BoreholeDto> {
    return this.httpClient.get<BoreholeDto>(this.baseUrl + 'borehole/' + boreholeId);
  }

  createBoreholeManual(createBoreholeManualRequestDto: CreateBoreholeManualRequestDto): Observable<number> {
    return this.httpClient.post<number>(this.baseUrl + 'borehole/manual', createBoreholeManualRequestDto);
  }

  createBoreholeBulk(createBoreholeBulkRequestDtos: CreateBoreholeBulkRequestDto[], areaId: number): Observable<number> {
    return this.httpClient.post<number>(this.baseUrl + 'borehole/bulk/' + areaId, createBoreholeBulkRequestDtos);
  }

}
