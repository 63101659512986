import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologJoinCompanyCodeTokenRouterParam } from '../../shared/router-params';
import { GeologAppRoute, GeologLoginRoute } from '../../shared/routes/geolog.routes';
import { AuthStateService } from '../../shared/services/auth-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyHttpService } from '../../shared/services/company-http.service';
import { JoinCompanyRequestDto } from '@platri/geolog-common-core';

@Component({
  selector: 'elab-join-company-page',
  templateUrl: './join-company-page.component.html',
  styleUrls: ['./join-company-page.component.scss']
})
export class JoinCompanyPageComponent implements OnInit, OnDestroy {

  subscriptions: Subscription =  new Subscription();

  isWaitingForJoinCompanyResponse = false;

  joinCompanyFailedWrongData = false;
  joinCompanyFailedNoConnection = false;
  joinCompanyFailedInternalServer = false;

  hasSuccessfullyJoinCompany = false;
  
  routerParams!: Params;
  joinCompanyCodeFromParams: string;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authStateService: AuthStateService,
    private companyHttpService: CompanyHttpService,
    private matSnackbar: MatSnackBar
  ) {}
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.joinCompanyCodeFromParams = this.routerParams[GeologJoinCompanyCodeTokenRouterParam];
    if (!this.authStateService.isLoggedIn.getValue() || !this.joinCompanyCodeFromParams) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute], {queryParams: {returnUrl: this.router.url}});
    } else {
      this.joinCompany();
    }
  }
  
  joinCompany(): void {
    this.isWaitingForJoinCompanyResponse = true;
    this.joinCompanyFailedWrongData = false;
    this.joinCompanyFailedNoConnection = false;
    this.joinCompanyFailedInternalServer = false;
    const joinCompanyRequestDto: JoinCompanyRequestDto = {joinCompanyCode: this.joinCompanyCodeFromParams};
    this.subscriptions.add(this.companyHttpService.joinCompany(joinCompanyRequestDto).subscribe(res => {
      this.hasSuccessfullyJoinCompany = true;
      this.isWaitingForJoinCompanyResponse = false;
      this.matSnackbar.open("Successfully joined company!");
    }, error => {
      console.log(error);
      if (error.status === 0) {
        this.onConnectionLost();
      }
      if (error.status === 500) {
        this.onInternalServerError();
      }
      this.isWaitingForJoinCompanyResponse = false;
    }));
  }

  onConnectionLost(): void {
    this.joinCompanyFailedNoConnection = true;
  }
  
  onInternalServerError(): void {
    this.joinCompanyFailedInternalServer = true;
  }

}
