export function generateJoinCompanyCode(length = 6): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    // At least 2 numbers
    let numberPart = '';
    for (let i = 0; i < 2; i++) {
      numberPart += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    let charPart = '';
    for (let i = 0; i < length - 2; i++) {
      charPart += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    const code = numberPart + charPart;

    // Shuffle the code
    return code.split('').sort(() => Math.random() - 0.5).join('');
}
