import { BoreholeLayerInterface } from '../../..';

export class CreateBoreholeLayerRequestDto implements BoreholeLayerInterface {
  depthMM: number;
  compressionLoadMPA: number;
  additionalInformation: string;
  boreholeId: number;
  
  constructor(depthMM: number, compressionLoadMPA: number, additionalInformation: string, boreholeId: number) {
    this.depthMM = depthMM;
    this.compressionLoadMPA = compressionLoadMPA;
    this.additionalInformation = additionalInformation;
    this.boreholeId = boreholeId;
  }
}
