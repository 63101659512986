import { AddressInterface } from '../../..';

export class AddressDto implements AddressInterface {
  street: string;
  streetNumber: string;
  city: string;
  country: string;
  zipCode: string;
  formattedAddress: string;
  latitude: number;
  longitude: number;
  googleMapsUrl: string;
  googleMapsPlaceId: string;
  state: string;
  timezoneOffsetMinutes: number;
  timezoneId: string;
  name?: string;
  
  constructor(args: AddressDto) {
    if (args) { 
      this.street = args.street;
      this.streetNumber = args.streetNumber;
      this.city = args.city;
      this.country = args.country;
      this.zipCode = args.zipCode;
      this.formattedAddress = args.formattedAddress;
      this.latitude = args.latitude;
      this.longitude = args.longitude;
      this.googleMapsUrl = args.googleMapsUrl;
      this.googleMapsPlaceId = args.googleMapsPlaceId;
      this.state = args.state;
      this.timezoneOffsetMinutes = args.timezoneOffsetMinutes;
      this.timezoneId = args.timezoneId;
      this.name = args.name;
    } 
  }
}
