<div class="full-view">
  <div class="flex-container">
    <div class="loading-box" *ngIf="isWaitingForJoinCompanyResponse">
      <mat-spinner></mat-spinner>
    </div>
    
    <div *ngIf="!joinCompanyFailedNoConnection && !isWaitingForJoinCompanyResponse && hasSuccessfullyJoinCompany">
      Unternehmen erfolgreich beigetreten!
    </div>
    
    <div *ngIf="!joinCompanyFailedNoConnection && !isWaitingForJoinCompanyResponse && !hasSuccessfullyJoinCompany">
      Etwas ist schief gelaufen.
    </div>
    
    <div *ngIf="joinCompanyFailedNoConnection">
      Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.
    </div>
    
    <div *ngIf="joinCompanyFailedInternalServer">
      Fehler auf dem Server aufgetreten
    </div>
  </div>
</div>
