import { Pipe, PipeTransform } from '@angular/core';
import { BoreholeLayerFillingMaterialEnum } from '@platri/geolog-common-core';

@Pipe({
  name: 'fillingMaterial',
})
export class FillingMaterialPipe implements PipeTransform {
  transform(value: BoreholeLayerFillingMaterialEnum): string | null {
    if (value === null) {
      return null;
    }
    switch (value) {
      case BoreholeLayerFillingMaterialEnum.EXCAVATED_SOIL:
        return 'Bodenaushub';
      case BoreholeLayerFillingMaterialEnum.SWELLING_CLAY_PELLETS:
        return 'Quellton (Pallets)';
      case BoreholeLayerFillingMaterialEnum.SWELLING_CLAY_GRANULES:
        return 'Quellton (Granulat)';
      case BoreholeLayerFillingMaterialEnum.GRAVEL:
        return 'Kies';
      case BoreholeLayerFillingMaterialEnum.CRUSHED_STONE:
        return 'Schotter';
      case BoreholeLayerFillingMaterialEnum.CEMENT_SUSPENSION:
        return 'Zementsuspension';
      case BoreholeLayerFillingMaterialEnum.BOROPLAST:
        return 'BOROPLAST';
      case BoreholeLayerFillingMaterialEnum.GRIT:
        return 'Splitt';
      case BoreholeLayerFillingMaterialEnum.DRILLED_MATERIAL:
        return 'Bohrmaterial';
      case BoreholeLayerFillingMaterialEnum.FILLING_SAND:
        return 'Füllsand';
      default:
        return 'Unbekannt';
    }
  }
}
