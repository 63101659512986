import { CompanyLicenseKeyInterface } from '../../..';

export class CompanyLicenseKeyDto implements CompanyLicenseKeyInterface {
  id: number;
  licenseKey: string;
  isActive: boolean;
  
  constructor(args: CompanyLicenseKeyDto) {
    if (args) { 
      this.id = args.id;
      this.licenseKey = args.licenseKey;
      this.isActive = args.isActive;
    } 
  }
}
