import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CreateBoreholeFormInterface } from './create-borehole-form.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AreaHttpService, BoreholeHttpService, GeologAppRoute, GeologAreaIdRouterParam, GeologAreasRoute, GeologBoreholesRoute, GeologForgotPasswordRoute, GeologLoginRoute, GeologProjectIdRouterParam, GeologProjectsRoute } from '../../../shared';
import { BoreholeStatusEnum, CreateBoreholeManualRequestDto } from '@platri/geolog-common-core';
import { getSubscription } from '@platri/elab-angular-core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'elab-create-borehole-form',
  templateUrl: './create-borehole-form.component.html',
  styleUrls: ['./create-borehole-form.component.scss']
})
export class CreateBoreholeFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulCreateBoreholeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  createBoreholeFormGroup!: FormGroup<CreateBoreholeFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForCreateBoreholeResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;
  
  createFailedNoConnection = false;
  createFailedInternalServer = false;

  routerParams!: Params;
  projectIdFromParam: number;
  areaIdFromParam: number;
  
  BoreholeStatusEnum = BoreholeStatusEnum;
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private areaHttpService: AreaHttpService,
    private boreholeHttpService: BoreholeHttpService,
    private activatedRoute: ActivatedRoute,
    private matSnackbar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.createBoreholeFormGroup = this.createCreateBoreholeFormGroup();
  }

  createCreateBoreholeFormGroup(): FormGroup<CreateBoreholeFormInterface> {
    return this.fb.group<CreateBoreholeFormInterface>({
      boreholeId: this.fb.control(null),
      status: this.fb.control(BoreholeStatusEnum.CREATED),
      depth: this.fb.control(null),
      diameter: this.fb.control(null),
      longitude: this.fb.control(null),
      latitude: this.fb.control(null),
      altitude: this.fb.control(null),
      waterLevel: this.fb.control(null),
    });
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.createBoreholeFormGroup.valueChanges.subscribe(res => {
      this.createFailedNoConnection = false;
      this.createFailedInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.projectIdFromParam = this.routerParams[GeologProjectIdRouterParam];
    this.areaIdFromParam = this.routerParams[GeologAreaIdRouterParam];
    if (!this.projectIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
  }
  

  onSubmit(): void {
    if (this.createBoreholeFormGroup.valid) {
      this.isWaitingForCreateBoreholeResponse = true;
      this.createFailedNoConnection = false;
      this.createFailedInternalServer = false;
      const {boreholeId, status, depth ,diameter, longitude, latitude, waterLevel, altitude} = this.createBoreholeFormGroup.getRawValue();
      //@ts-ignore
      const createBoreholeManualRequestDto: CreateBoreholeManualRequestDto = {physicalBoreholeId: boreholeId!, status: status!, longitude: longitude!, latitude: latitude!, depth: depth!, waterLevel: waterLevel!, altitude: altitude!, pusherDiameterMM: diameter! , areaId: +this.areaIdFromParam};
      this.subscriptions.add(this.boreholeHttpService.createBoreholeManual(createBoreholeManualRequestDto).subscribe(createdId => {
        this.successfulCreateBoreholeEmitter.next(true);
        this.matSnackbar.open('Successfully created borehole!');
        this.router.navigate(['/', GeologAppRoute, GeologProjectsRoute, this.projectIdFromParam, GeologAreasRoute, this.areaIdFromParam], {fragment: GeologBoreholesRoute});
        this.isWaitingForCreateBoreholeResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        this.isWaitingForCreateBoreholeResponse = false;
      }));
    }
  }

  onConnectionLost(): void {
    (this.createFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.createFailedInternalServer = true;
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologForgotPasswordRoute = GeologForgotPasswordRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
  protected readonly GeologAreasRoute = GeologAreasRoute;
  protected readonly GeologBoreholesRoute = GeologBoreholesRoute;
}
