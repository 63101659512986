import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { ProjectDetailPageUrlFragmentEnum, ProjectInterface } from '@platri/geolog-common-core';
import { ProjectHttpService } from '../../shared/services/project-http.service';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologProjectIdRouterParam } from '../../shared/router-params';
import { GeologAppRoute, GeologAreasRoute, GeologCreateAreaRoute, GeologEditProjectRoute, GeologLoginRoute, GeologProjectDetailIdRouteHelper, GeologProjectsRoute } from '../../shared/routes/geolog.routes';

@Component({
  selector: 'elab-project-detail-page',
  templateUrl: './project-detail-page.component.html',
  styleUrls: ['./project-detail-page.component.scss']
})
export class ProjectDetailPageComponent implements OnInit, OnDestroy {
  
  searchFormControl: FormControl<string | null> = new FormControl<string>('');
  dataSource : ProjectInterface[] = [];
  
  subscriptions: Subscription =  new Subscription();
  
  isInitialized = false;
  isWaitingForProjectResponse = false;
  hasErrorLoadingProjectDetails = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;

  routerParams!: Params;
  projectIdFromParam: number;
  
  project: ProjectInterface;
  
  selectedIndex = 0;
  
  constructor(
    private matDialog: MatDialog,
    private projectHttpService: ProjectHttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.activatedRoute.fragment, this.onUrlFragmentChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.projectIdFromParam = this.routerParams[GeologProjectIdRouterParam];
    if (!this.projectIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    if (this.activatedRoute.snapshot.url[2] && this.activatedRoute.snapshot.url[2].path === "areas") {
      this.selectedIndex = 1;
    }
    this.loadData();
  }

  onUrlFragmentChanges(fragment: string): void {
    if (fragment !== null) {
      switch (fragment.toLowerCase()) {
        default:
        case ProjectDetailPageUrlFragmentEnum.OVERVIEW: {
          this.selectedIndex = 0;
          break;
        }
        case ProjectDetailPageUrlFragmentEnum.AREAS: {
          this.selectedIndex = 1;
          break;
        }
      }
    } 
  }


  loadData(): void {
    this.hasErrorLoadingProjectDetails = false;
    this.isWaitingForProjectResponse = true;
    this.subscriptions.add(
      this.projectHttpService.getProjectById(this.projectIdFromParam).subscribe(projectDto => {
        this.project = projectDto;
        this.isWaitingForProjectResponse = false;
        this.isInitialized = true;
      }, error => {
        this.hasErrorLoadingProjectDetails = true;
        this.isWaitingForProjectResponse = false;
      })
    );
  }

  changeUrlFragment(index: number): void {
    switch (index) {
      case 0: {
        window.location.hash = '#' + ProjectDetailPageUrlFragmentEnum.OVERVIEW;
        break;
      }
      case 1: {
        window.location.hash = '#' + ProjectDetailPageUrlFragmentEnum.AREAS;
        break;
      }
    }
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
  protected readonly GeologProjectIdRouterParam = GeologProjectIdRouterParam;
  protected readonly GeologEditProjectRoute = GeologEditProjectRoute;
  protected readonly GeologProjectDetailIdRouteHelper = GeologProjectDetailIdRouteHelper;
  protected readonly GeologAreasRoute = GeologAreasRoute;
  protected readonly GeologCreateAreaRoute = GeologCreateAreaRoute;
}
