import { Component } from '@angular/core';

@Component({
  selector: 'elab-create-borehole-page',
  templateUrl: './create-borehole-page.component.html',
  styleUrls: ['./create-borehole-page.component.scss']
})
export class CreateBoreholePageComponent {
  
  
}
