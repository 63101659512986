import { FormControl } from '@angular/forms';
import { AddressInterface } from '@platri/geolog-common-core';

export interface EditAreaFormInterface {
  address: FormControl<AddressInterface | null>;
  latitude: FormControl<number | null>;
  longitude: FormControl<number | null>;
  cadastralDistrict: FormControl<string | null>;
  floor: FormControl<string | null>;
  parcelNumber: FormControl<string | null>;
  description: FormControl<string | null>;
  soil: FormControl<string | null>;
}
