import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { CreateAreaFormComponent } from './create-area-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { GoogleAddressSearchAutocompleteModule } from '../../google-address-search-autocomplete';
import { UploadBoreholesModule } from '../../shared/upload-boreholes/upload-boreholes.module';

@NgModule({
  declarations: [CreateAreaFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    RouterLink,
    GoogleAddressSearchAutocompleteModule,
    UploadBoreholesModule
  ],
  exports: [CreateAreaFormComponent]
})
export class CreateAreaFormModule { }
