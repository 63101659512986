
import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { environment } from '@platri/elab-angular-environments';
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[data-cy]'
})
export class DataCyDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    if (environment.production) {
      renderer.removeAttribute(el.nativeElement, 'data-cy');
    }
  }
}
