import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateProjectFormInterface } from './create-project-form.interface';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GeologAppRoute, GeologForgotPasswordRoute, GeologProjectsRoute } from '../../../shared/routes/geolog.routes';
import { ProjectHttpService } from '../../../shared/services/project-http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateProjectDto } from '@platri/geolog-common-core';
import { CompanyStateService } from '../../../shared/services/company-state.service';

@Component({
  selector: 'elab-create-project-form',
  templateUrl: './create-project-form.component.html',
  styleUrls: ['./create-project-form.component.scss']
})
export class CreateProjectFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulCreateProjectEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  createProjectFormGroup!: FormGroup<CreateProjectFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForCreateProjectResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;
  
  createFailedNoConnection = false;
  createFailedInternalServerError = false;
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private projectHttpService: ProjectHttpService,
    private matSnackbar: MatSnackBar,
    private companyStateService: CompanyStateService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.createProjectFormGroup = this.createCreateProjectFormGroup();
  }

  createCreateProjectFormGroup(): FormGroup<CreateProjectFormInterface> {
    return this.fb.group<CreateProjectFormInterface>({
      name: this.fb.control('', [Validators.required]),
      description: this.fb.control(''),
      client: this.fb.control(''),
      propertyOwner: this.fb.control(''),
      planner: this.fb.control('')
    });
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.createProjectFormGroup.valueChanges.subscribe(res => {
      this.createFailedNoConnection = false;
      this.createFailedInternalServerError = false;
    }));
  }

  initializeSubscriptions(): void {
  }
  

  onSubmit(): void {
    if (this.createProjectFormGroup.valid) {
      this.isWaitingForCreateProjectResponse = true;
      this.createFailedNoConnection = false;
      this.createFailedInternalServerError = false;
      const {name, planner, propertyOwner, description, client} = this.createProjectFormGroup.getRawValue();
      const createProjectDto: CreateProjectDto = new CreateProjectDto({name: name!, planner: planner!, propertyOwner: propertyOwner!, description: description!, client: client!, companyId: this.companyStateService.getSyncSelectedData()!.id!});
      this.subscriptions.add(this.projectHttpService.createProject(createProjectDto).subscribe(createdId => {
        this.successfulCreateProjectEmitter.next(true);
        this.matSnackbar.open('Successfully created Project!');
        this.router.navigate(['/', GeologAppRoute, GeologProjectsRoute]);
        this.isWaitingForCreateProjectResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        this.isWaitingForCreateProjectResponse = false;
      }));
    }
  }

  onConnectionLost(): void {
    (this.createFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.createFailedInternalServerError = true;
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologForgotPasswordRoute = GeologForgotPasswordRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
}
