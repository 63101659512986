<div class="full-view">
  <div class="header-bar">
    <button [routerLink]="['/', GeologAppRoute, GeologProjectsRoute]" mat-button color="primary" type="button"><mat-icon style="font-size: 12px; height: 12px; width: 12px; margin-right: 0">arrow_back_ios</mat-icon> Zurück zu den Projekten</button>
    <div>
      <button *ngIf="userCompanyUserRole === CompanyUserRoleEnum.ADMIN" [routerLink]="['/', GeologAppRoute, GeologCompanyRoute, companyIdFromParam, GeologEditCompanyRoute]" style="margin-right: 12px" mat-raised-button type="button"><mat-icon>edit</mat-icon>Bearbeiten</button>
    </div>
  </div>
  
  <div class="details">
    <h3>{{company ? company.name : '...'}}</h3>
    
    <mat-tab-group [selectedIndex]="selectedIndex" animationDuration="0ms" mat-align-tabs="center">
      <mat-tab label="Übersicht"> 
        <table *ngIf="isInitialized" style="width:100%">
          <tr>
            <th>Unternehmensname</th>
            <td>{{isInitialized ? company.name : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Lizenzschlüssel</th> 
            <td>{{isInitialized ? company.licenseKey : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Rechnungsadresse</th>
            <td>{{isInitialized ? company.address!.street + ' ' + company.address!.streetNumber + ', ' + company.address!.zipCode + ' ' + company.address!.city + ', ' + company.address!.country : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{isInitialized ? company.email : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Telefon</th>
            <td>{{isInitialized ? company.phoneNumber : "Loading..."}}</td>
          </tr>
        </table>
        <div class="loading-box" *ngIf="!isInitialized && !hasErrorLoadingCompanyDetails" data-cy="loading-box">
          <mat-spinner></mat-spinner>
        </div>
        
        <div *ngIf="!isInitialized && hasErrorLoadingCompanyDetails" class="error-box" >
          <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
          <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
        </div>
        <div style="position: fixed; width: 0; height: 0;" *ngIf="isWaitingForCompanyResponse" data-cy="loading-box"></div>
      </mat-tab>
      <mat-tab style="max-width: 110px; display: flex;flex-direction: column;" label="Team"> 
        <div style="display: flex; flex-direction: column; gap: 40px">
           <div *ngIf="company?.joinCompanyCode && userCompanyUserRole === CompanyUserRoleEnum.ADMIN" class="invitation-code-container">
            <div class="info">
              <span class="title">Einladungscode</span>
              <span class="description">Teile Sie diesen Code mit den Benutzern, die Sie Ihrem Team hinzufügen möchten.</span>
            </div>
            <div class="code-container">
              <span class="code">{{company.joinCompanyCode}}</span>
              <button class="button" mat-icon-button (click)="copyJoinCompanyCodeToClipboard()">
                <mat-icon class="icon">content_copy</mat-icon>
              </button>
            </div>
          </div>
          <elab-team-worker-list [userCompanyUserRole]="userCompanyUserRole" [userCompanyUserId]="userCompanyUserId" (loadedTeamMembersEmitter)="onLoadedTeamMembers($event)"></elab-team-worker-list>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
