<div class="flex-container">
  <form class="form" [formGroup]="joinCompanyFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize">
    <h4 style="align-self: flex-start;">Einem Unternehmen beitreten</h4>
    <p>Geben Sie den Code ein, den Sie mit Ihrer Einladungs E-Mail erhalten haben, um sich ihrem Unternehmen anzuschließen</p>
    <div class="flex-container-row">
      <div class="form-step-container flex-container-column">
        <mat-form-field appearance="outline">
          <mat-label>Code</mat-label>
          <input matInput type="text" id="code-input" [formControl]="joinCompanyFormGroup.controls.code" placeholder="A1B2C3D4" data-cy="code-input">
        </mat-form-field>
      </div>
    </div>
    
    <div style="width: 100%">
      <button id="join-button" [disabled]="isWaitingForJoinCompanyResponse" [class.spinner]="isWaitingForJoinCompanyResponse" style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="join-button">Beitreten</button>
    </div>

    <div style="height: 60px" data-cy="error-box">
      <p class="join-error wrong-data" *ngIf="joinCompanyFailedWrongData" data-cy="join-error-wrong-data">Der angegebene Code ist falsch</p>
      <p class="join-error" *ngIf="joinCompanyFailedNoConnection" data-cy="connection-error"> Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
      <p class="join-error" *ngIf="joinCompanyFailedInternalServer" data-cy="internal-server-error"> Fehler auf dem Server aufgetreten.</p>
    </div>
    
    
  </form>
</div>

<p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error" data-cy="fatal-error">Fataler Fehler beim Laden der benötigten Daten!</p>
