<div class="forty-sixty-view">
  <div class="image-container">
    <h3 style="color: white">GeoLOG</h3>
    <!--<button style="margin-top: 100px" (click)="openForgotPasswordDialog()">Open Dialog</button> -->
  </div>
  <div class="forgot-password-container">
        <elab-forgot-password-form></elab-forgot-password-form>
  </div>
  
  
</div>
