import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompanyInterface, CompanyUserInterface, CompanyUserRoleEnum } from '@platri/geolog-common-core';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologCompanyIdRouterParam, GeologProjectIdRouterParam } from '../../shared/router-params';
import { GeologAppRoute, GeologAreasRoute, GeologCompanyRoute, GeologCreateAreaRoute, GeologEditCompanyRoute, GeologEditProjectRoute, GeologLoginRoute, GeologProjectDetailIdRouteHelper, GeologProjectsRoute } from '../../shared/routes/geolog.routes';
import { CompanyHttpService } from '../../shared/services/company-http.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserHttpService } from '../../shared';

@Component({
  selector: 'elab-company-detail-page',
  templateUrl: './company-detail-page.component.html',
  styleUrls: ['./company-detail-page.component.scss']
})
export class CompanyDetailPageComponent implements OnInit, OnDestroy {
  
  searchFormControl: FormControl<string | null> = new FormControl<string>('');
  dataSource : CompanyInterface[] = [];
  
  subscriptions: Subscription =  new Subscription();
  
  isInitialized = false;
  isWaitingForCompanyResponse = false;
  hasErrorLoadingCompanyDetails = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;

  routerParams!: Params;
  companyIdFromParam: number;
  
  company: CompanyInterface;
  
  selectedIndex = 0;
  
  userCompanyUserRole: CompanyUserRoleEnum | null = null;
  userCompanyUserId: number;
  
  constructor(
    private companyHttpService: CompanyHttpService,
    private userHttpService: UserHttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private clipboard: Clipboard,
    private matSnackBar: MatSnackBar
  ) {
  }
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.companyIdFromParam = this.routerParams[GeologCompanyIdRouterParam];
    if (!this.companyIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    this.loadData();
  }


  loadData(): void {
    this.hasErrorLoadingCompanyDetails = false;
    this.isWaitingForCompanyResponse = true;
    this.subscriptions.add(
      this.companyHttpService.getCompanyById(+(this.companyIdFromParam)).subscribe(companyDto => {
        this.company = companyDto;
        console.log(this.company);
        this.isWaitingForCompanyResponse = false;
        this.isInitialized = true;
      }, error => {
        this.hasErrorLoadingCompanyDetails = true;
        this.isWaitingForCompanyResponse = false;
      })
    );
  }

  copyJoinCompanyCodeToClipboard(): void {
    this.clipboard.copy(this.company.joinCompanyCode!);
    this.matSnackBar.open('Invitation code copied!');
  }

  onLoadedTeamMembers(teamMembers: CompanyUserInterface[]): void {
    this.subscriptions.add(this.userHttpService.getCurrentUser().subscribe(userDto => {
      if (userDto.userRole === "ADMIN") this.userCompanyUserRole = CompanyUserRoleEnum.ADMIN;
      else {
        const userTeamMember = teamMembers.find(teamMember => teamMember.userId === userDto.id);
        this.userCompanyUserRole = userTeamMember!.role!;
        this.userCompanyUserId = userTeamMember!.id!;
      }
    }));
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
  protected readonly GeologProjectIdRouterParam = GeologProjectIdRouterParam;
  protected readonly GeologEditProjectRoute = GeologEditProjectRoute;
  protected readonly GeologProjectDetailIdRouteHelper = GeologProjectDetailIdRouteHelper;
  protected readonly GeologAreasRoute = GeologAreasRoute;
  protected readonly GeologCreateAreaRoute = GeologCreateAreaRoute;
  protected readonly GeologCompanyRoute = GeologCompanyRoute;
  protected readonly GeologEditCompanyRoute = GeologEditCompanyRoute;
  protected readonly CompanyUserRoleEnum = CompanyUserRoleEnum;
}
