<div class="full-view">
  <div class="table-with-search">
    <table cdk-table [dataSource]="dataSource" data-cy="borehole-layer-list-table">
      <ng-container cdkColumnDef="id">
        <th width="15%" style="text-align: left; color:#808080" cdk-header-cell *cdkHeaderCellDef> Schicht </th>
        <td cdk-cell *cdkCellDef="let row; let i = index"> {{i}} </td>
      </ng-container>
      
      <ng-container cdkColumnDef="depth">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Tiefe </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.depthMM !== null ? row.depthMM + ' mm' : ''}} </td>
      </ng-container>
      
      <ng-container cdkColumnDef="fillingQuantity">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Füllmenge (Masse) </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.fillingQuantity}} Kg </td>
      </ng-container>
      
      <ng-container cdkColumnDef="fillingMaterial">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Füll material </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.fillingMaterial | fillingMaterial}} </td>
      </ng-container>
      
      <ng-container cdkColumnDef="compressionLoad">
        <th width="20%" style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Kompressionslast </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.compressionLoadMPA ? (+row.compressionLoadMPA).toFixed(2) + ' Mpa' : ''}}</td>
      </ng-container>
       
      <ng-container cdkColumnDef="density">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Dichte </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.density ? (+row.density).toFixed(1) + ' kg/L' : ''}} </td>
      </ng-container>      
      
      <ng-container cdkColumnDef="thickness">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Schichtdicke </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row"> {{row.layerThickness ? row.layerThickness + ' mm' : ''}} </td>
      </ng-container>      
      
      
      
      <tr class="tr-header" cdk-header-row *cdkHeaderRowDef="['id', 'depth', 'fillingQuantity', 'fillingMaterial', 'compressionLoad', 'density', 'thickness']"></tr>
      <tr class="clickable-row" cdk-row *cdkRowDef="let row; columns: ['id', 'depth', 'fillingQuantity', 'fillingMaterial', 'compressionLoad', 'density', 'thickness']" data-cy="element-row"></tr>
    </table>
    
    <div class="loading-box" *ngIf="isLoadingBoreholeLayers" data-cy="loading-box">
      <mat-spinner></mat-spinner>
    </div>
    
    <div class="error-box" *ngIf="hasFailedLoadingBoreholeLayersNoConnection" data-cy="error-box">
      <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
      <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
    </div>
    
    <div class="error-box" *ngIf="hasFailedLoadingBoreholeLayersInternalServerError" data-cy="error-box">
      <p style="color: red" data-cy="internal-server-error">Der Server hat einen Fehler zurückgegebe!</p>
      <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
    </div>
    
    <div style="text-align: center" *ngIf="hasLoadedBoreholeLayers && !hasFailedLoadingBoreholeLayersNoConnection && availableBoreholeLayers.length === 0 && !hasFailedLoadingBoreholeLayersInternalServerError && !isLoadingBoreholeLayers" data-cy="no-results-box">
      <h4 style="margin-bottom: 12px">Keine Bohrlochlagen</h4>
    </div>
  </div>
</div>
