import { AfterContentInit, Component, OnDestroy } from '@angular/core';
import { BoreholeInterface, CreateBoreholeBulkRequestDto } from '@platri/geolog-common-core';
import { GeologAppRoute, GeologAreasRoute, GeologLoginRoute, GeologProjectsRoute } from '../../shared/routes/geolog.routes';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BoreholeHttpService } from '../../shared/services/borehole-http.service';
import { getSubscription } from '@platri/elab-angular-core';
import { GeologAreaIdRouterParam, GeologProjectIdRouterParam } from '../../shared/router-params';
@Component({
  selector: 'elab-upload-boreholes-page',
  templateUrl: './upload-boreholes-page.component.html',
  styleUrls: ['./upload-boreholes-page.component.scss']
})
export class UploadBoreholesPageComponent implements OnDestroy, AfterContentInit {
  subscriptions: Subscription = new Subscription();

  isInitialized = false;
  isWaitingForUploadBoreholesResponse = false;

  uploadFailedNoConnection = false;
  uploadFailedInternalServer = false;

  routerParams!: Params;
  projectIdFromParam: number;
  areaIdFromParam: number;
  
  createBoreholeBulkRequestDtos: CreateBoreholeBulkRequestDto[];

  constructor(
    private router: Router,
    private boreholeHttpService: BoreholeHttpService,
    private activatedRoute: ActivatedRoute,
    private matSnackbar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.initializeSubscriptions();
    this.isInitialized = true;
  }
  
  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.projectIdFromParam = this.routerParams[GeologProjectIdRouterParam];
    this.areaIdFromParam = this.routerParams[GeologAreaIdRouterParam];
    if (!this.projectIdFromParam || !this.areaIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
  }

  onBoreholeUploadChange(boreholes: BoreholeInterface[]): void {
    const createBoreholeBulkRequestDtos: CreateBoreholeBulkRequestDto[] = boreholes.map(borehole =>
      new CreateBoreholeBulkRequestDto({
        physicalBoreholeId: borehole.physicalBoreholeId!,
        latitude: borehole.latitude!,
        longitude: borehole.longitude!,
        altitude: borehole.altitude!
      }));
    this.createBoreholeBulkRequestDtos = createBoreholeBulkRequestDtos;
  }
  
  uploadBoreholes(): void {
    this.isWaitingForUploadBoreholesResponse = true;
    this.uploadFailedNoConnection = false;
    this.uploadFailedInternalServer = false;
    this.subscriptions.add(
      this.boreholeHttpService.createBoreholeBulk(this.createBoreholeBulkRequestDtos, this.areaIdFromParam).subscribe(res => {
        this.matSnackbar.open("Successfully uploaded boreholes!");
        this.router.navigate(['/', GeologAppRoute, GeologProjectsRoute, this.projectIdFromParam, GeologAreasRoute, this.areaIdFromParam]);
        this.isWaitingForUploadBoreholesResponse = false;
      }, error => {
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        this.isWaitingForUploadBoreholesResponse = false;
      })
    );
  }

  onConnectionLost(): void {
    this.uploadFailedNoConnection = true;
  }
  
  onInternalServerError(): void {
    this.uploadFailedInternalServer = true;
  }

  protected readonly GeologAreasRoute = GeologAreasRoute;
  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
}
