<div class="flex-container">
  <form class="form" [formGroup]="loginFormGroup" (ngSubmit)="onSubmit()" *ngIf="isInitialized && !hasFatalErrorOnInitialize">
    <h4 style="align-self: flex-start;">Willkommen zurück</h4>
    <div class="flex-container-row">
      <div class="form-step-container flex-container-column">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="text" id="email-input" [formControl]="loginFormGroup.controls.email" placeholder="max.muster@platri.de" data-cy="email-input">
          <mat-error *ngIf="loginFormGroup.controls.email.hasError('pattern')">
            Bitte geben Sie eine korrekte Email-Adresse ein!
          </mat-error>
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Passwort</mat-label>
          <input matInput id="password-input" [formControl]="loginFormGroup.controls.password" placeholder="12345678" [type]="hidePassword ? 'password' : 'text'" data-cy="password-input">
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
      </div>
    </div>
    
    <button type="button" id="forgot-password-button" style="align-self: flex-end; padding: 0; margin: -16px 0 18px 0; height: 20px" mat-button color="primary" (click)="routeToForgotPassword()" data-cy="forgot-password-button">Passwort vergessen?</button>
  
    <div style="width: 100%">
      <button id="login-button" [disabled]="isWaitingForLoginResponse" [class.spinner]="isWaitingForLoginResponse"  style="width: 100%" mat-raised-button color="primary" type="submit" data-cy="login-button">Einloggen</button>
    </div>

    <div>
      <p style="margin-top: 5px; margin-block-start: 0; margin-block-end: 0;">Noch keinen Account? <button type="button" id="sign-up-button" mat-button color="primary" (click)="routeToRegister()" data-cy="sign-up-button">Registrieren</button></p>
    </div>
    
    <div style="height: 60px">
      <p class="login-error wrong-data" *ngIf="signInFailedWrongData" data-cy="wrong-data-error">Die angegebenen Daten sind falsch</p>
   <p class="login-error" *ngIf="signInFailedNoConnection" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
   <p class="login-error" *ngIf="signInFailedInternalServer" data-cy="internal-server-error">Fehler beim Speichern auf dem Server!</p> </div>
    
    
  </form>
</div>

<p style="color: red" *ngIf="hasFatalErrorOnInitialize" data-cy="fatal-error">Fataler Fehler beim Laden der benötigten Daten!</p>
