import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordDialogComponent } from '../../dialogs/forgot-password-dialog/forgot-password-dialog.component';

@Component({
  selector: 'elab-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent {
  
  constructor(
    private matDialog: MatDialog
  ) {
  }

  openForgotPasswordDialog(): void {
    const dialogRef = this.matDialog.open(ForgotPasswordDialogComponent, {
      minWidth: '75vw'
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  
}
