import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditCompanyFormInterface } from './edit-company-form.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologAreasRoute, GeologCompanyRoute, GeologForgotPasswordRoute, GeologLoginRoute, GeologProjectsRoute } from '../../../shared/routes/geolog.routes';
import { CompanyAddressDto, CompanyInterface, UpdateCompanyRequestDto } from '@platri/geolog-common-core';
import { getSubscription } from '@platri/elab-angular-core';
import { GeologCompanyIdRouterParam } from '../../../shared/router-params';
import { CompanyHttpService } from '../../../shared/services/company-http.service';
import { addressValidator } from '../../../shared/validators/address.validator';
import { germanPhoneValidator } from '../../../shared/validators/german-phone.validator';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'elab-edit-company-form',
  templateUrl: './edit-company-form.component.html',
  styleUrls: ['./edit-company-form.component.scss']
})
export class EditCompanyFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulEditCompanyEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  editCompanyFormGroup!: FormGroup<EditCompanyFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForEditCompanyResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;
  
  editFailedNoConnection = false;
  editFailedInternalServer = false;

  routerParams!: Params;
  companyIdFromParam: number;


  isLoadingCompany = false;
  hasFailedLoadingCompanyNoConnection = false;
  hasFailedLoadingCompanyInternalServer = false;

  loadedCompany: Readonly<CompanyInterface>;
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private companyHttpService: CompanyHttpService,
    private activatedRoute: ActivatedRoute,
    private matSnackbar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.editCompanyFormGroup = this.createEditAreaFormGroup();
    this.editCompanyFormGroup.disable();
  }

  createEditAreaFormGroup(): FormGroup<EditCompanyFormInterface> {
    return this.fb.group<EditCompanyFormInterface>({
      name: this.fb.control('', [Validators.required]),
      licenseKey: this.fb.control('', [Validators.required]),
      businessArea: this.fb.control('', [Validators.required]),
      billingAddress: this.fb.control('', [Validators.required, addressValidator()]),
      email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-äöüß]+@[a-z0-9.-äöüß]+\\.[a-z]{2,4}$')]),
      phoneNumber: this.fb.control('', [Validators.required, germanPhoneValidator()])
    });
  }

  patchEditCompanyFormGroup(companyInterface: CompanyInterface): void {
    this.editCompanyFormGroup.patchValue(companyInterface);
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.editCompanyFormGroup.valueChanges.subscribe(res => {
      this.editFailedInternalServer = false;
      this.editFailedNoConnection = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.companyIdFromParam = this.routerParams[GeologCompanyIdRouterParam];
    if (!this.companyIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    this.loadData();
  }

  loadData(): void {
    this.isLoadingCompany = true;
    this.hasFailedLoadingCompanyNoConnection = false;
    this.hasFailedLoadingCompanyInternalServer = false;
    this.subscriptions.add(
      this.companyHttpService.getCompanyById(this.companyIdFromParam).subscribe(companyDto => {
        this.loadedCompany = companyDto;
        this.patchEditCompanyFormGroup(companyDto);
        this.isLoadingCompany = false;
        this.editCompanyFormGroup.enable();
      }, error => {
        console.log(error);
        this.isLoadingCompany = false;
        if (error.status === 0) {
          this.onLoadCompanyConnectionLost();
        }
        if (error.status === 500) {
          this.onLoadCompanyInternalServer();
        }
      })
    );
  }
  

  onSubmit(): void {
    if (this.editCompanyFormGroup.valid) {
      this.isWaitingForEditCompanyResponse = true;
      this.editFailedInternalServer = false;
      const {billingAddress, businessArea, email, name, phoneNumber} = this.editCompanyFormGroup.getRawValue();
      const processedAddress: CompanyAddressDto  = this.getCompanyAddressFromBillingAddress(billingAddress!)!;
      const updateCompanyRequestDto: UpdateCompanyRequestDto = {address: processedAddress!, businessArea: businessArea!, email: email!, name: name!, phoneNumber: phoneNumber!};
      this.subscriptions.add(this.companyHttpService.updateCompany(this.companyIdFromParam, updateCompanyRequestDto).subscribe(companyDto => {
        this.successfulEditCompanyEmitter.next(true);
        this.matSnackbar.open("Successfully edited company!");
        this.router.navigate(['/', GeologAppRoute, GeologCompanyRoute, this.companyIdFromParam]);
        this.isWaitingForEditCompanyResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        this.isWaitingForEditCompanyResponse = false;
      }));
    }
  }
  
  onLoadCompanyConnectionLost(): void {
    this.hasFailedLoadingCompanyNoConnection = true;
  }

  onLoadCompanyInternalServer(): void {
    this.hasFailedLoadingCompanyInternalServer = true;
  }

  onConnectionLost(): void {
    (this.editFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.editFailedInternalServer = true;
  }

  getCompanyAddressFromBillingAddress(billingAddress: string): CompanyAddressDto | null {
    const regex = /(.+?)\s+(\d+),\s+(\d{5}),\s+(.+)/;
    const match = billingAddress.match(regex);
    
    if (match && match.length === 5) {
      return new CompanyAddressDto({
        street: match[1],
        streetNumber: match[2],
        zipCode: match[3],
        city: match[4],
        country: 'Germany',
        addressLine1: '1',
        state: 'NRW'
      });
    }
    
    return null;
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologForgotPasswordRoute = GeologForgotPasswordRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
  protected readonly GeologAreasRoute = GeologAreasRoute;
  protected readonly GeologCompanyRoute = GeologCompanyRoute;
}
