import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DeleteAccountPageComponent } from './delete-account-page.component';

@NgModule({
  declarations: [DeleteAccountPageComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  exports: [DeleteAccountPageComponent]
})
export class DeleteAccountPageModule { }
