import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotPasswordPageComponent } from './forgot-password-page.component';
import { ForgotPasswordFormModule } from '../../components/forgot-password-page/forgot-password-form/forgot-password-form.module';
import { LoginFormModule } from '../../components/login-page/login-form/login-form.module';

@NgModule({
  declarations: [ForgotPasswordPageComponent],
  imports: [
    CommonModule,
    ForgotPasswordFormModule,
    LoginFormModule
  ],
  exports: [ForgotPasswordPageComponent]
})
export class ForgotPasswordPageModule { }
