<elab-team-worker-invite *ngIf="userCompanyUserRole === CompanyUserRoleEnum.ADMIN" (successfulTeamWorkerInviteEmitter)="loadData()"></elab-team-worker-invite>
<div class="full-view" style="padding-top: 20px;">
  <div class="table-with-search">
    <table cdk-table [dataSource]="dataSource" data-cy="team-worker-list-table">
      <ng-container cdkColumnDef="id">
        <th width="40%" style="text-align: left; color:#808080" cdk-header-cell *cdkHeaderCellDef> Email oder Name </th>
        <td cdk-cell *cdkCellDef="let row"> {{row.user ? row.user.firstName + ' ' + row.user.lastName : row.invitedEmail}}</td>
      </ng-container>
      
      <ng-container cdkColumnDef="role">
        <th style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef> Rolle </th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row">
          <ng-container *ngIf="row.role === CompanyUserRoleEnum.ADMIN">Administrator</ng-container>
          <ng-container *ngIf="row.role === CompanyUserRoleEnum.WORKER">Mitarbeiter</ng-container>
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="status">
        <th width="20px" style="text-align: center; color:#808080" cdk-header-cell *cdkHeaderCellDef>Status</th>
        <td style="text-align: center;" cdk-cell *cdkCellDef="let row">
            <mat-chip *ngIf="row.status === CompanyUserStatusEnum.ACTIVE" [ngClass]="'success-chip'" class="success-chip">Mitglieder</mat-chip>
            <mat-chip *ngIf="row.status === CompanyUserStatusEnum.INVITED" [ngClass]="'pending-chip'">Ausstehend</mat-chip>
        </td>
      </ng-container>
      
      <ng-container cdkColumnDef="actions">
      <th style="color:#808080" cdk-header-cell *cdkHeaderCellDef></th>
      <td width="20px" cdk-cell *cdkCellDef="let row"> 
        <button *ngIf="userCompanyUserRole === CompanyUserRoleEnum.ADMIN" style="color: black" mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
          <button [disabled]="row.id === userCompanyUserId " mat-menu-item (click)="handleRowEditClick(row)">Bearbeiten</button>
          <button [disabled]="row.id === userCompanyUserId" mat-menu-item (click)="handleRowDeleteClick(row)">Löschen</button>
        </mat-menu>
      </td>
    </ng-container>
      
      <tr class="tr-header" cdk-header-row *cdkHeaderRowDef="['id', 'role', 'status', 'actions']"></tr>
      <tr class="clickable-row" cdk-row *cdkRowDef="let row; columns: ['id', 'role', 'status', 'actions']" (click)="handleRowClick(row)" data-cy="element-row"></tr>
    </table>
    
    <div class="loading-box" *ngIf="isLoadingTeamWorkers" data-cy="loading-box">
      <mat-spinner></mat-spinner>
    </div>
    
    <div class="error-box" *ngIf="hasFailedLoadingTeamWorkersNoConnection" data-cy="error-box">
      <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
      <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
    </div>
    
    <div class="error-box" *ngIf="hasFailedLoadingTeamWorkersInternalServer" data-cy="error-box">
      <p style="color: red" data-cy="internal-server-error">Fehler auf dem Server aufgetreten.</p>
      <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
    </div>
    
    <div style="text-align: center" *ngIf="hasLoadedTeamWorkers && !hasFailedLoadingTeamWorkersNoConnection && !hasFailedLoadingTeamWorkersInternalServer && availableTeamUsers.length === 0" data-cy="no-results-box">
      <h4 style="margin-bottom: 12px">Keine Teammitglieder</h4>
    </div>
  </div>
</div>
