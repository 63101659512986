import { ProjectInterface } from '../../..';

export class CreateProjectDto implements ProjectInterface {
  name: string;
  description?: string;
  client?: string;
  propertyOwner?: string;
  planner?: string;
  companyId: number;
  
  constructor(args: CreateProjectDto) {
    if (args) {
      this.name = args.name;
      this.description = args.description;
      this.client = args.client;
      this.propertyOwner = args.propertyOwner;
      this.planner = args.planner;
      this.companyId = args.companyId;
    }
  }
}
