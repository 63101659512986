import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthStateService } from '../shared/services/auth-state.service';
import { GeologAppRoute, GeologLoginRoute } from '../shared/routes/geolog.routes';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private router: Router, private authStateService: AuthStateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authStateService.getSyncIsLoggedIn()) {
      return true;
    } else {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
      return false;
    }
  }
}
