import { BoreholeInterface, BoreholeStatusEnum } from '../../..';

export class CreateBoreholeViaWebRequestDto implements BoreholeInterface {
  physicalBoreholeId: string;
  status: BoreholeStatusEnum;
  pusherDiameterMM: number;
  depth: number;
  longitude: number;
  latitude: number;
  altitude: number;
  waterLevel: number;
  
  constructor(args: CreateBoreholeViaWebRequestDto) {
    if (args) { 
      this.physicalBoreholeId = args.physicalBoreholeId;
      this.status = args.status;
      this.pusherDiameterMM = args.pusherDiameterMM;
      this.depth = args.depth;
      this.longitude = args.longitude;
      this.latitude = args.latitude;
      this.altitude = args.altitude;
      this.waterLevel = args.waterLevel;
    } 
  }
}
