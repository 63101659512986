import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamWorkerListComponent } from './team-worker-list.component';
import { ResetPasswordFormModule } from '../../reset-password-page/reset-password-form/reset-password-form.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import {
    TeamWorkerInviteModule
} from "libs/frontend/geolog-app/src/lib/components/company-detail-page/team-worker-invite";

@NgModule({
  declarations: [TeamWorkerListComponent],
    imports: [
        CommonModule,
        ResetPasswordFormModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        CdkTableModule,
        MatIconModule,
        RouterLink,
        MatProgressSpinnerModule,
        MatChipsModule,
        MatMenuModule,
        TeamWorkerInviteModule
    ],
  exports: [TeamWorkerListComponent]
})
export class TeamWorkerListModule { }
