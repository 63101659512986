import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MagicLinkLoginPageComponent } from './magic-link-login-page.component';

@NgModule({
  declarations: [MagicLinkLoginPageComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  exports: [MagicLinkLoginPageComponent]
})
export class MagicLinkLoginPageModule { }
