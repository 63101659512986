import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CompanyDto, CreateCompanyRequestDto, JoinCompanyRequestDto, ProjectDto, UpdateCompanyRequestDto } from '@platri/geolog-common-core';
import { environment } from '../../environments/default.environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyHttpService implements OnDestroy {
  baseUrl = environment.geologApi;

  constructor(
    private httpClient: HttpClient,
  ) {
    console.log('Initializing CompanyHttpService');
  }

  ngOnDestroy(): void {
    console.log('Destroying CompanyHttpService');
  }

  joinCompany(joinCompanyRequestDto: JoinCompanyRequestDto): Observable<number> {
    return this.httpClient.post<number>(this.baseUrl + 'company/join-company-code', joinCompanyRequestDto);
  }
  
  getCompanyById(companyId: number): Observable<CompanyDto> {
    return this.httpClient.get<CompanyDto>(this.baseUrl + 'company/' + companyId);
  }

  createCompany(createCompanyRequestDto: CreateCompanyRequestDto): Observable<number> {
    return this.httpClient.post<number>(this.baseUrl + 'company', createCompanyRequestDto);
  }

  updateCompany(id: number, updateCompanyRequestDto: UpdateCompanyRequestDto): Observable<CompanyDto> {
    return this.httpClient.patch<CompanyDto>(this.baseUrl + 'company/' + id, updateCompanyRequestDto);
  }

  getAllByCurrentUser(): Observable<CompanyDto[]> {
    return this.httpClient.post<CompanyDto[]>(this.baseUrl + 'company/filter', {});
  }

}
