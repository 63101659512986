<div class="full-view">
  <div class="header-bar">
    <button [routerLink]="['/', GeologAppRoute, GeologProjectsRoute]" mat-button color="primary" type="button"><mat-icon style="font-size: 12px; height: 12px; width: 12px; margin-right: 0">arrow_back_ios</mat-icon> Zurück zu den Projekten</button>
    <div>
      <button [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, projectIdFromParam, GeologEditProjectRoute]" style="margin-right: 12px" mat-raised-button type="button"><mat-icon>edit</mat-icon>Bearbeiten</button>
      <button [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, projectIdFromParam, GeologCreateAreaRoute]" style="margin-right: 12px" mat-raised-button type="button"><mat-icon>add</mat-icon>Neuer Bereich</button>
    </div>
  </div>
  
  <div class="details">
    <h3>{{project ? project.name : '...'}}</h3>
    
    <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="changeUrlFragment($event)" animationDuration="0ms" mat-align-tabs="center">
      <mat-tab label="Übersicht"> 
        <table *ngIf="isInitialized" style="width:100%">
          <tr>
            <th>Projektname</th>
            <td>{{isInitialized ? project.name : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Beschreibung</th>
            <td>{{isInitialized ? project.description && project.description !== '' ? project.description : '-' : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Kunde</th>
            <td>{{isInitialized ? project.client && project.client !== '' ? project.client : '-' : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Grundeigentümer</th>
            <td>{{isInitialized ? project.propertyOwner && project.propertyOwner !== '' ? project.propertyOwner : '-' : "Loading..."}}</td>
          </tr>
          <tr>
            <th>Planer</th>
            <td>{{isInitialized ? project.planner && project.planner !== '' ? project.planner : '-' : "Loading..."}}</td>
          </tr>
        </table>
        <div class="loading-box" *ngIf="!isInitialized && !hasErrorLoadingProjectDetails" data-cy="loading-box">
          <mat-spinner></mat-spinner>
        </div>
        
        <div *ngIf="!isInitialized && hasErrorLoadingProjectDetails" class="error-box" >
          <p style="color: red" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden!</p>
          <button mat-button color="primary" type="button" (click)="loadData()" data-cy="try-again-button"> Versuchen Sie ein noch einmal </button>
          <div style="position: fixed; width: 0; height: 0;" *ngIf="isWaitingForProjectResponse" data-cy="loading-box"></div>

        </div>
      </mat-tab>
      <mat-tab style="max-width: 110px" label="Bereiche" > 
        <elab-area-list></elab-area-list>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
