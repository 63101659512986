import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoreholeLayerListComponent } from './borehole-layer-list.component';
import { ResetPasswordFormModule } from '../../reset-password-page/reset-password-form/reset-password-form.module';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { FillingMaterialPipe } from '../../../pipes';

@NgModule({
  declarations: [BoreholeLayerListComponent, FillingMaterialPipe],
  imports: [
    CommonModule,
    ResetPasswordFormModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CdkTableModule,
    MatIconModule,
    RouterLink,
    MatProgressSpinnerModule,
    MatChipsModule
  ],
  exports: [BoreholeLayerListComponent]
})
export class BoreholeLayerListModule { }
