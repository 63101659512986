<div class="forty-sixty-view">
  <div class="image-container">
    <h3 style="color: white">GeoLOG</h3>
    <!-- <button style="margin-top: 100px" (click)="openRegisterDialog()">Open Dialog</button> -->
  </div>
  <div class="register-container">
    <elab-register-form></elab-register-form>
    
   <!-- <button style="margin-top: 100px" (click)="openRegisterDialog()">Open Dialog</button> -->
  </div>
  
  
</div>
