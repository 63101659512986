import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'elab-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.scss']
})
export class RegisterDialogComponent {
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly registerData: any,
    private readonly dialogRef: MatDialogRef<RegisterDialogComponent>,
  ) {
  }

  onSuccessfullyLoggedIn(): void {
    this.dialogRef.close();
  }
  
}
