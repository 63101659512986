import { BoreholeLayerInterface } from '../../..';

export class UpdateBoreholeLayerRequestDto implements BoreholeLayerInterface {
  depthMM: number;
  compressionLoadMPA: number;
  additionalInformation: string;
  
  constructor(depthMM: number, compressionLoadMPA: number, additionalInformation: string) {
    this.depthMM = depthMM;
    this.compressionLoadMPA = compressionLoadMPA;
    this.additionalInformation = additionalInformation;
  }
}
