import { Component } from '@angular/core';

@Component({
  selector: 'elab-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent {
  
}
