import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AreaDto, CreateAreaRequestDto, GetAreasByFilterRequestDto, UpdateAreaRequestDto } from '@platri/geolog-common-core';
import { PageDto } from '@platri/elab-common-swagger';
import { environment } from '../../environments/default.environment';

@Injectable({
  providedIn: 'root',
})
export class AreaHttpService implements OnDestroy {
  baseUrl = environment.geologApi;

  constructor(
    private httpClient: HttpClient,
  ) {
    console.log('Initializing AreaHttpService');
  }

  ngOnDestroy(): void {
    console.log('Destroying AreaHttpService');
  }

  getAllAreasByFilter(getAreasByFilterRequestDto: GetAreasByFilterRequestDto): Observable<PageDto<AreaDto>> {
    return this.httpClient.post<PageDto<AreaDto>>(this.baseUrl + 'area/filter', getAreasByFilterRequestDto);
  }

  getAreaById(id: number): Observable<AreaDto> {
    return this.httpClient.get<AreaDto>(this.baseUrl + 'area/' + id);
  }

  createArea(createAreaDto: CreateAreaRequestDto): Observable<number> {
    return this.httpClient.post<number>(this.baseUrl + 'area', createAreaDto);
  }

  updateArea(id: number, updateAreaDto: UpdateAreaRequestDto): Observable<AreaDto> {
    return this.httpClient.patch<AreaDto>(this.baseUrl + 'area/' + id, updateAreaDto);
  }

}
