import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address as ElabGoogleAddress, Options } from '@platri/elab-angular-google-autocomplete';
import { Subscription } from 'rxjs';

import { GoogleAddressSearchAutocompleteFormInterface } from './google-address-search-autocomplete-form.interface';
import { AddressInterface } from '@platri/geolog-common-core';

@Component({
  selector: 'elab-google-address-search-autocomplete',
  templateUrl: './google-address-search-autocomplete.component.html',
  styleUrls: ['./google-address-search-autocomplete.component.scss'],
})
export class GoogleAddressSearchAutocompleteComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isRequired = true;
  @Input() existingAddress: AddressInterface;
  
  @Output() addressSelected = new EventEmitter<AddressInterface>();

  options: Partial<Options>;
 
  googleAddressSearchAutocompleteFormGroup!: FormGroup<GoogleAddressSearchAutocompleteFormInterface>;
  
  private subscriptions = new Subscription();
  constructor(private readonly fb: FormBuilder) {}
  
  ngOnInit(): void {
    this.initializeFormGroup();
    this.updateAddressFromInput();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['existingAddress']) {
      this.updateAddressFromInput();
    }
  }
  
  updateAddressFromInput(): void {
    if (this.existingAddress) {
      this.googleAddressSearchAutocompleteFormGroup.patchValue({address: this.existingAddress.formattedAddress});
    }
  }

  initializeFormGroup(): void {
    this.googleAddressSearchAutocompleteFormGroup = this.createGoogleAddressSearchAutocompleteFormGroup();
  }
  
  createGoogleAddressSearchAutocompleteFormGroup(): FormGroup<GoogleAddressSearchAutocompleteFormInterface> {
    return this.fb.group<GoogleAddressSearchAutocompleteFormInterface>({
      address: this.fb.control(null, this.isRequired ? [Validators.required] : [])
    });
  }

  handleAddressChange(inputAddress: ElabGoogleAddress): void {
    this.googleAddressSearchAutocompleteFormGroup.setErrors(null);
      const getAddressComponent = (type: string): string => {
        const component = inputAddress.address_components.find(a =>
          a.types.includes(type)
        );
        return component ? component.long_name : '';
      };

      const address: AddressInterface = {
        city: getAddressComponent('locality') || getAddressComponent('administrative_area_level_1') || '',
        country: getAddressComponent('country') || '',
        // @ts-ignore
        formattedAddress: inputAddress.formatted_address,
        // @ts-ignore
        latitude: inputAddress.geometry.location.lat(),
        longitude: inputAddress.geometry.location.lng(),
        googleMapsUrl: inputAddress.url,
        googleMapsPlaceId: inputAddress.place_id,
        streetNumber: getAddressComponent('street_number') || '',
        zipCode: getAddressComponent('postal_code') || '',
        street: getAddressComponent('route') || '',
        state: getAddressComponent('administrative_area_level_1') || '',
        // @ts-ignore
        timezoneOffsetMinutes: inputAddress['utc_offset_minutes'] || 0,
        name: inputAddress['name'] || '',
      };
      this.addressSelected.emit(address);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
