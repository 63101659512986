import { BoreholeInterface, BoreholeStatusEnum } from '../../..';
import { BoreholeLayerDto } from './borehole-layer.dto';
import { AreaDto } from './area.dto';
import { BoreholeCommentDto } from './borehole-comment.dto';

export class BoreholeDto implements BoreholeInterface {
  id: number;
  machineId: string;
  longitude: number;
  latitude: number;
  altitude: number;
  machineSoftwareVersion: string;
  physicalBoreholeId: string;
  pusherDiameterMM: number;
  startTimestamp: Date;
  endTimestamp: Date;
  reason: string;
  boreholeLayers: BoreholeLayerDto[];
  machineOperator: string;
  status: BoreholeStatusEnum;
  totalMass: number;
  totalVolume: number;
  depth: number;
  waterLevel: number;
  area: AreaDto;
  areaId: number;
  boreholeComments: BoreholeCommentDto[];
  
  constructor(args: BoreholeDto) {
    if (args) { 
      this.id = args.id;
      this.machineId = args.machineId;
      this.machineSoftwareVersion = args.machineSoftwareVersion;
      this.physicalBoreholeId = args.physicalBoreholeId;
      this.pusherDiameterMM = args.pusherDiameterMM;
      this.startTimestamp = args.startTimestamp;
      this.endTimestamp = args.endTimestamp;
      this.reason = args.reason;
      this.boreholeLayers = args.boreholeLayers;
      this.machineOperator = args.machineOperator;
      this.status = args.status;
      this.totalMass = args.totalMass;
      this.totalVolume = args.totalVolume;
      this.depth = args.depth;
      this.waterLevel = args.waterLevel;
      this.area = args.area;
      this.areaId = args.areaId;
      this.boreholeComments = args.boreholeComments;
    } 
  }
}
