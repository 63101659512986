import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyPageComponent } from './company-page.component';
import { JoinCompanyFormModule } from '../../components/company-page/join-company-form/join-company-form.module';
import { RegisterCompanyFormModule } from '../../components/company-page/register-form/register-company-form.module';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [CompanyPageComponent],
  imports: [
    CommonModule,
    JoinCompanyFormModule,
    RegisterCompanyFormModule,
    MatListModule
  ],
  exports: [CompanyPageComponent]
})
export class CompanyPageModule { }
