import { BoreholeInterface } from '../../..';

export class CreateBoreholeBulkRequestDto implements BoreholeInterface {
  physicalBoreholeId: string;
  latitude: number;
  longitude: number;
  altitude: number;
  
  constructor(args: CreateBoreholeBulkRequestDto) {
    if (args) { 
      this.physicalBoreholeId = args.physicalBoreholeId;
      this.longitude = args.longitude;
      this.latitude = args.latitude;
      this.altitude = args.altitude;
    } 
  }
}
