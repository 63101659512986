import { CompanyUserInterface, CompanyUserRoleEnum, CompanyUserStatusEnum } from '../../..';
import { UserDto } from '@platri/elab-nestjs-auth-plug-play';

export class CompanyUserDto implements CompanyUserInterface {
  id: number;
  companyId?: number;
  user?: UserDto;
  userId?: string;
  invitedEmail?: string;
  role?: CompanyUserRoleEnum;
  status?: CompanyUserStatusEnum;
  
  constructor(args: CompanyUserDto) {
    if (args) { 
      this.id = args.id;
      this.companyId = args.companyId;
      this.user = args.user;
      this.userId = args.userId;
      this.role = args.role;
      this.status = args.status;
    } 
  }
}
