import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { GeologAppRoute, GeologCompanyLandingRoute, GeologCompanyRoute, GeologLoginRoute, GeologProjectsRoute, GeologRegisterRoute } from '../../shared/routes/geolog.routes';
import { AuthStateService } from '../../shared/services/auth-state.service';
import { Subscription } from 'rxjs';
import { getSubscription } from '@platri/elab-angular-core';
import { Router } from '@angular/router';
import { CompanyStateService } from '../../shared/services/company-state.service';
import { CompanyDto } from '@platri/geolog-common-core';

@Component({
  selector: 'elab-geolog-app',
  templateUrl: './geolog-app.component.html',
  styleUrls: ['./geolog-app.component.scss']
})
export class GeologAppComponent implements OnInit, OnDestroy {

  protected readonly GeologLoginRoute = GeologLoginRoute;
  
  subscriptions: Subscription = new Subscription();
  
  accessToken: string = '';
  
  availableCompanies: CompanyDto[];
  selectedCompany: CompanyDto;
  
  constructor(
    private authStateService: AuthStateService,
    private companyStateService: CompanyStateService,
    private ngZone: NgZone,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.authStateService.refreshTokens();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.authStateService.getAsyncAccessToken(), this.onAccessTokenChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.companyStateService.getAsyncAvailableData(), this.onAvailableCompaniesChanges.bind(this)));
    this.subscriptions.add(getSubscription(this.companyStateService.getAsyncSelectedData(), this.onSelectedCompaniesChanges.bind(this)));
  }

  onAccessTokenChanges(accessToken: string): void {
    this.accessToken = accessToken;
    if (this.accessToken && (!this.companyStateService.getSyncAvailableData() || this.companyStateService.getSyncAvailableData().length === 0)) {
      this.companyStateService.loadAvailableData();
    }
  }

  onAvailableCompaniesChanges(availableCompanies: CompanyDto[]): void {
    this.availableCompanies = availableCompanies;
  }

  onSelectedCompaniesChanges(selectedCompany: CompanyDto): void {
    this.selectedCompany = selectedCompany;
  }
  
  changeSelectedCompany(company: CompanyDto): void {
    this.companyStateService.sendSelectedData(company);
  }
  
  logout(): void {
    this.authStateService.logout();
    this.companyStateService.resetAllStates();
  }

  protected readonly GeologRegisterRoute = GeologRegisterRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologCompanyRoute = GeologCompanyRoute;
  protected readonly GeologCompanyLandingRoute = GeologCompanyLandingRoute;
}
