<div class="full-view">
  <div class="flex-container">
    <div class="loading-box" *ngIf="isWaitingForMagicLinkLoginResponse">
      <mat-spinner></mat-spinner>
    </div>
    
    <div *ngIf="!magicLinkLoginFailedNoConnection && !isWaitingForMagicLinkLoginResponse && hasSuccessfullyMagicLinkLogin">
      Erfolgreiche Anmeldung über den magischen Link!
    </div>
    
    <div *ngIf="!magicLinkLoginFailedNoConnection && !isWaitingForMagicLinkLoginResponse && !hasSuccessfullyMagicLinkLogin">
      Etwas ist schief gelaufen.
    </div>
    
    <div *ngIf="magicLinkLoginFailedNoConnection">
      Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.
    </div>
    
    <div *ngIf="magicLinkLoginFailedInternalServer">
      Fehler auf dem Server aufgetreten.
    </div>
  </div>
</div>
