import { ProjectInterface } from '../../..';
import { AreaDto } from './area.dto';

export class ProjectDto implements ProjectInterface {
  id: number;
  name: string;
  client?: string;
  description?: string;
  planner?: string;
  propertyOwner?: string;
  companyId: number;
  areas: AreaDto[];
  
  constructor(args: ProjectDto) {
    if (args) { 
      this.id = args.id;
      this.name = args.name;
      this.client = args.client;
      this.description = args.description;
      this.planner = args.planner;
      this.propertyOwner = args.propertyOwner;
      this.companyId = args.companyId;
      this.areas = args.areas;
    } 
  }
}
