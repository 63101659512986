<div style="width: 700px; margin: 0 auto">
  <elab-upload-boreholes (DataChangeEmitter)="onBoreholeUploadChange($event)"></elab-upload-boreholes>
    <div style="width: 100%; display: flex; flex-direction: row; margin-top: 1.5rem;  margin-bottom: 1.5rem">
      <button id="cancel-button" [disabled]="isWaitingForUploadBoreholesResponse" [class.spinner]="isWaitingForUploadBoreholesResponse" style="width: 100%; margin-right: 16px; " mat-raised-button type="button" [routerLink]="['/', GeologAppRoute, GeologProjectsRoute, projectIdFromParam, GeologAreasRoute, areaIdFromParam]" data-cy="cancel-button">Abbrechen</button>
      <button id="upload-button" [disabled]="isWaitingForUploadBoreholesResponse" [class.spinner]="isWaitingForUploadBoreholesResponse" style="width: 100%" mat-raised-button color="primary" type="button" data-cy="upload-button" (click)="uploadBoreholes()" >Upload</button>
    </div>

    <div *ngIf="uploadFailedNoConnection" style="height: 60px">
      <p class="login-error" data-cy="connection-error">Die Verbindung zum Server konnte nicht hergestellt werden. Versuchen Sie es später noch einmal.</p>
    </div>
  
    <div *ngIf="uploadFailedInternalServer" style="height: 60px">
      <p class="login-error" data-cy="internal-server-error">Fehler auf dem Server aufgetreten.</p>
    </div>
</div>
