import { CompanyLicenseKeyInterface } from '../../..';

export class CreateCompanyLicenseKeyRequestDto implements CompanyLicenseKeyInterface {
  licenseKey: string;
  isActive: boolean;
  
  constructor(licenseKey: string, isActive: boolean) {
    this.licenseKey = licenseKey;
    this.isActive = isActive;
  }
}
