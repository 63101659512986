import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CompanyDto } from '@platri/geolog-common-core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyHttpService } from '../services/company-http.service';

@Injectable({ providedIn: 'root' })
export class CompaniesByCurrentUserResolver implements Resolve<CompanyDto[]> {
  constructor(private companyHttpService: CompanyHttpService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompanyDto[]> {
    return this.companyHttpService.getAllByCurrentUser();
  }
}
