import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompanyUserDto, CompanyUserInterface, CompanyUserRelationsEnum, CompanyUserRoleEnum, CompanyUserStatusEnum, GetCompanyUsersByFilterRequestDto, ProjectInterface } from '@platri/geolog-common-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologCreateProjectRoute, GeologLoginRoute } from '../../../shared/routes/geolog.routes';
import { getSubscription } from '@platri/elab-angular-core';
import { GeologCompanyIdRouterParam } from '../../../shared/router-params';
import { CompanyUserHttpService } from '../../../shared/services/company-user-http.service';

@Component({
  selector: 'elab-team-worker-list',
  templateUrl: './team-worker-list.component.html',
  styleUrls: ['./team-worker-list.component.scss']
})
export class TeamWorkerListComponent implements OnInit, OnDestroy {
  @Input() userCompanyUserId: number;
  @Input() userCompanyUserRole: CompanyUserRoleEnum | null;
  @Output() loadedTeamMembersEmitter: EventEmitter<CompanyUserInterface[]> = new EventEmitter<CompanyUserInterface[]>();
  
  searchFormControl: FormControl<string | null> = new FormControl<string>('');
  dataSource: CompanyUserInterface[] = [];
  availableTeamUsers: CompanyUserInterface[] = [];
  
  subscriptions: Subscription =  new Subscription();
  
  isLoadingTeamWorkers = true;
  hasLoadedTeamWorkers = false;
  hasFailedLoadingTeamWorkersNoConnection = false;
  hasFailedLoadingTeamWorkersInternalServer = false;
  
  searchStrings: string[] = [];

  routerParams!: Params;
  companyIdFromParam: number;
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private companyUserHttpService: CompanyUserHttpService
  ) {
  }
  
  ngOnInit(): void {
    this.initializeSubscriptions();
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.companyIdFromParam = this.routerParams[GeologCompanyIdRouterParam];
    if (!this.companyIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    this.loadData();
  }


  loadData(): void {
    this.isLoadingTeamWorkers = true;
    this.hasFailedLoadingTeamWorkersNoConnection = false;
    this.hasFailedLoadingTeamWorkersInternalServer = false;
    const getCompanyUsersByFilterRequestDto = new GetCompanyUsersByFilterRequestDto();
    getCompanyUsersByFilterRequestDto.companyId = this.companyIdFromParam;
    getCompanyUsersByFilterRequestDto.status = CompanyUserStatusEnum.ACTIVE;
    getCompanyUsersByFilterRequestDto.loadRelations = [CompanyUserRelationsEnum.USER];
    this.subscriptions.add(
      this.companyUserHttpService.getCompanyUsersByFilter(getCompanyUsersByFilterRequestDto).subscribe(companyUserPageDto => {
        this.availableTeamUsers = companyUserPageDto.data;
        this.loadedTeamMembersEmitter.emit(this.availableTeamUsers);
        this.dataSource = structuredClone(companyUserPageDto.data);
        this.isLoadingTeamWorkers = false;
        this.hasLoadedTeamWorkers = true;
        this.searchStrings = [];
        companyUserPageDto.data.map((companyUserDto, index) => {
          let searchString = `${index};`;
          for (const companyUserDtoKey in companyUserDto) {
            if (companyUserDtoKey !== "user") {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              searchString += companyUserDto[companyUserDtoKey] + ';';
            }
          }
          this.searchStrings.push(searchString);
        });
      }, error => {
        console.log(error);
        this.isLoadingTeamWorkers = false;
        this.hasLoadedTeamWorkers = true;
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
      })
    );
  }

  handleRowClick(row: ProjectInterface): void {
    //this.router.navigate([GeologBoreholeDetailIdRoute(this.routerParams[GeologProjectIdRouterParam], this.routerParams[GeologAreaIdRouterParam], row.id + '')]);
    //this.router.navigate([GeologProjectDetailIdRoute(row.id + '')]);
  }

  onConnectionLost(): void {
    this.hasFailedLoadingTeamWorkersNoConnection = true;
  }
  
  onInternalServerError(): void {
    this.hasFailedLoadingTeamWorkersInternalServer = true;
  }
  
  search(): void {
    const userSearchString = this.searchFormControl.getRawValue();
    const filteredSearchStrings = this.searchStrings.filter(searchString => searchString.includes(userSearchString!));

    this.dataSource = filteredSearchStrings.map(filteredSearchString => {
      const index = filteredSearchString.split(';', 1)[0];
      return structuredClone(this.availableTeamUsers[+index]);
    });
  }

  handleRowDeleteClick(companyUser: CompanyUserInterface): void {
    this.companyUserHttpService.deleteCompanyUserById(companyUser.id!).subscribe(res => {
      this.isLoadingTeamWorkers = true;
      this.hasLoadedTeamWorkers = false;
      this.hasFailedLoadingTeamWorkersInternalServer = false;
      this.hasFailedLoadingTeamWorkersNoConnection = false;
      this.loadData();
    });
  }

  handleRowEditClick(companyUser: CompanyUserInterface): void {
    alert("Not ready yet");
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologCreateProjectRoute = GeologCreateProjectRoute;
  protected readonly CompanyUserStatusEnum = CompanyUserStatusEnum;
  protected readonly CompanyUserRoleEnum = CompanyUserRoleEnum;
}
