import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterCompanyFormInterface } from './register-company-form.interface';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GeologAppRoute, GeologAreasRoute, GeologBoreholesRoute, GeologLoginRoute, GeologProjectsRoute } from '../../../shared/routes/geolog.routes';
import { CompanyHttpService } from '../../../shared/services/company-http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { addressValidator } from '../../../shared/validators/address.validator';
import { germanPhoneValidator } from '../../../shared/validators/german-phone.validator';
import { AddressInterface, CompanyAddressDto, CreateCompanyRequestDto } from '@platri/geolog-common-core';

@Component({
  selector: 'elab-register-company-form',
  templateUrl: './register-company-form.component.html',
  styleUrls: ['./register-company-form.component.scss']
})
export class RegisterCompanyFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulRegisterEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() stepEmitter: EventEmitter<number> = new EventEmitter<number>();
  
  registerFormGroup!: FormGroup<RegisterCompanyFormInterface>;

  subscriptions: Subscription = new Subscription();

  isInitialized = false;
  isWaitingForRegisterResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;

  signInFailedWrongData = false;
  signInFailedNoConnection = false;
  signInFailedInternalServer = false;
  
  hasSuccessfullyRegistered = false;
  
  // This is needed to change the layout depending on the current state of the form. 
  step: number = 0;

  constructor(
    private fb: FormBuilder,
    private companyHttpService: CompanyHttpService,
    private matSnackbar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.registerFormGroup = this.createRegisterFormGroup();
  }

  createRegisterFormGroup(): FormGroup<RegisterCompanyFormInterface> {
    return this.fb.group<RegisterCompanyFormInterface>({
      name: this.fb.control('', [Validators.required]),
      licenseKey: this.fb.control('', [Validators.required]),
      billingAddress: this.fb.control(null, [Validators.required ]),
      email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-äöüß]+@[a-z0-9.-äöüß]+\\.[a-z]{2,4}$')]),
      phoneNumber: this.fb.control('', [Validators.required, germanPhoneValidator()])
      }
    );
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.registerFormGroup.valueChanges.subscribe(res => {
      this.signInFailedWrongData = false;
      this.signInFailedNoConnection = false;
      this.signInFailedInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
  }

  onSubmit(): void {
    if (this.registerFormGroup.valid) {
      this.isWaitingForRegisterResponse = true;
      this.signInFailedWrongData = false;
      this.signInFailedNoConnection = false;
      this.signInFailedInternalServer = false;
      const {name, licenseKey, email, phoneNumber, billingAddress} = this.registerFormGroup.getRawValue();
      //@ts-ignore
      const companyAddressDto: CompanyAddressDto = new CompanyAddressDto(billingAddress!);
      const createCompanyRequestDto: CreateCompanyRequestDto = new CreateCompanyRequestDto(companyAddressDto, email!, name!, phoneNumber!, licenseKey!);
      this.subscriptions.add(this.companyHttpService.createCompany(createCompanyRequestDto).subscribe(res => {
        this.hasSuccessfullyRegistered = true;
        this.isWaitingForRegisterResponse = false;
        this.successfulRegisterEmitter.next(true);
        this.matSnackbar.open('Successfully registered!');
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        } else {
          this.onWrongPasswordEntered();
        }
        this.isWaitingForRegisterResponse = false;
      }));
    }
  }

  onConnectionLost(): void {
    (this.signInFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.signInFailedInternalServer = true;
  }

  onWrongPasswordEntered(): void {
    this.signInFailedWrongData = true;
  }
  
  changeStep(step: number): void {
    this.step = step;
    this.stepEmitter.emit(this.step);
  }

  onAddressSelected(address: AddressInterface): void {
    address.addressLine1 = 'test';
    this.registerFormGroup.patchValue({billingAddress: address});
  }

  protected readonly GeologLoginRoute = GeologLoginRoute;
  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologAreasRoute = GeologAreasRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
  protected readonly GeologBoreholesRoute = GeologBoreholesRoute;
}
