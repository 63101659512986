import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyUserRoleEnum, CreateCompanyUserRequestDto } from '@platri/geolog-common-core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologLoginRoute } from '../../../shared/routes/geolog.routes';
import { GeologCompanyIdRouterParam } from '../../../shared/router-params';
import { CompanyUserHttpService } from '../../../shared/services/company-user-http.service';
import { TeamWorkerInviteFormInterface } from './team-worker-invite-form.interface';
import { getSubscription } from '@platri/elab-angular-core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'elab-team-worker-invite',
  templateUrl: './team-worker-invite.component.html',
  styleUrls: ['./team-worker-invite.component.scss']
})
export class TeamWorkerInviteComponent implements OnInit, OnDestroy, AfterContentInit {
  @Output() successfulTeamWorkerInviteEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  teamWorkerInviteFormGroup!: FormGroup<TeamWorkerInviteFormInterface>;
  
  subscriptions: Subscription =  new Subscription();

  isInitialized = false;
  isWaitingForTeamWorkerInviteResponse = false;
  hasFailedLoadingTeamWorkersNoConnection = false;

  inviteFailedCompanyUserAlreadyInvitedOrExists = false;
  inviteFailedNoConnection = false;
  inviteFailedInternalServerError = false;

  routerParams!: Params;
  companyIdFromParam: number;
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private companyUserHttpService: CompanyUserHttpService,
    private fb: FormBuilder,
    private matSnackBar: MatSnackBar
  ) {
  }
  
  ngOnInit(): void {
    this.initializeFormGroup();
  }
  
  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  initializeFormGroup(): void {
    this.teamWorkerInviteFormGroup = this.createTeamWorkerInviteFormGroup();
  }

  createTeamWorkerInviteFormGroup(): FormGroup<TeamWorkerInviteFormInterface> {
    return this.fb.group<TeamWorkerInviteFormInterface>({
      invitedEmail: this.fb.control('', [Validators.required, Validators.email]),
      role: this.fb.control(null, [Validators.required]),
    });
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.companyIdFromParam = this.routerParams[GeologCompanyIdRouterParam];
    if (!this.companyIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.teamWorkerInviteFormGroup.valueChanges.subscribe(res => {
      this.inviteFailedNoConnection = false;
      this.inviteFailedInternalServerError = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onConnectionLost(): void {
    (this.hasFailedLoadingTeamWorkersNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.inviteFailedInternalServerError = true;
  }
  
  onSubmit(): void {
    if (this.teamWorkerInviteFormGroup.valid) {
      this.teamWorkerInviteFormGroup.disable();
      this.isWaitingForTeamWorkerInviteResponse = true;
      this.inviteFailedNoConnection = false;
      this.inviteFailedInternalServerError = false;
      this.inviteFailedCompanyUserAlreadyInvitedOrExists = false;
      const {invitedEmail, role} = this.teamWorkerInviteFormGroup.getRawValue();
      //@ts-ignore
      const createCompanyUserRequestDto: CreateCompanyUserRequestDto = { invitedEmail: invitedEmail!, role: role!, companyId: +this.companyIdFromParam};
      this.subscriptions.add(this.companyUserHttpService.createCompanyUser(createCompanyUserRequestDto).subscribe(createdId => {
        this.successfulTeamWorkerInviteEmitter.next(true);
        this.matSnackBar.open('Successfully invited worker!');
        this.teamWorkerInviteFormGroup.reset();
        this.isWaitingForTeamWorkerInviteResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        if (error.status === 409) {
          this.inviteFailedCompanyUserAlreadyInvitedOrExists = true;
        }
        if (error.error.errorCode === 'company_user_exists') {
        }
        this.teamWorkerInviteFormGroup.enable();
        this.isWaitingForTeamWorkerInviteResponse = false;
      }));
    }
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly CompanyUserRoleEnum = CompanyUserRoleEnum;
}
