import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StandaloneAppComponent } from './standalone-app.component';
import { StandaloneRoutingModule } from './standalone-routing.module';
import { GenericWrapperComponent, GenericWrapperModule } from '@platri/elab-angular-generic-wrapper';
import { authInterceptorProviders } from '../lib';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@NgModule({
  declarations: [StandaloneAppComponent],
  imports: [BrowserModule, StandaloneRoutingModule, GenericWrapperModule],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    authInterceptorProviders
  ],
  bootstrap: [GenericWrapperComponent]
})
export class StandaloneAppModule {}
