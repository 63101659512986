import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleAddressSearchAutocompleteComponent } from './google-address-search-autocomplete.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { FrontendGoogleAutocompleteModule } from '@platri/elab-angular-google-autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [GoogleAddressSearchAutocompleteComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatIconModule,
    TranslocoModule,
    FrontendGoogleAutocompleteModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [GoogleAddressSearchAutocompleteComponent],
})
export class GoogleAddressSearchAutocompleteModule {}
