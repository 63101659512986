import { Injectable, OnDestroy } from '@angular/core';
import { AbstractStateV2Service } from '@platri/elab-angular-core';
import { CompanyDto, CompanyInterface } from '@platri/geolog-common-core';
import { CompanyHttpService } from './company-http.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyStateService extends AbstractStateV2Service<CompanyInterface> implements OnDestroy {

  constructor(
    private companyHttpService: CompanyHttpService,
  ) {
    console.log('Initializing ' + CompanyStateService.name);
    super();
  }

  ngOnDestroy(): void {
    console.log("Destroying " + CompanyStateService.name);
    this.subscriptions.unsubscribe();
    this.allData.unsubscribe();
    this.selectedData.unsubscribe();

  }

  loadAvailableData(): void {
    this.subscriptions.add(this.allData.getSubscription(this.companyHttpService.getAllByCurrentUser().pipe(map(res => {
      this.onAllCompaniesForCurrentUserLoaded(res);
      return res;
    }))));
  }

  onAllCompaniesForCurrentUserLoaded(companies: CompanyDto[]): void {
    if (companies && companies.length > 0 && !this.selectedData.getValue()) {
      this.sendSelectedData(companies[0]);
    }
  }
  
  sendSelectedData(selectedCompany: CompanyDto): void {
    this.selectedData.next(selectedCompany);
  }
  
}
