import { BoreholeInterface, BoreholeStatusEnum } from '../../..';
import { BoreholeLayerDto } from './borehole-layer.dto';

export class CreateBoreholeRequestDto implements BoreholeInterface {
  machineId: string;
  machineSoftwareVersion: string;
  physicalBoreholeId: string;
  pusherDiameterMM: number;
  startTimestamp: Date;
  endTimestamp: Date;
  reason?: string;
  latitude: number;
  longitude: number;
  altitude: number;
  boreholeLayers: BoreholeLayerDto[];
  status: BoreholeStatusEnum;
  areaId: number;
  
  constructor(args: CreateBoreholeRequestDto) {
    if (args) { 
      this.machineId = args.machineId;
      this.machineSoftwareVersion = args.machineSoftwareVersion;
      this.physicalBoreholeId = args.physicalBoreholeId;
      this.pusherDiameterMM = args.pusherDiameterMM;
      this.startTimestamp = args.startTimestamp;
      this.endTimestamp = args.endTimestamp;
      this.reason = args.reason;
      this.longitude = args.longitude;
      this.latitude = args.latitude;
      this.altitude = args.altitude;
      this.boreholeLayers = args.boreholeLayers;
      this.status = args.status;
      this.areaId = args.areaId;
    } 
  }
}
