import { AfterContentInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EditAreaFormInterface } from './edit-area-form.interface';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GeologAppRoute, GeologAreasRoute, GeologForgotPasswordRoute, GeologLoginRoute, GeologProjectsRoute } from '../../../shared/routes/geolog.routes';
import { AddressInterface, AreaInterface, UpdateAreaRequestDto } from '@platri/geolog-common-core';
import { getSubscription } from '@platri/elab-angular-core';
import { GeologAreaIdRouterParam, GeologProjectIdRouterParam } from '../../../shared/router-params';
import { AreaHttpService } from '../../../shared/services/area-http.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'elab-edit-area-form',
  templateUrl: './edit-area-form.component.html',
  styleUrls: ['./edit-area-form.component.scss']
})
export class EditAreaFormComponent implements OnDestroy, OnInit, AfterContentInit {
  @Output() successfulEditAreaEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  editAreaFormGroup!: FormGroup<EditAreaFormInterface>;

  subscriptions: Subscription = new Subscription();
  
  isInitialized = false;
  isWaitingForEditAreaResponse = false;
  hasFatalErrorOnInitialize = false;
  hasNonFatalErrorOnInitialize = false;
  
  editFailedNoConnection = false;
  editFailedInternalServer = false;

  routerParams!: Params;
  projectIdFromParam: number;
  areaIdFromParam: number;

  isLoadingArea = false;
  hasFailedLoadingAreaNoConnection = false;

  loadedArea: Readonly<AreaInterface>;
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private areaHttpService: AreaHttpService,
    private activatedRoute: ActivatedRoute,
    private matSnackbar: MatSnackBar
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeFormGroup();
  }

  ngAfterContentInit(): void {
    this.initializeFormSubscriptions();
    this.initializeSubscriptions();
    this.isInitialized = true;
  }

  initializeFormGroup(): void {
    this.editAreaFormGroup = this.createEditAreaFormGroup();
    this.editAreaFormGroup.disable();
  }

  createEditAreaFormGroup(): FormGroup<EditAreaFormInterface> {
    return this.fb.group<EditAreaFormInterface>({
      address: this.fb.control(null, [Validators.required]),
      latitude: this.fb.control(null),
      longitude: this.fb.control(null),
      cadastralDistrict: this.fb.control(''),
      floor: this.fb.control(''),
      parcelNumber: this.fb.control(''),
      description: this.fb.control(''),
      soil: this.fb.control('')
    });
  }

  patchEditAreaFormGroup(area: AreaInterface): void {
    this.editAreaFormGroup.patchValue(area);
  }

  initializeFormSubscriptions(): void {
    this.initializeOnValueChangesSubscription();
  }

  initializeOnValueChangesSubscription(): void {
    this.subscriptions.add(this.editAreaFormGroup.valueChanges.subscribe(res => {
      this.editFailedNoConnection = false;
      this.editFailedInternalServer = false;
    }));
  }

  initializeSubscriptions(): void {
    this.subscriptions.add(getSubscription(this.activatedRoute.params, this.onActivatedRouteChanges.bind(this)));
  }

  onActivatedRouteChanges(params: Params): void {
    this.routerParams = params;
    this.projectIdFromParam = this.routerParams[GeologProjectIdRouterParam];
    this.areaIdFromParam = this.routerParams[GeologAreaIdRouterParam];
    if (!this.projectIdFromParam || !this.areaIdFromParam) {
      this.router.navigate([GeologAppRoute, GeologLoginRoute]);
    }
    this.loadData();
  }

  loadData(): void {
    this.isLoadingArea = true;
    this.hasFailedLoadingAreaNoConnection = false;
    this.subscriptions.add(
      this.areaHttpService.getAreaById(this.areaIdFromParam).subscribe(areaDto => {
        this.loadedArea = areaDto;
        this.patchEditAreaFormGroup(areaDto);
        this.isLoadingArea = false;
        this.editAreaFormGroup.enable();
      }, error => {
        console.log(error);
        this.isLoadingArea = false;
        if (error.status === 0) {
          this.onLoadAreaConnectionLost();
        }
      })
    );
  }
  

  onSubmit(): void {
    if (this.editAreaFormGroup.valid) {
      this.isWaitingForEditAreaResponse = true;
      this.editFailedNoConnection = false;
      this.editFailedInternalServer = false;
      const {address, latitude, longitude, cadastralDistrict, floor, parcelNumber, description, soil} = this.editAreaFormGroup.getRawValue();
      //todo: Should not need projectId
      // @ts-ignore
      const updateAreaRequestDto: UpdateAreaRequestDto = {address: address!, longitude: longitude!, latitude: latitude!, cadastralDistrict: cadastralDistrict!, floor: floor!, parcelNumber: parcelNumber!, description: description!, soil: soil!, projectId: this.projectIdFromParam};
      this.subscriptions.add(this.areaHttpService.updateArea(this.areaIdFromParam, updateAreaRequestDto).subscribe(areaDto => {
        this.successfulEditAreaEmitter.next(true);
        this.matSnackbar.open("Successfully edited area!");
        this.router.navigate(['/', GeologAppRoute, GeologProjectsRoute, this.projectIdFromParam, GeologAreasRoute, this.areaIdFromParam]);
        this.isWaitingForEditAreaResponse = false;
      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.onConnectionLost();
        }
        if (error.status === 500) {
          this.onInternalServerError();
        }
        this.isWaitingForEditAreaResponse = false;
      }));
    }
  }
  
  onLoadAreaConnectionLost(): void {
    this.hasFailedLoadingAreaNoConnection = true;
  }

  onConnectionLost(): void {
    (this.editFailedNoConnection) = true;
  }
  
  onInternalServerError(): void {
    this.editFailedInternalServer = true;
  }

  onAddressSelected(address: AddressInterface): void {
    this.editAreaFormGroup.patchValue({address: address, latitude: address.longitude, longitude: address.latitude});
  }

  protected readonly GeologAppRoute = GeologAppRoute;
  protected readonly GeologForgotPasswordRoute = GeologForgotPasswordRoute;
  protected readonly GeologProjectsRoute = GeologProjectsRoute;
  protected readonly GeologAreasRoute = GeologAreasRoute;
}
