import { AddressInterface } from './address.interface';

export interface CompanyInterface {
  address?: AddressInterface;
  businessArea?: string;
  email?: string;
  id?: number;
  joinCompanyCode?: string;
  name?: string;
  phoneNumber?: string;
  licenseKey?: string;
}
