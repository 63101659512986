import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ResetPasswordRequestDto, SignUpRequestDto, UserDto } from '@platri/elab-common-auth-plug-play';
import { excludeFalsyPropertiesFromObject } from '@platri/elab-common-core';
import { environment } from '../../environments/default.environment';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService implements OnDestroy {
  baseUrl = environment.geologApi;

  constructor(
    private httpClient: HttpClient,
  ) {
    console.log('Initializing UserHttpService');
  }

  ngOnDestroy(): void {
    console.log('Destroying UserHttpService');
  }
  
  getCurrentUser(): Observable<UserDto> {
    return this.httpClient.get<UserDto>(this.baseUrl + 'users');
  }

  signUp(signUpRequestDto: SignUpRequestDto): Observable<void> {
    excludeFalsyPropertiesFromObject(signUpRequestDto);
    return this.httpClient.post<void>(this.baseUrl + 'users/sign-up', signUpRequestDto);
  }

  sendResetPasswordEmail(email: string): Observable<void> {
    return this.httpClient.post<void>(this.baseUrl + 'users/email/reset-password-request', {email});
  }

  resetPassword(resetPasswordRequestDto: ResetPasswordRequestDto): Observable<void> {
    return this.httpClient.post<void>(this.baseUrl + 'users/email/reset-password', resetPasswordRequestDto);
  }
  
  confirmEmail(token: string): Observable<void> {
    return this.httpClient.get<void>(this.baseUrl + 'users/email/verify/' + token);
  }

  deactivateAccount(token: string): Observable<void> {
    return this.httpClient.patch<void>(this.baseUrl + 'users/deactivate-account', {token});
  }

  deleteAccount(token: string): Observable<void> {
    return this.httpClient.patch<void>(this.baseUrl + 'users/delete-account', {token});
  }

}
